import { API_BASE_URL } from "../config/apiConfig";
import { axiosGet } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getStudentDashBoardSuccess } from "../actions/studentDashboardAction";
import { dateFns } from "../services/dateFns";

const useStudentDashboard = () => {
  const dispatch = useDispatch();
  const studentDashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard || {});

  // const getStudentDashboard = useCallback(() => {
  //   if (!studentDashboard.RecentCalls) {
  //     axiosGet(`${API_BASE_URL}/GetStudentDashboard`)
  //       .then((res) => {
  //         dispatch(getStudentDashBoardSuccess(res.data));
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [studentDashboard, dispatch]);

  const getRecentSession = useCallback(
    (sessionId) => {
      return studentDashboard?.RecentCalls?.find(session => session.ID.toString() === sessionId.toString());
    },
    [studentDashboard]
  );

  const bookmarkSession = useCallback((sessionId) => {
    const updatedData = studentDashboard?.RecentCalls?.map((session) => {
      if (session.ID === sessionId) {
        session.isBookmarked = !session?.isBookmarked;
      }
      return session;
    });
    dispatch(getStudentDashBoardSuccess({ Data: { ...studentDashboard, RecentCalls: updatedData } }));
  }, [studentDashboard]);

  // const addSessionRating = (sessionId, tutorRating) => {
  //   studentDashboard.RecentCalls = studentDashboard?.RecentCalls?.map((session) => {
  //     if (session.ID == sessionId) {
  //       session.Ratings = tutorRating;
  //     }
  //     return session;
  //   })
  // }

  const addSessionRating = useCallback(
    (sessionId, tutorRating) => {
      const updatedData = studentDashboard?.RecentCalls?.map((session) =>
        session.ID === sessionId
          ? { ...session, Ratings: tutorRating }  
          : session
      );
  
      dispatch(
        getStudentDashBoardSuccess({
          Data: { ...studentDashboard, RecentCalls: updatedData, PrivateTutorInfo: [] },
        })
      );
    },
    [dispatch, studentDashboard]
  );

  const filterRecentSession = useCallback(
    (sessionType, sessionDay) => {
      if (sessionType !== '0' && sessionDay !== '0') {
        const sessions = studentDashboard?.RecentCalls?.filter(session => session?.Type === sessionType);
        return dateFns(sessions, sessionDay);
      } else if (sessionType !== '0') {
        return studentDashboard?.RecentCalls?.filter(session => session?.Type === sessionType);
      } else if (sessionDay !== '0') {
        return dateFns(studentDashboard?.RecentCalls, sessionDay);
      } else {
        return studentDashboard?.RecentCalls;
      }
    },
    [studentDashboard]
  );

  const markAsFavTutor = useCallback((tutorId) => {
    const updatedData = studentDashboard?.RecentCalls?.map((session) => {
      if (session.TutorID === tutorId) {
        session.FavouriteTutor = session?.FavouriteTutor === "true" ? "false" : "true";
      }
      return session;
    });
    dispatch(getStudentDashBoardSuccess({ Data: { ...studentDashboard, RecentCalls: updatedData } }));
  }, [studentDashboard]);

  const getNotes = useCallback(
    (noteId) => {
      return studentDashboard?.Documents?.find(note => note.ID.toString() === noteId.toString());
    },
    [studentDashboard]
  );

  // useEffect(() => {
  //   getStudentDashboard();
  // }, [getStudentDashboard]);

  return { studentDashboard, getRecentSession, bookmarkSession, addSessionRating, filterRecentSession, markAsFavTutor, getNotes };
};

export default useStudentDashboard;