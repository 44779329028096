import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

export const privateClassesAssignmentSuccess = (data) => {
  return {
    type: actionTypes.PRIVATE_CLASSES_ASSIGNMENT,
    payload: {
      assignments: data.Data,
    },
  };
};

export const privateClassesAssignment = (data) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/PrivateClassesAssignment`, data)
    .then((response) => {
      if (response) {
        dispatch(privateClassesAssignmentSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};
