import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const VideoModal = ({isOpen, title, data, handleClose}) => {
    return <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
            <video controls autoPlay>
                <source src={data?.SessionURL} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </DialogContent>
    </Dialog>
}

export default React.memo(VideoModal);