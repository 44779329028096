import React, { useState, useEffect } from 'react'
import { Box, Card, Grid, LinearProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";

import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/reproduction.png';

import trophyImg from '../../assets/images/trophy.png';
import resultIcon1 from '../../assets/images/resultIcon1.png';
import resultIcon2 from '../../assets/images/resultIcon2.png';
import resultIcon3 from '../../assets/images/resultIcon3.png';

import { useParams, useNavigate } from 'react-router-dom';

import useTranslations from '../../custom-hooks/useTranslations';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import useMockTest from '../../custom-hooks/useMockTest';
import useMockTestQuestions from '../../custom-hooks/useMockTestQuestions';

import SubTopHead from '../sub-top-head/SubTopHead';
import ShareWithFriend from '../common-components/ShareWithFriend';


const MockTestResult = () => {
    const navigate = useNavigate();
    const { subjectId, mockTestId } = useParams();
    const translations = useTranslations();
    const { getSubject } = useStudyMaterial()
    const subject = getSubject(atob(subjectId));
    const { getMockTest } = useMockTest(atob(subjectId));
    const mockTest = getMockTest(atob(mockTestId));
    const { mockTestQuestions, groupQuestionsByMark } = useMockTestQuestions(atob(mockTestId));
    const questionsByMark = groupQuestionsByMark();
    const [testResult, setTestResult] = useState({ Correct: 0, Incorrect: 0, NotAttempted: 0, Scored: 0 });

    const translation = {
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[36]?.PracticeTest
    }

    const handleBack = () => {
        navigate(`/mock-test/${subjectId}`);
    }

    useEffect(() => {
        if (Array.isArray(mockTestQuestions)) {
            const result = mockTestQuestions?.reduce((acc, current) => {
                if (current.AttemptedStatus === false) {
                    acc.NotAttempted = acc.NotAttempted + 1;
                }

                if (current.AnswerID === current.LastSelectedAnswerID) {
                    acc.Correct = acc.Correct + 1;
                    acc.Scored = acc.Scored + current.Mark;
                } else {
                    acc.Incorrect = acc.Incorrect + 1;
                }


                return acc;
            }, { Correct: 0, Incorrect: 0, NotAttempted: 0, Scored: 0  })
            setTestResult(result);
        }
    }, [mockTestQuestions])

    return (
        <div>
            <Header />
            <HeroSection />
            <Box className='OuterContainer'>
                <Box className='studyContainer'>
                    <SubTopHead subject={subject} />
                    {/* <Box className='subHeading'>
                        <Box className="cardMain lightGray">
                            <Box className="BackBtn">
                                <Button className='backBtn' variant="text" sx={{ mb: 1, paddingLeft: 0 }} startIcon={<ArrowBackIcon />} onClick={handleBack}> {translation?.BackBtn} </Button>
                            </Box>

                            <Box className="cardTop" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                <Box className="cardRight" sx={{ display: { xs: 'flex', alignItems: 'center' } }}>
                                    <Box className="cardContent">
                                        <Typography variant='h4'>{mockTest?.SubjectName}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box> */}

                    <Box className="practiceBox">
                        <Box className='practiceBoxIn'>
                            <ShareWithFriend />

                            <Box className="solutionBanner lightYellow" sx={{ mt: 3, mb: 0, display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                <Box className="contentBox">
                                    {/* <Typography>
                                        Biology test 1
                                    </Typography> */}
                                    <Typography variant='h3' sx={{ margin: 0 }} >
                                        {mockTest?.TestName}
                                    </Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>{mockTest?.NumberOfQuestion} {translation?.PracticeQuestion} </li>
                                            {/* <li>3 topics</li> */}
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="imageBox">
                                    <img src={cardIcon}></img>
                                </Box>
                            </Box>
                            {/* <Box className="attemptedHeading" sx={{ background: '#FFEBCE', padding: 2 }}>
                                <Box sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                    <Typography>
                                        <Typography variant='div'>overview of preproduction and life span</Typography>
                                    </Typography>
                                </Box>
                            </Box> */}
                            <Box className="MockResult" sx={{ mt: 3 }}>
                                <Box className='scoreCard attemptedCard'>
                                    <Box className="mainHeading">
                                        <Typography variant='h3'>
                                            {translation?.Your} {translation?.Score}
                                        </Typography>
                                    </Box>

                                    <Box className="solutionBanner marksCard lightYellow orgbdr radius8" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                        <Box className="contentBox">
                                            <Typography>
                                                {translation?.YorHaveScored}
                                            </Typography>
                                            <Typography variant='h3' sx={{ margin: 0 }} >
                                                {testResult?.Scored}/{mockTest?.NumberOfMark}
                                            </Typography>
                                        </Box>
                                        <Box className="imageBox">
                                            <img src={trophyImg}></img>
                                        </Box>
                                    </Box>

                                    <Box className="answerList">
                                        <Box className="green radius4" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'flex-start' } }}>
                                            <Box className="imageBox">
                                                <img src={resultIcon1}></img>
                                            </Box>

                                            <Box className="contentBox">
                                                <Typography variant='h3'>
                                                    {testResult?.Correct} {translation?.CorrectAnswer}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className="pink radius4" sx={{ mt: 1, mb: 1, display: { xs: 'flex', alignItems: 'center', justifyContent: 'flex-start' } }}>
                                            <Box className="imageBox">
                                                <img src={resultIcon2}></img>
                                            </Box>

                                            <Box className="contentBox">
                                                <Typography variant='h3'>
                                                    {testResult?.Incorrect} {translation?.IncorrectAnswer}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className="sky radius4" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'flex-start' } }}>
                                            <Box className="imageBox">
                                                <img src={resultIcon3}></img>
                                            </Box>

                                            <Box className="contentBox">
                                                <Typography variant='h3'>
                                                    {testResult?.NotAttempted} {translation?.PracticeNotAttempted}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* <Box className="AnsKey" sx={{ mt: 2, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                        <button className='anskeybtn'>{translation?.AnswerKeySolutions}</button>
                                    </Box> */}
                                </Box>

                                <Box className="subWiseResult attemptedCard" sx={{ mt: 2 }}>
                                    <Box className="mainHeading" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                        <Typography variant='h3'>
                                            {translation?.SessionWiseMark}
                                        </Typography>
                                    </Box>
                                    <Box className="sectionBox">
                                        <Box className="chaptersBox" sx={{ mt: 2, display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={2}>

                                            { questionsByMark?.map((question, index) => {
                                                return (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Box className="chapterBoxIn lightbdr">
                                                            <Box className="contentBox" sx={{ mb: 1 }}>
                                                                <Typography variant='h3'>
                                                                    {question?.Mark} {translation?.PracticeMarks} {translation?.PracticeQuestion}
                                                                </Typography>
                                                                <Typography variant='h4'>
                                                                    {translation?.PracticeScored} :- <Typography variant='div'>{question?.Scored}/{question?.TotalMark} </Typography>
                                                                </Typography>
                                                                <Typography>{translation?.NumberOfQuestion} :-
                                                                    <Typography variant='div'>{question?.NoOfQuestions}</Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        {translation?.PracticeCorrect}
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blueText'>
                                                                        {question?.Correct}
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blueText" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={(question?.Correct/question?.NoOfQuestions) * 100} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        {translation?.PracticeIncorrect}
                                                                    </Typography>
                                                                    <Typography variant='div' className='points pinkText'>
                                                                        {question?.Incorrect}
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar pink" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={(question?.Incorrect/question?.NoOfQuestions) * 100} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        {translation?.PracticeNotAttempted}
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blackText'>
                                                                        {question?.NotAttempted}
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blackbg" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={(question?.NotAttemptedQuestion/question?.NoOfQuestions) * 100} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })

                                            }


                                                {/* <Grid item xs={12} md={6}>
                                                <Box className="chapterBoxIn lightbdr">
                                                            <Box className="contentBox" sx={{ mb: 1 }}>
                                                                <Typography variant='h3'>
                                                                    section - C
                                                                </Typography>
                                                                <Typography variant='h4'>
                                                                    score :- <Typography variant='div'>2.0/10.0 </Typography>
                                                                </Typography>
                                                                <Typography>number of questions :-
                                                                    <Typography variant='div'>10</Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Correct
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blueText'>
                                                                        02
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blueText" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={25} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Incorrect
                                                                    </Typography>
                                                                    <Typography variant='div' className='points pinkText'>
                                                                        03
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar pink" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Not Attempted
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blackText'>
                                                                        05
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blackbg" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                <Box className="chapterBoxIn lightbdr">
                                                            <Box className="contentBox" sx={{ mb: 1 }}>
                                                                <Typography variant='h3'>
                                                                    section - C
                                                                </Typography>
                                                                <Typography variant='h4'>
                                                                    score :- <Typography variant='div'>2.0/10.0 </Typography>
                                                                </Typography>
                                                                <Typography>number of questions :-
                                                                    <Typography variant='div'>10</Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Correct
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blueText'>
                                                                        02
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blueText" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Incorrect
                                                                    </Typography>
                                                                    <Typography variant='div' className='points pinkText'>
                                                                        03
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar pink" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Not Attempted
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blackText'>
                                                                        05
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blackbg" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                <Box className="chapterBoxIn lightbdr">
                                                            <Box className="contentBox" sx={{ mb: 1 }}>
                                                                <Typography variant='h3'>
                                                                    section - C
                                                                </Typography>
                                                                <Typography variant='h4'>
                                                                    score :- <Typography variant='div'>2.0/10.0 </Typography>
                                                                </Typography>
                                                                <Typography>number of questions :-
                                                                    <Typography variant='div'>10</Typography>
                                                                </Typography>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Correct
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blueText'>
                                                                        02
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blueText" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Incorrect
                                                                    </Typography>
                                                                    <Typography variant='div' className='points pinkText'>
                                                                        03
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar pink" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                            <Box className="cardBottom" sx={{ padding: 0, mt: 1 }}>
                                                                <Typography className='progressText' sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                                                    <Typography variant='div'>
                                                                        Not Attempted
                                                                    </Typography>
                                                                    <Typography variant='div' className='points blackText'>
                                                                        05
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="progressBar blackbg" sx={{ mt: 0, mb: 1 }}>
                                                                    <LinearProgress variant="determinate" value={progress} sx={{ height: 3 }} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                </Grid> */}
                                            </Grid>
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>

                           

                        </Box>
                    </Box>
                </Box>
            </Box>
            <FooterSection />
        </div>
    )
}

export default MockTestResult