// Function to open the image in a modal
export const openImage = (imageSrc) => {
    const modal = document.getElementById("zoomImageModal");
    const modalImage = document.getElementById("modalImage");
    modal.style.display = "block";
    modalImage.src = imageSrc;
  };
  
// Function to close the modal
export const closeImage = () => {
    const modal = document.getElementById("zoomImageModal");
    modal.style.display = "none";
};

export const ZoomImageContainer = () => {
    return (
        <div id="zoomImageModal" className="zoom-image-modal">
            <span className="close" onClick={closeImage}>
            &times;
            </span>
            <img className="zoom-image-modal-content" id="modalImage" />
        </div>
    );
};