import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getTranslationsSuccess } from "../actions/getTranslationAction";

let isApiCalling = false;

const useTranslations = () => {
  const dispatch = useDispatch();
  const translations = useSelector(({ getTranslationsReducer }) => getTranslationsReducer.translations || []);

  const getLocalizationString = useCallback(() => {
    if (translations.length === 0 && isApiCalling === false) {
      isApiCalling = true;
      const data = { deviceType: 1 };
      
      axiosPost(`${API_BASE_URL}/GetLocalizationsString`, data)
        .then((res) => {
          dispatch(getTranslationsSuccess(res.data));
          // const result = res.data.Data.reduce((acc, obj) => {
          //   return { ...acc, ...obj };
          // }, {});
          // dispatch(getTranslationsSuccess(result))
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [translations, dispatch]);

  useEffect(() => {
    getLocalizationString();
  }, [getLocalizationString]);

  return translations;
};

export default useTranslations;