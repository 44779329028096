import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { privateClassesAssignmentSuccess } from '../../actions/privateClassesAssignment';
import useTranslations from '../../custom-hooks/useTranslations';
import useClasses from '../../custom-hooks/useClasses';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import clock from '../../assets/images/clock.svg';

const Assignment = () => {
    const dispatch = useDispatch();
    const { classId } = useParams();
    const translations = useTranslations();
    const { getClass } = useClasses();
    // const cls = getClass(atob(sessionId));
    const [assignments, setAssignments] = useState([]);
    const [isStart, setIsStart] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState([]);
    const [expandedQuestion, setExpandedQuestion] = useState(0);
    const [allSelectedAnswer, setAllSelectedAnswer] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [correctOption, setCorrectOption] = useState({});
    const [isCheckSolution, setIsCheckSolution] = useState({});

    const translation = {
        ...translations[8]?.PrivateClassDashboard,
        ...translations[12]?.ClassDetail,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[36]?.PracticeTest,
    }

    const privateClassesAssignments = useSelector(({ privateClassesAssignmentReducer }) => privateClassesAssignmentReducer.assignments);

    const handleStartPractice = (assignment) => {
        setIsStart(true);
        setSelectedAssignment(assignment);
    
        const answeredQuestions = assignment?.Questions?.filter(
            (question) => question.LastSelectedAnswerID !== 0
        );
    
        setAllSelectedAnswer(
            answeredQuestions?.map((question) => ({
                QuestionID: question.ID,
                AnswerID: question.LastSelectedAnswerID,
            })) || []
        );
    
        const selectedOption = {};
        const correctOption = {};
        const isCheckSolution = {};
    
        answeredQuestions?.forEach((question) => {
            selectedOption[question.ID] = question.LastSelectedAnswerID;
            correctOption[question.ID] = question.AnswerID;
            isCheckSolution[question.ID] = true;
        });
    
        setSelectedOption(selectedOption);
        setCorrectOption(correctOption);
        setIsCheckSolution(isCheckSolution);
    }

    const handleOptionClick = (questionId, optionId) => {
        if (correctOption[questionId] !== undefined) return;
      
        setSelectedOption((prev) => ({
          ...prev,
          [questionId]: optionId,
        }));
      
        setSelectedAnswer((prev) => {
          const updatedAnswer = [...prev];
          const prevAnswerIndex = updatedAnswer.findIndex((answer) => answer.QuestionID === questionId);
      
          if (prevAnswerIndex !== -1) {
            updatedAnswer[prevAnswerIndex] = { ...updatedAnswer[prevAnswerIndex], AnswerID: optionId };
          } else {
            updatedAnswer.push({ QuestionID: questionId, AnswerID: optionId });
          }
      
          return updatedAnswer;
        });
    };
      
    
    const handleAnswerCheck = () => {
        const questionIds = [...allSelectedAnswer, ...selectedAnswer].map((answer) => answer.QuestionID)
        const filterQuestion = selectedAssignment?.Questions?.filter((question) => questionIds.includes(question.ID))
        const correctAnswer =  filterQuestion?.reduce((acc, question) => {
            acc[question.ID] = question.AnswerID;
            return acc;
        }, {});
        const data = {
            DataList: selectedAnswer,
            AssignmentID: assignments?.ID,
            SessionID: assignments?.SessionID,
            PrivateClassID: atob(classId)
        }
        axiosPost(`${API_BASE_URL}/CheckAnswerForAssignment`, data);
        setAllSelectedAnswer([...allSelectedAnswer, ...selectedAnswer]);
        setCorrectOption(correctAnswer || {});
        setSelectedAnswer([]);

        const updatedQuestions = selectedAssignment?.Questions?.map(question => ({
            ...question,
            LastSelectedAnswerID: selectedOption[question.ID] || question.LastSelectedAnswerID
        }));

        const updatedAssignments = assignments.map(assignment =>
            assignment.ID === selectedAssignment.ID 
            ? { ...assignment, Questions: updatedQuestions } 
            : assignment
        );

        dispatch(privateClassesAssignmentSuccess({ Data: updatedAssignments }));
    }

    const handleCheckSolution = (question) => {
        setIsCheckSolution((prev) => ({
            ...prev,
            [question.ID]: prev[question.ID] === undefined ? true: !prev[question.ID]
        }));
    }

    const handleAccordianExpand = (questionIndex) => {
        if (expandedQuestion === questionIndex) {
            setExpandedQuestion(null);
        } else {
            setExpandedQuestion(questionIndex);
        }
    }

    // const getAssignment = () => {
    //     const data = { ClassID: session.ID };
    //     axiosPost(`${API_BASE_URL}/PrivateClassesAssignment`, data).then((res) => {
    //         setAssignments(res.data.Data);
    //     });
    // }

    // useEffect(() => {
    //     getAssignment();
    // }, [])

    useEffect(() => {
        if (Array.isArray(privateClassesAssignments)) {
            setAssignments(privateClassesAssignments);
        }


    }, [privateClassesAssignments])

  return (
    <>
        <Box className="mainHeading">
            <h4>{translation?.Assignment}</h4>
        </Box>
        { isStart === false ?
        <Box className="progressNumber-parent">
            <Box className="assignment_accWrapper">
                <div>
                    { assignments?.map((assignment, index) => {
                        return (
                            <Accordion key={assignment.ID}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box className='acc_wrap'>
                                    <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>{index + 1}</Typography><Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>{assignment?.Assignemnt}</Typography></Box>
                                    <Box className="box-level">
                                        <Typography variant='span' className='cyan'>{translation?.Easy}</Typography>
                                        <Typography variant='span' className={` ${assignment?.Status === 'Solved' ? 'lt-green' : 'lt-red' }`}>{translation[assignment?.Status?.replaceAll(' ', '')]}</Typography>
                                    </Box>
                                </Box>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className="acc_status">
                                    <Box className="ac_score">
                                        <Typography variant="span">{translation?.Score}</Typography>
                                        <Typography variant="span">{assignment?.Submission}/{assignment?.numberOfQuestion}</Typography>
                                    </Box>
                                    <Box className="ac_score">
                                        <Typography variant="span">{translation?.StatusLabel}</Typography>
                                        <Typography variant="span" className="c-red">{translation[assignment?.Status?.replaceAll(' ', '')]}</Typography>
                                    </Box>
                                    <Box className="ac_score">
                                        <Typography variant="span">{translation?.Submission}</Typography>
                                        <Typography variant="span" className="c-blue">{assignment?.Submission} {translation?.Submission}</Typography>
                                    </Box>
                                </Box>
                                <Button className="darkBlue" onClick={() => handleStartPractice(assignment)}>
                                    {translation?.StartSolving}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                        )
                    }) }
                </div>
            </Box>
            {/* <Box className="viewMore" sx={{ mt: 3, mb: 3, gap: 0.5, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                <button className='viewMoreBtn'>view more (05)</button>
            </Box> */}
        </Box> : 
        <Box className="progressNumber-parent">
            <Box className="progressNumber">
                <Typography variant='div' className='notStarted'><Typography variant='div'>
                    <img src={clock} />

                </Typography>{selectedAssignment?.HoursLeft} {translation?.Hour} {translation?.Left}</Typography>
                <Typography variant='div' className='cyan'> <Typography variant='div'>
                    <img src={clock} />
                </Typography> {selectedAssignment?.Attendance} {translation?.ClassAttendance}</Typography>

                <Typography variant='div' className='understood'> <Typography variant='div'>
                    <img src={clock} />
                </Typography> {selectedAssignment?.Submission}/{selectedAssignment?.numberOfQuestion} {translation?.ProblemSolved}</Typography>
            </Box>
            <Box className="subHeading">
                <Typography variant='h3'>{selectedAssignment?.Assignemnt}</Typography>
            </Box>
            <Box className="assignment_accWrapper">
                <div>
                    { selectedAssignment?.Questions?.map((question, index) => {
                        return (
                            <Accordion key={question.ID}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box className='acc_wrap'>
                                    <Box className="acc-label"><Typography variant="span" sx={{ fontSize: '20px', fontWeight: 600, color: '005DB8', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9FCFF', borderRadius: '50%' }}>{index + 1}</Typography>
                                    <Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }} dangerouslySetInnerHTML={{__html: question?.Question}}></Typography></Box>
                                    {/* <Typography variant="p" sx={{ fontSize: '18px', lineHeight: '1.4', color: '#000000', fontWeight: '500' }}>{question?.Question}</Typography></Box> */}
                                    {/* <Box className="box-level">
                                        <Typography variant='span' className='cyan'>{translation?.Easy}</Typography>
                                        <Typography variant='span' className={` ${question?.Status === 'Solved' ? 'lt-green' : 'lt-red' }`}>{translation[question?.Status?.replaceAll(' ', '')]}</Typography>
                                    </Box> */}
                                </Box>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className="Classification">
                                    <Box className="selectOption">
                                        <Typography sx={{ mb: 2 }}>
                                            {translation?.SelectOneOption}
                                        </Typography>

                                        <Box className="questioList" >
                                            { question?.Options?.map((option, optionIndex) => {
                                                const isSelected = selectedOption[question.ID] === option.ID && correctOption[question.ID] === undefined;
                                                const isCorrect = correctOption[question.ID] === option.ID && correctOption[question.ID] === selectedOption[question.ID];
                                                const isCorrectAnswer = correctOption[question.ID] === option.ID;
                                                const isIncorrect = correctOption[question.ID] !== undefined && selectedOption[question.ID] === option.ID && selectedOption[question.ID] !== correctOption[question.ID];

                                                const className = isSelected ? 'blue' : isIncorrect ? 'pink' : isCorrect ? 'green' : isCorrectAnswer ? 'blue' : '';

                                                return <Box
                                                    className={`questionListIn ${className}`}
                                                    sx={{ mb: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                    key={option?.ID}
                                                    onClick={() => handleOptionClick(question?.ID, option?.ID)}
                                                >
                                                    <Box className="cardIcon">{String.fromCharCode(65 + optionIndex)}</Box>
                                                    <Box>
                                                        <Typography dangerouslySetInnerHTML={{__html: option?.Value}}></Typography>
                                                        {isIncorrect && (
                                                            <Typography variant="div">
                                                                {translation?.WrongAnswer}
                                                            </Typography>
                                                        )}
                                                        {(isCorrect || isCorrectAnswer) && selectedOption[question.ID] !== undefined && (
                                                            <Typography variant="div">
                                                                {translation?.CorrectAnswer}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            }) }

                                        </Box>

                                        
                                        { correctOption[question.ID] !== undefined && !isCheckSolution[question.ID] && 
                                            <Box className="QuestionSolution" sx={{mt:3}}>
                                                <Box className="cardMain orgbdr lightYellow">
                                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                            <Box className="cardIcon YellowCircle">
                                                            <Typography variant='div'>
                                                            <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                            <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                            </svg>
                                                            </Typography>
                                                            </Box>
                                                            <Box className="cardContent">
                                                                <Typography variant='h4'>{translation?.PracticeSolutions}   </Typography>
                                                            </Box>
                                                            
                                                        </Box>
                                                        <Box className="cardLeft">
                                                            <Button variant="contained" className='darkYellow' onClick={() => handleCheckSolution(question)}>{translation?.CheckSolution}</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }

                                        { isCheckSolution[question.ID] && 
                                            <Box className="rightSolution orgbdr radius8">
                                                <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                    <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                    <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                        <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                        </svg>
                                                    </Typography>
                                                    <Typography  sx={{margin:0}}>
                                                        {translation?.PracticeSolutions}
                                                    </Typography>
                                                </Box>
                                                <Box className="SolutionSummary">
                                                    <Typography variant='h5' sx={{mb:1}}>
                                                        {translation?.CorrectOption} {String.fromCharCode(65 + question?.Options.findIndex((option) => option.ID === correctOption[question.ID]))}
                                                    </Typography>
                                                    <Typography dangerouslySetInnerHTML={{__html: question?.Solution}}></Typography>
                                                </Box>
                                            </Box>
                                        }

                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        )
                    }) }

                    { selectedAnswer?.length > 0 && <Box className="checkAnswer" sx={{mt:4, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }} >
                        <button className='checkAnswerBtn' onClick={handleAnswerCheck}>{translation?.CheckAnswer} ({selectedAnswer?.length})</button>
                    </Box> }
                </div>
            </Box>
        </Box> }
    </>
  )
}

export default React.memo(Assignment);