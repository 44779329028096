import * as actionTypes from "../actions/actionTypes";

const initialState = {student: []};

const studentRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_STUDENT_REGISTRATION:
      return {
        ...state,
        student: [action.payload.student],
      };

    default:
      return state;
  }
};
export default studentRegistrationReducer;
