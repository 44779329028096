import * as actionTypes from "../actions/actionTypes";

const initialState = {profile: []};

const updateStudentProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STUDENT_PROFILE:
      return {
        ...state,
        profile: [action.payload.profile],
      };

    default:
      return state;
  }
};
export default updateStudentProfileReducer;
