import React, { useState, useEffect } from 'react';
import { Box, FormControl, Typography, RadioGroup, FormControlLabel, Radio, OutlinedInput, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';

                
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import penguine from '../../assets/images/penguine.png';

import "../../App.css";
import "../subject/Subject.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";
import "../../components/login/Login.css";
import './registration.css';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import { studentRegistration } from '../../actions/studentRegistrationAction';
import { getStudentProfile } from '../../actions/studentProfileAction';
import { getStudentDashBoard } from '../../actions/studentDashboardAction';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import { generateDeviceId } from '../../services/common';
import LoginSlider from '../login/LoginSlider';
import FlagDropDown from '../login/FlagDropDown';


const initialState = {
	StudentName: '',
    ReferralCode: '',
	StudentGenderID: '',
	StudentGradeID: '',
	StudentExaminationID: '',
	StudentSubExaminationID: '',
};

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {socket, isConnected } = useSocket();
    const [tab, setTab] = useState(0);
    const [dialCode, setDialCode] = useState({});
    const [selectedBoard, setSelectedBoard] = useState();
    const [isApiCalling, setIsApiCalling] = useState(false);
    const translations = useTranslations();

	const translation = {
        ...translations[0]?.Login,
        ...translations[2]?.EditProfile,
        ...translations[23]?.TutorRegistration,
        ...translations[26]?.QuickCall,
        ...translations[33]?.ApplicationConfiguration,
        ...translations[35]?.Referral
    }

    const {
		control,
		trigger,
        handleSubmit,
        getValues,
        setError,
		formState: { errors },
	} = useForm({
		defaultValues: initialState,
		mode: 'onChange'
	});

    const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
	const loginToken = JSON.parse(Cookies.get('loginToken'));

    const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);

    const handleBack = () => {
		setTab((prevTab) => prevTab - 1);
	};

    const handleRedeemNext = async () => {
        if (await trigger()) {
            try {
                const referralCode = getValues('ReferralCode');
    
                if (!referralCode || (await axiosPost(`${API_BASE_URL}/CheckReferralCode`, { ReferralCode: referralCode }))) {
                    setTab((prevTab) => prevTab + 1);
                }
            } catch (error) {
                console.error('Error: ', error);
                setError('ReferralCode', {
                    type: 'manual',
                    message: translation?.InvalidReferralCode,
                });
            }
        } else {
            console.log('Validation failed. Please fill in all required fields.');
        }
    };

	const handleNext = async () => {
		const isValid = await trigger();
		if (isValid) {
			setTab((prevTab) => prevTab + 1);
		} else {
			console.log('Validation failed. Please fill in all required fields.');
		}
	};

    const handleExamBoardSelect = (board) => {
		setSelectedBoard(board);
	};

    const onSubmitClickHandler = async (formData) => {
        const phNumber = localStorage.getItem("PhoneNumber")
        const email = phNumber.includes('@') ? phNumber : `${phNumber?.slice(1)}@getyasa.com`
        const uid = userInfo?.uid ? userInfo?.uid : loginToken?.localId;

        const studentObj = {
            StudentUID: uid,
            StudentEmail: email,
            StudentName: formData.StudentName,
            ReferralCode: formData.ReferralCode,
            StudentGradeID: Number(formData.StudentGradeID),
            StudentGenderID: Number(formData.StudentGenderID),
            StudentCountryID: Number(dialCode?.ID),
            StudentExaminationID: Number(formData.StudentExaminationID),
            StudentSubExaminationID: Number(formData.StudentSubExaminationID),
            StudentTargetExamID: 9,
            PhoneNumber: phNumber.includes('@') ? null : phNumber?.replace(/^\+(91|965)/, '')
        };

        dispatch(studentRegistration(studentObj))
            .then(() => {
                sessionSave(uid);
            })
            .catch(error => {
                console.error('Error during registration:', error);
            });
	};

    const sessionSave = async (uid) => {
        setIsApiCalling(true);
        const deviceId = generateDeviceId();
        sessionStorage.setItem("deviceId", deviceId)
    
        const data = {
          UserUID: uid,
          Maker: navigator.platform,
          Model: navigator.userAgent.split(" ")[0],
          DeviceID: deviceId,
          Platform: "Web",
          AppVersion: "1.0",
        };

        axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
          .then((res) => {
            setIsApiCalling(false);
            const customToken  = res.data.Token;
            sessionStorage.removeItem('IsActive');
            localStorage.setItem('customToken', customToken);
    
            const mySocketData = {
              AuthToken: customToken,
              authorization: JSON.parse(Cookies.get('loginToken')).idToken,
              DeviceID: deviceId,
              IsActive: "true"
            }
            socket.emit("LoginSession", JSON.stringify(mySocketData));
            const payload = {
              authorization: JSON.parse(Cookies.get('loginToken')).idToken,
              DeviceID: deviceId,
            }
    
            socket.emit('StudentOnline', JSON.stringify(payload));
            dispatch(getStudentProfile());
            // dispatch(getStudentDashBoard());
            navigate("/");
          })
          .catch((err) => {
            setIsApiCalling(false);
            console.log('Error: ', err);
          });
    };

    useEffect(() => {
		if (helperData) {
			const country = helperData?.Country.find((countryItem) => countryItem?.ID.toString() === localStorage.getItem('CountryID'))
      		setDialCode(country)
		}

	}, [helperData])
    
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 12,
        height: 12,
        border: '1px solid #F5F5F5',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f5f5f5',
        '&::before': {
            display: 'block',
            width: '12px',
            height: '12px',
            content: '""',
            backgroundColor: '#0F4D92',
            borderRadius: '100%',
            top: '0',
            left: '0',
            position: 'absolute',
        },
    });

    const BpRadio = (props) => {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    return (
        <div className="registration_wrapper">
            <Box className='login_wrapper'>
                <LoginSlider />
                <Box className="right_section">
                    <Box sx={{ width: '100%' }} className="tab-wrapper">
                        <Box className="custom-panel-wrapper">
                            <form onSubmit={handleSubmit(onSubmitClickHandler)}>
                            { tab === 0 &&
                                <Box className="login_form">
                                    <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize'}}>{translation?.PersonalInfo}</Typography>
                                    <Box>
                                        <Controller
                                            name="StudentName"
                                            className="inputName"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: translation?.NameIs }} 
                                            render={({ field }) => (
                                                <>
                                                <FormControl fullwidth className="inputName" sx={{ p: 0 }}>
                                                    <FormLabel sx={{ fontSize: "16px", fontWeight: "600", color: "#0F4D92", marginBottom: "8px" , width:"410px" }}>{translation?.Name}</FormLabel>
                                                    <TextField 
                                                        {...field} 
                                                        name="StudentName"
                                                        // label={translation?.Name}
                                                        placeholder={translation.EnterYourName}
                                                        sx={{
                                                            input: {
                                                                color: '#999999',
                                                                "&::placeholder": {
                                                                    opacity: 1,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {errors.StudentName && <small className="text-danger ">{errors.StudentName.message}</small>}
                                                </FormControl>
                                                </>
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <Controller
											name="StudentGenderID"
											control={control}
											defaultValue=""
											rules={{ required: translation?.GenderIs }} 
											render={({ field }) => (
												<FormControl>
													<FormLabel id="demo-customized-radios" sx={{ fontSize: "16px", fontWeight: "600", color: "#0F4D92", marginBottom: "8px" }}>{translation?.GenderName}</FormLabel>
													<RadioGroup
														row
														{...field} 
														name="customized-radios"
                                                        className="personalRadio"
														onClick={(e) => field.onChange(e.ID)}
													>

													{
														helperData?.Gender?.map((e)=> <FormControlLabel key={e.ID} value={e.ID} control={<BpRadio />} label={e.Value} />)
													}
													</RadioGroup>
										            {errors.StudentGenderID && <small className="text-danger">{errors.StudentGenderID.message}</small>}     
												</FormControl>
											)}
										/>
                                    </Box>
                                    {/* <Box sx={{mt: 1}}>
                                        <Controller
                                            name="ReferralCode"
                                            className="inputName"
                                            control={control}
                                            defaultValue=""
                                            // rules={{ required: translation?.NameIs }} 
                                            render={({ field }) => (
                                                <FormControl fullwidth className="inputName" sx={{ p: 0 }}>
                                                    <FormLabel sx={{ fontSize: "16px", fontWeight: "600", color: "#0F4D92", marginBottom: "8px" }}>{translation?.ReferralCode} <HelpOutlineIcon sx={{mb: 0.5, p: '2px'}} /> </FormLabel>
                                                    <TextField 
                                                        {...field} 
                                                        name="ReferralCode"
                                                        placeholder={translation?.ReferralCode} 
                                                        // label={translation?.ReferralCode}  
                                                        sx={{
                                                            input: {
                                                                color: '#999999',
                                                                "&::placeholder": {
                                                                    opacity: 1,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {errors.ReferralCode && <small className="text-danger ">{errors.ReferralCode.message}</small>}
                                                </FormControl>
                                            )}
                                        />
                                    </Box>  */}
                                    <Button className="next_btn" onClick={handleRedeemNext}>{translation?.NextBtn}</Button>
                                </Box> }
                                
                            { tab === 1 && <Box>
                                <Box className="BackBtn">
                                    <Button className='backBtn' variant="text" sx={{ mb: 2 }} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.ProfileBackBtn} </Button>
                                </Box>
                                <Box className="login_form selectClass selectExam">
                                    <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '16px', textTransform: 'capitalize' }}>{translation?.SelectExamination}</Typography>
                                    <Typography sx={{ fontSize: '18px', color: '#555555', fontWeight: '600', marginBottom: '16px', textTransform: 'capitalize' }}>{translation?.YourKeyToUnlocking}</Typography>
                                    <Box className="cusbtnFlx">
										<Controller
											name="StudentExaminationID"
											control={control}
											defaultValue=""
											rules={{ required: translation?.ExamBoardIs }}
											render={({ field }) => (
											    <FormControl>
													{boards?.map((board) => (
                                                        <Button
                                                            {...field}
                                                            key={board?.ID}
                                                            sx={{ px: 3 }}
                                                            className={field.value === board?.ID ? 'active' : ''}
                                                            onClick={() => {
                                                                field.onChange(board?.ID)
                                                                handleExamBoardSelect(board)
                                                            }}
                                                        >
                                                            {board?.Value}
                                                        </Button>
													))}
												    {errors.StudentExaminationID && <small className="text-danger">{errors.StudentExaminationID.message}</small>}
											    </FormControl>
											)}
										/>
                                    </Box>
                                    { selectedBoard?.SubBoards.length > 0 ?
										<Box fullwidth className='cusbtnFlx'>
											<Controller
												name="StudentSubExaminationID"
												control={control}
												defaultValue=""
												rules={{ required: translation?.SubExamBoardIs }} 
												render={({ field }) => (
												<FormControl>
													<FormLabel className="sub-exam">{translation?.SelectSubExamination}</FormLabel>
														{selectedBoard && selectedBoard?.SubBoards?.map((subboard) => (
                                                            <Button
                                                                {...field}
                                                                variant='outlined'
                                                                sx={{ px: 3 }}
                                                                onClick={() => field.onChange(subboard?.ID)}
                                                                className={field.value === subboard?.ID ? 'active' : ''}
                                                            >
                                                                {subboard?.Value}
                                                            </Button>
														))}
													    {errors.StudentSubExaminationID && <small className="text-danger">{errors.StudentSubExaminationID.message}</small>}
													</FormControl>
												)}
												/>
											</Box> : <></>
										}
                                    <Button className="next_btn" onClick={handleNext}>{translation?.NextBtn}</Button>
                                </Box>
                            </Box> }

                            { tab === 2 && <Box sx={{ p: 3}}>
                                <Box className="BackBtn" onClick={handleBack}>
                                    <Button className='backBtn' variant="text" sx={{ mb: 2 }} startIcon={<ArrowBackIcon />} > {translation?.ProfileBackBtn} </Button>
                                </Box>
                                <Box className="login_form selectClass">
                                    <Typography variant="h3" sx={{ fontSize: '24px', color: '#0F4D92', fontWeight: '600', marginBottom: '8px', textTransform: 'capitalize' }}>{translation?.SelectClass}</Typography>
                                    <Box className="cusbtnFlx">
                                        <Controller
                                            name="StudentGradeID"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: translation?.GradeIs }}
                                            render={({ field }) => (
                                                <FormControl>
                                                    {selectedBoard?.Grades.map((grade) => (
                                                        <Button
                                                            {...field}
                                                            key={grade?.ID}
                                                            className={field.value == grade.ID ? 'active' : ''}
                                                            onClick={() => field.onChange(grade.ID)}
                                                        >
                                                            {grade.Value}
                                                        </Button>
                                                    ))}
                                                    {errors.StudentGradeID && <small className="text-danger">{errors.StudentGradeID.message}</small>}
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                    <Button type="submit" className="next_btn" disabled={isApiCalling}>{translation?.Submit}</Button>
                                </Box>
                            </Box> }
                            </form>
                        </Box>
                    </Box>
                </Box>
                <img className="penguine_abs" src={penguine} alt="penguine" />
                <FlagDropDown />
            </Box>
        </div>
    );
};

export default Registration;
