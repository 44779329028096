import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";

import { InlineMath  } from "react-katex";
import "katex/dist/katex.min.css";

import ShareWithFriend from '../common-components/ShareWithFriend';
import usePracticeTest from '../../custom-hooks/usePracticeTest';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';

import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";


const PracticeTest = () => {
    const navigate = useNavigate();
    const { subjectId, chapterId } = useParams();
    const translations = useTranslations();
    const { getSubject, getChapter } = useStudyMaterial()
    const chapter = getChapter(atob(subjectId), atob(chapterId))
    const subject = getSubject(atob(subjectId));
    const { questions } = usePracticeTest(atob(subjectId), atob(chapterId));

    const translation = {
        ...translations[36]?.PracticeTest
    }

    const handleQuestion = (question) => {
        navigate(`/practice-test-question/${subjectId}/${chapterId}/${btoa(question?.ID)}`)
    }

    useEffect(() => {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }, []);

  return (
    <div>
     <Header/>
     <HeroSection/>
     <Box className='OuterContainer' sx={{background:"#fff"}}>
            <Box className='studyContainer'>
                <SubTopHead subject={subject} />

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <Box className="QuestionHead">
                            <Typography variant='h2' sx={{margin:0}}>
                                {chapter?.ChapterName}
                            </Typography>
                            <ul>
                                <li>{chapter?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                                {/* <li>{chapter?.NumberOfTopic} Topics</li> */}
                            </ul>
                        </Box>


                        <Box className="chaptersBox" sx={{mt:2, display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="chapterBoxIn cream" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Typography variant='div'>
                                    {questions?.reduce((acc, current) => current.QuestionStatus === 'UNDERSTOOD' ? acc = acc + 1 : acc, 0)} / {chapter?.NumberOfQuestion}
                                </Typography>
                                <Typography >
                                    {translation?.PracticeQuestion} {translation?.PracticeUnderstood}  
                                </Typography>
                            </Box>
                            <Box className="chapterBoxIn sky" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Typography variant='div'>
                                    {questions?.reduce((acc, current) => current.QuestionStatus === 'ATTEMPTTED' || current.QuestionStatus === 'UNDERSTOOD' ? acc = acc + 1 : acc, 0)} / {chapter?.NumberOfQuestion}
                                </Typography>
                                <Typography>
                                    {translation?.PracticeQuestion} {translation?.PracticeStarted} 
                                </Typography>
                            </Box>
                        </Box>


                        <ShareWithFriend />
                        <Box className="mainHeading">
                            <Typography variant='h3'>
                                {translation?.PracticeQuestion}
                            </Typography>
                        </Box>

                        <Box className="Classification questionSet1">

                        { questions?.length > 0 && questions.map((question, index) => {
                            return (
                                <Box className="cardMain" sx={{ mb:2}} key={question?.ID}>
                                    <Box className="cardTop">
                                        <Box className="cardRight">
                                            <Box className="cardIcon" sx={{ mb:1}}>
                                            <Typography variant='div'>
                                                {index + 1}
                                            </Typography>
                                            </Box>
                                            <Box className="cardContent">
                                                {/* <Typography variant='h4' sx={{mb:1, cursor: 'pointer'}} onClick={() => handleQuestion(question)}><InlineMath math={question?.Question} /></Typography> */}
                                                <Typography variant='h4' sx={{mb:1, cursor: 'pointer'}} onClick={() => handleQuestion(question)}><Typography dangerouslySetInnerHTML={{ __html: question?.Question }} /></Typography>
                                                { question?.SelectedAnswerID ?
                                                    <Box className="progressNumber questionView">
                                                        <span className="green">{translation?.PracticeAttempted}</span>
                                                        <span className={`${question.QuestionStatus === 'UNDERSTOOD' ? 'green' : 'understood'}`}>{question.QuestionStatus === 'UNDERSTOOD' ? translation?.PracticeUnderstood : translation?.PracticeNotUnderstood}</span>
                                                    </Box> : 
                                                    <Box className="cardLeft" onClick={() => handleQuestion(question)}>
                                                        <Button variant="contained">{translation?.StartPractice}</Button>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        {/* { question?.SelectedAnswerID ?
                                            <Box className="cardLeft">
                                                <Button variant="contained" onClick={(e) => handleQuestion(question)}>{translation?.PracticeCompleted}</Button>
                                            </Box> : <></>
                                        } */}
                                    </Box>
                                </Box>
                            )
                            }) }

                            {/* <Box className="cardMain" sx={{ mb:2}}>
                                <Box className="cardTop">
                                    <Box className="cardRight">
                                        <Box className="cardIcon" sx={{ mb:1}}>
                                        <Typography variant='div'>
                                            1
                                        </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4' sx={{ mb:1}}>Which is definition of continuous variation is correct ?</Typography>
                                            <Box className="progressNumber questionView">
                                                <span className='understood'>not understood </span>
                                                <span className='started'>Attempted</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="cardMain" sx={{ mb:2}}>
                                <Box className="cardTop">
                                    <Box className="cardRight">
                                        <Box className="cardIcon" sx={{ mb:1}}>
                                        <Typography variant='div'>
                                            2
                                        </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4' sx={{ mb:1}}>The diagram shows how homo sapiens (modern people) could have evolved from earlier ancestors.</Typography>
                                            <Box className="imageBox" sx={{mt:1, mb:1}}>
                                            <img src={cardIcon}></img>
                                            </Box>
                                            <Typography variant='h4' sx={{ mb:1}}>The diagram shows how homo sapiens (modern people) could have evolved from earlier ancestors.</Typography>
                                            <Box className="cardLeft">
                                                <Button variant="contained">start practice</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="cardMain" sx={{ mb:2}}>
                                <Box className="cardTop">
                                    <Box className="cardRight">
                                        <Box className="cardIcon" sx={{ mb:1}}>
                                        <Typography variant='div'>
                                           3
                                        </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4' sx={{ mb:1}}>The table shows the birth rates and death rates in four countries. Which country will double its population most quickly ?</Typography>
                                            <Box className="cardLeft">
                                                <Button variant="contained" className='darkYellow'>continue</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="cardMain">
                                <Box className="cardTop">
                                    <Box className="cardRight">
                                        <Box className="cardIcon" sx={{ mb:1}}>
                                        <Typography variant='div'>
                                            4
                                        </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4' sx={{ mb:1}}>What is an example of a characteristic that shows discontinuous variation in humans ?</Typography>
                                            <Box className="cardLeft">
                                                <Button variant="contained" className='blueBtn'>start practice</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box> */}
                        </Box>
                       
                    </Box>
                </Box>
            </Box>
        </Box>
   
     <FooterSection/>
    </div>
  )
}

export default PracticeTest