import React from "react";
import { Button } from "@mui/material";
import './NoPage.css';
import { useNavigate  } from "react-router-dom";
import useTranslations from "../../custom-hooks/useTranslations";

import pageNotFound from "../../assets/images/page-not-found.png"
import footerCloud from "../../assets/images/footer-cloud.png"

function NoPage() {
	const navigate = useNavigate();
	const translations = useTranslations();
	const message = translations[2]?.EditProfile
	const navigateToDashboard = ()=>{
		navigate('/')
	}

  return (
    <div className="mul-dev">
      <div className="mul-dev-col">
        <h2 className="blue-text">ohhh...</h2>
        <img src={pageNotFound} alt="devices"/>
        <h2 className="blue-text">No data found</h2>
        <Button type="button" className="mul-btn" onClick={()=>navigateToDashboard()}>Back to Home</Button>
      </div>
      {/* <img className="footer-cloud" src={footerCloud} alt="cloud"/> */}
    </div>
  )
}

export default NoPage