import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getExamBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXAM_BOARD:
      return {
        board: action.payload.board,
      };

    default:
      return state;
  }
};
export default getExamBoardReducer;
