import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import i18n from "./i18n";

import { BrowserRouter } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import configureStore from "./store";
import { Provider } from "react-redux";
import App from "./App.js";


const store = configureStore();

TimeAgo.addDefaultLocale(en);

export default function Main () {

  const setLanguage = () => {
    if (localStorage.getItem("I18N_LANGUAGE") === "en") {
      localStorage.setItem("I18N_LANGUAGE", "en");
      i18n.changeLanguage("en");
      document.body.dir = i18n.dir();
    }
  };

  useEffect(() => {
    setLanguage();
  }, []);
  
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
