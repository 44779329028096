import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionbyChapter, getQuestionbyChapterSuccess } from "../actions/getQuestionByChapter";

const usePracticeTest = (subjectId, chapterId) => {
  const dispatch = useDispatch();
  const questions = useSelector(({ getQuestionByChapterReducer }) => getQuestionByChapterReducer.questionByChapter || []);

  const getPracticeTest = useCallback(() => {
    if (questions.length === 0) {
      dispatch(getQuestionbyChapter({ TopicID: subjectId, ChapterID: chapterId }))
    }
  }, [subjectId, chapterId]);

  const getQuestion = useCallback(
    (questionId) => {
      const index = questions.findIndex(question => question.ID.toString() === questionId.toString());
      if (index !== -1) {
        return { ...questions[index], QuestionIndex: index + 1 };
      }
      return {};
    },
    [questions]
  );

  const updateAttemptedQuestion = useCallback(
    (questionId, selectedAnswerId, selectedAnswer) => {
      const index = questions.findIndex((question) => question.ID == questionId);
    
      if (index !== -1) {
        questions[index] = { 
          ...questions[index], 
          SelectedAnswerID: selectedAnswerId,
          SelectedAnswer: selectedAnswer,
          QuestionStatus: 'ATTEMPTTED'
        };
      } 

      dispatch(getQuestionbyChapterSuccess({ Data: questions }));
    },
    [questions]
  );

  const updateQuestionStatus = useCallback(
    (questionId, questionStatus) => {

      const index = questions?.findIndex((question) => question.ID == questionId);
      if (index !== -1) {
        questions[index] = { 
          ...questions[index], 
          QuestionStatus: questionStatus
        };
      } 

      dispatch(getQuestionbyChapterSuccess({ Data: questions }));
    },
    [questions]
  );

  useEffect(() => {
    getPracticeTest();
  }, [getPracticeTest]);

  return { questions, getQuestion, updateAttemptedQuestion, updateQuestionStatus };
};

export default usePracticeTest;
