import * as actionTypes from "../actions/actionTypes";

const initialState = {student: []};

const studentProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_PROFILE:
      return {
        ...state,
        student: [action.payload.student],
      };

    default:
      return state;
  }
};
export default studentProfileReducer;
