import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const getMockTestQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MOCK_TEST_QUESTIONS:
      return {
        mockTestQuestions: action.payload.mockTestQuestions,
      };

    default:
      return state;
  }
};
export default getMockTestQuestionsReducer;
