import React from "react"
import { Button, Stack, Chip, TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import useHelperData from "../../custom-hooks/useHelperData";

const QuestionReportModal = ({ isOpen, report, translation, setIsReport, onChangeReport, onSubmitReport }) => {
    const { helperData } = useHelperData();

    return <Dialog
        open={isOpen}
        onClose={() => setIsReport(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>{translation?.ReportAnIssue}</DialogTitle>
        <DialogContent>
            <div className='mb-3 ratinbox'>
                <p className='title-info'>{translation?.HelpUsUnderstand}</p>
                <Stack direction="row" spacing={1} className='rating-menus'>
                    {helperData?.QuestionReportMessages?.map((item) => (
                        <Chip
                            key={item.ID}
                            sx={{direction: 'ltr'}}
                            label={item.Value}
                            onClick={() => onChangeReport("ReportID", item.ID)}
                            className={report?.ReportID === item.ID ? "cis-selected" : ""}
                            variant="outlined"
                        />
                    ))}
                </Stack>
            </div>
            <div className='mb-3 ratinbox'>
                <TextField
                    id="outlined-controlled"
                    className="report-message"
                    placeholder={translation?.WantToAddMore}
                    value={report?.Notes}
                    onChange={(e) => onChangeReport("Notes", e.target.value)}
                    fullwidth
                />
            </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center',  gap: 2, pb: 3 }}>
        <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={() => setIsReport(false)}>
            {translation?.QuickCancel}
        </Button>
        <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={onSubmitReport}>
            {translation?.QuickSubmit}
        </Button> 
        </DialogActions>
    </Dialog>
}

export default QuestionReportModal;