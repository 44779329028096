import * as actionTypes from "../actions/actionTypes";

const initialState = [];
const getQuestionByChapterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUESTION_BY_CHAPTER:
      return {
        questionByChapter: action.payload.questionByChapter,
      };

    default:
      return state;
  }
};
export default getQuestionByChapterReducer;
