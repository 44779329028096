import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const quickCallsRecentSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUICK_CALLS_RECENT_SESSIONS:
      return {
        sessions: action.payload.sessions,
      };

    case actionTypes.TOGGLE_TUTOR_ONLINE_STATUS:
      const { TutorID } = action.payload;
      return {
        sessions: state.sessions.map(session => {
          if (session.TutorID === TutorID) {
            // Toggle FavouriteTutor for the specific tutor
            return {
              ...session,
              FavouriteTutor: !session.FavouriteTutor,
            };
          }
          return session;
        }),
      };

    default:
      return state;
  }
};

export default quickCallsRecentSessionReducer;
