import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getStudyMaterialSuccess } from "../actions/getStudyMaterial";

const useStudyMaterial = (subjectId, chapterId) => {
  const dispatch = useDispatch();
  const studyMaterial = useSelector(({ getStudyMaterialReducer }) => getStudyMaterialReducer.studyMaterial || []);

  const getStudyMaterial = useCallback(() => {
    const data = { TopicID: subjectId, ChapterID: chapterId };

    if (studyMaterial.length === 0) {
      axiosPost(`${API_BASE_URL}/GetStudyMaterial`, data)
        .then((res) => {
          dispatch(getStudyMaterialSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [subjectId, chapterId, studyMaterial.length, dispatch]);

  const getSubject = useCallback(
    (subjectId) => {
      return studyMaterial.find(subject => subject.ID.toString() === subjectId.toString()) || {};
    },
    [studyMaterial]
  );

  const getChapter = useCallback(
    (subjectId, chapterId) => {
      const subject = studyMaterial.find(subject => subject.ID.toString() === subjectId.toString());
      if (subject) {
        const index = subject.Chapters.findIndex(chapter => chapter.ID.toString() === chapterId.toString());
        if (index !== -1) {
          return { ...subject.Chapters[index], ChapterIndex: index + 1 };
        }
      }
      return {};
    },
    [studyMaterial]
  );

  const getAttemptedChapters = useCallback(
    () => {
      const attemptedChapters = [];
      studyMaterial.forEach((subject) => {
        const subjectId = subject.ID; 
        const subjectName = subject.SubjectName;

        subject.Chapters.forEach((chapter) => {
          if (chapter.ChapterStatus === 'Attempted' && chapter.ChapterCompleted === false) {
            attemptedChapters.push({
              ...chapter,
              SubjectID: subjectId,
              SubjectName: subjectName,
            });
          }
        });
      });
      return attemptedChapters;
    },
    [studyMaterial]
  );

  const updateChapterQuestionCount = useCallback(
    (subjectId, chapterId, data) => {
      if (!studyMaterial) return;
  
      const updatedData = studyMaterial?.map((subject) => {
        if (subject.ID != subjectId) return subject;
        let chapterCompleted = false;
        const updatedChapters = subject.Chapters.map((chapter) => {
          if (chapter.ID != chapterId) return chapter;
  
          const { QuestionAttempted, QuestionUnderstood, NumberOfQuestion  } = chapter;
          const { QuestionAttempted: newAttempted, QuestionUnderstood: newUnderstood } = data;
          chapterCompleted = NumberOfQuestion === (QuestionAttempted + newAttempted)
          return {
            ...chapter,
            ChapterStatus: 'Attempted',
            QuestionAttempted: QuestionAttempted + newAttempted,
            QuestionUnderstood: QuestionUnderstood + newUnderstood,
            ChapterCompleted: NumberOfQuestion === (QuestionAttempted + newAttempted),
          };
        });
  
        return {
          ...subject,
          SubjectStatus: 'Attempted',
          ChapterNotStarted: updatedChapters.reduce((acc, curr) => curr.ChapterStatus === 'Pending' ? acc = acc + 1: acc, 0),
          ChapterPractising: updatedChapters.reduce((acc, curr) => curr.ChapterStatus === 'Attempted' ? acc = acc + 1: acc, 0),
          NumberOfCompletedChapter: chapterCompleted ? subject.NumberOfCompletedChapter + 1 : subject.NumberOfCompletedChapter,
          Chapters: updatedChapters,
        };
      });
  
      dispatch(getStudyMaterialSuccess({ Data: updatedData }));
    },
    [studyMaterial]
  );

  useEffect(() => {
    getStudyMaterial();
  }, [getStudyMaterial]);

  return { studyMaterial, getSubject, getChapter, getAttemptedChapters, updateChapterQuestionCount };
};

export default useStudyMaterial;