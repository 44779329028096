import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTargetExamReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TARGET_EXAM_BOARD:
      return {
        exam: action.payload.exam,
      };

    default:
      return state;
  }
};
export default getTargetExamReducer;
