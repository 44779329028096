import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const getGenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GENDER:
      return {
        gender: action.payload.gender,
      };

    default:
      return state;
  }
};
export default getGenderReducer;