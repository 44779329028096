import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Snackbar } from '@mui/material';
import Cookies from 'js-cookie';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import Header from '../header/Header';
import FooterSection from '../footer/FooterSection';

import '../../App.css';
import '../subject/Subject.css';
import '../private-class/PrivateClass.css';
import '../responsive/Responsive.css';
import './ReferEarn.css';

import referReward from '../../assets/images/referReward.png';
import creditImg from '../../assets/images/creditImg.png';
import copyImg from '../../assets/images/copyImg.svg';
import shareBtn from '../../assets/images/share.svg';
import whatsBtn from '../../assets/images/whatsBtn.svg';

import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '../../custom-hooks/useTranslations';
import { getReferralRecord } from '../../actions/getReferralRecordAction';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosGet } from '../../services/api';


const ReferEarn = () => {
    const dispatch = useDispatch();
    const translations = useTranslations();
    const [copied, setCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [profileInfo, setProfileInfo] = useState(Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null);
    // const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

    const translation = {
        ...translations[3]?.MenuScreen,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[33]?.ApplicationConfiguration,
        ...translations[35]?.Referral
    }

    const referralRecord = useSelector(({ getReferralRecordReducer }) => getReferralRecordReducer?.referralRecord);

    const handleCopy = () => {
        navigator.clipboard.writeText(profileInfo?.ReferralCodeStudent).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Hide the message after 2 seconds
        }).catch(err => {
          console.error('Failed to copy: ', err);
        });
    };

    const handleShare = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              title: document.title,
              text: 'Check out this link!',
              url: window.location.origin,
            });
          } else {
            setIsOpen(true);
          }
        } catch (error) {
          console.error('Error sharing:', error.message);
        }
    };

    const handleClosed = () => {
        setIsOpen(false);
    };

    const handleRedeem = async () => {
        try {
            const response = await axiosGet(`${API_BASE_URL}/WithdrawReferralAmount`);

            if (response) {
                const updatedProfile = {
                    ...profileInfo,
                    CreditEarned: "0"
                };
    
                setProfileInfo(updatedProfile);
                // sessionStorage.setItem("ProfileInfo", JSON.stringify(updatedProfile));
                Cookies.set('ProfileInfo', JSON.stringify(updatedProfile));
            }


        } catch (error) {
            console.log('Error: ', error);
        }
    }
    

    const handleWhatsAppShare = () => {
        const message = `Check out this link! ${profileInfo?.StudentReferralURL + profileInfo?.ReferralCodeStudent}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsAppLink = `https://wa.me/?text=${encodedMessage}`;
        const whatsappAppLink = `whatsapp://send?text=${encodedMessage}`;
      
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
        if (isMobile) {
          window.location.href = whatsappAppLink;
        } else {
          window.open(whatsAppLink, '_blank');
        }
    };

    useEffect(() => {
        dispatch(getReferralRecord());
    }, [])

    return (
        <div>
            <Header />
            <Snackbar
                open={copied}
                autoHideDuration={3000}
                onClose={handleCopy}
                message={translation?.Copied}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            <Box className="quikCallBanner">
                <Box className="OuterContainer" sx={{ border: 'none', borderRadius: '0px' }}>
                    <Typography variant="h1">
                        {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className="OuterContainer containerShape" sx={{ background: '#EAF4FF', padding: '30px', paddingTop: '30px' }}>
                <Box className="whiteBg">
                    <Box className="ReferWrap">
                        <Box className="referrewardImg">
                            <img src={referReward} alt="Refer Reward" />
                        </Box>
                        <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: '600', lineHeight: 1, marginBottom: '8px', color: '#005DB8', textTransform: 'capitalize' }}>
                            {translation?.ReferEarn}
                        </Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: 1, marginBottom: '16px', color: '#555555', textTransform: 'capitalize' }}>
                            {translation.TellAboutYasa} <Typography component="span" sx={{ color: '#005DB8' }}>{translation?.YouWillGetCredit} {translation?.ReferralCredit}</Typography> {translation?.ForFree}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, marginBottom: '8px', color: '#767676', textTransform: 'capitalize' }}>
                            {translation?.WhenYourFriendAsk} <Typography component="span" sx={{ color: '#EEA800' }}>({translation?.TC})</Typography>
                        </Typography>
                        <Box className="friendCredit">
                            <Box className="friendCreditLeft">
                                <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1, marginBottom: '8px', color: '#555555', textTransform: 'capitalize' }}>
                                    {translation?.YourFriendWillGet}
                                </Typography>
                                <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, marginBottom: '5px', color: '#005DB8', textTransform: 'capitalize' }}>
                                    {translation?.YourFriendWillGetCredit} {translation?.ReferralCredit}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1, color: '#555555', textTransform: 'capitalize' }}>
                                    {translation?.ForFree}
                                </Typography>
                            </Box>
                            <img src={creditImg} alt="Credit" style={{ minWidth: '40px' }} />
                        </Box>
                        {/* <Box className="copyRefer">
                            <img src={copyImg} alt="Copy" onClick={handleCopy} />
                            {copied && (
                                <Typography sx={{ marginTop: '10px', color: 'green', fontSize: '14px', fontWeight: '700' }}>
                                    {translation?.Copied}
                                </Typography>
                            )}
                            <Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, marginBottom: '2px', color: '#767676', textTransform: 'capitalize' }}>
                                    {translation?.YourReferralCode}
                                </Typography>
                                <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, marginBottom: '2px', color: '#005DB8', textTransform: 'uppercase' }}>
                                    {profileInfo?.ReferralCodeStudent}
                                </Typography>
                            </Box>
                        </Box> */}
                        <Box className="copyRefer">
                            <Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, marginBottom: '2px', color: '#767676', textTransform: 'capitalize' }}>
                                    {translation?.YourReferralCode}
                                </Typography>
                                <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, marginBottom: '2px', color: '#005DB8', textTransform: 'uppercase' }}>
                                    {profileInfo?.ReferralCodeStudent}
                                </Typography>
                            </Box>
                            {/* { copied && (
                                <Typography sx={{ marginTop: '10px', color: 'green', fontSize: '14px', fontWeight: '700' }}>
                                    {translation?.Copied}
                                </Typography>
                            )} */}
                            <img src={copyImg} alt="Copy" onClick={handleCopy} />
                        </Box>
                        <Box className="shareSocial">
                            <Button className="shareBtn">
                                <Typography variant="span" sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, color: '#ffffff', textTransform: 'capitalize' }} onClick={handleShare}>
                                    {translation?.ReferralShare}
                                </Typography><img src={shareBtn} alt="" sx={{ marginLeft: '5px' }} /></Button>
                            <Button className="whatsappBtn">
                                <Typography variant="span" sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, color: '#ffffff', textTransform: 'capitalize' }} onClick={handleWhatsAppShare}>
                                    {translation?.ReferralWhatsApp}
                                </Typography><img src={whatsBtn} alt="" sx={{ marginLeft: '5px' }} />
                            </Button>
                        </Box>
                        <Box className="refTot">
                            <Box className="refTotBox">
                                <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>{translation?.ReferralEarning}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, color: '#555555', textTransform: 'capitalize', marginBottom: '6px' }}>{referralRecord?.TotalCredit} {translation?.ReferralCredit}</Typography>
                            </Box>
                            <Box className="refTotBox">
                                <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: 1, color: '#EA4435', textTransform: 'capitalize', marginBottom: '6px' }}>{translation?.TotalPaidOut}</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, color: '#555555', textTransform: 'capitalize', marginBottom: '6px' }}>{referralRecord?.TotalPaidOut} {translation?.ReferralCredit}</Typography>
                            </Box>
                        </Box>
                        { parseInt(profileInfo?.CreditEarned) > 100 &&
                            <Box className="redeem">
                                <Button className="redeemBtn" onClick={handleRedeem}>
                                    <Typography variant="span" sx={{ fontSize: '16px', fontWeight: '600', lineHeight: 1, color: '#ffffff', textTransform: 'capitalize' }}>
                                        {translation?.RedeemNow}
                                    </Typography>
                                </Button>
                            </Box>
                        }
                        <Box className="creditrecord">
                            {/* <Box className="crFlex crHead">
                                <Box className="crFlexLeft">
                                    <FormControl fullWidth className="selectCredit">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            defaultValue={10}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>All</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box className="crFlexRight">
                                    <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>100 Credits</Typography>
                                </Box>
                            </Box> */}
                            <Box className="crBody">
                                {/* <Box className="crFlex">
                                    <Box className="crFlexLeft">
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: 1, color: '#B0CDE9', textTransform: 'capitalize', marginBottom: '10px' }}>abcd</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1.1, color: '#D3D3D3', textTransform: 'capitalize' }}>16-may-2024</Typography>
                                    </Box>
                                    <Box className="crFlexRight">
                                        <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>25 دك</Typography>
                                    </Box>
                                </Box> */}
                                { referralRecord?.ReferralList?.map((item, index) => {
                                    return (
                                        <Box className="crFlex" key={index}>
                                            <Box className="crFlexLeft">
                                                <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: 1, color: '#555555', textTransform: 'capitalize', marginBottom: '10px' }}>{item.TransactionID}</Typography>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1.1, color: '#D3D3D3', textTransform: 'capitalize' }}>{new Date(item.DateTime).toLocaleDateString()}</Typography>
                                            </Box>
                                            <Box className="crFlexRight">
                                                <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>{item.Credit} {translation?.KuwaitiDinar}</Typography>
                                            </Box>
                                        </Box> )
                                }) }
                                
                                {/* <Box className="crFlex">
                                    <Box className="crFlexLeft">
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: 1, color: '#555555', textTransform: 'capitalize', marginBottom: '10px' }}>abcd</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1.1, color: '#D3D3D3', textTransform: 'capitalize' }}>16-may-2024</Typography>
                                    </Box>
                                    <Box className="crFlexRight">
                                        <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>25 دك</Typography>
                                    </Box>
                                </Box>
                                <Box className="crFlex">
                                    <Box className="crFlexLeft">
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: 1, color: '#555555', textTransform: 'capitalize', marginBottom: '10px' }}>abcd</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1.1, color: '#D3D3D3', textTransform: 'capitalize' }}>16-may-2024</Typography>
                                    </Box>
                                    <Box className="crFlexRight">
                                        <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: 1, color: '#005DB8', textTransform: 'capitalize', marginBottom: '6px' }}>25 دك</Typography>
                                    </Box>
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={isOpen}
                onClose={handleClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{translation?.ShareWithYourFriend}</DialogTitle>
                <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', background: '#F9FCFF', padding: '10px 20px', gap: 4 }}>
                    <Box sx={{ fontSize: '16px', fontWeight: '600' }}>
                    {window.location.origin + window.location.pathname}
                    </Box>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleCopy}>
                    <ContentCopyOutlinedIcon />
                    </Box>
                </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                <Button
                    variant="contained"
                    sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
                    size="large"
                    className='cis-btn m-0'
                    onClick={handleClosed}
                >
                    {translation?.QuickCancel}
                </Button>
                </DialogActions>
            </Dialog>

            <FooterSection />
        </div>
    );
};

export default ReferEarn;
