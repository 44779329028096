import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/education.png';

import ShareWithFriend from '../common-components/ShareWithFriend';

const PreviousSolution = () => {
    const [value, setValue] = React.useState(0);

    function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
    const handleChange = (event, newValue) => {
		setValue(newValue);
	};
  return (
    <div>
        <Header/>
        <HeroSection/>
        <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                        <Button className='backBtn' variant="text" sx={{mb:1, paddingLeft:0}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />}> Back </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardContent">
                                    <Typography variant='h4'>solutions</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />
                        {/* <Box className="shareBox" sx={{mt:3, mb:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                            <Typography variant='div'>
                                <img src={shareImg}></img>
                            </Typography>
                            <Typography>
                                share with your friends
                            </Typography>
                            </Box>
                            <Box>
                            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.6148 25.0346H2.06429e-05V11.9639C-0.00237698 11.071 0.204115 10.19 0.602995 9.39111C1.14456 8.19601 2.01876 7.18211 3.12113 6.47054C4.22351 5.75897 5.50744 5.37984 6.81952 5.37844H13.667V0.96582H15.7911L24 9.17479L15.7619 17.4129H13.667V13.0002H9.21362C8.2821 13.0025 7.37673 13.3084 6.63479 13.8717C5.89284 14.4349 5.35479 15.2247 5.10221 16.1214L2.6148 25.0346ZM6.81952 6.98303C5.8138 6.9841 4.82976 7.27537 3.98547 7.82187C3.14119 8.36836 2.47254 9.14688 2.05976 10.064L2.04512 10.0948C1.75362 10.6746 1.60271 11.3149 1.60461 11.9639V22.6851L3.55669 15.6901C3.90418 14.4564 4.64449 13.3697 5.66535 12.5947C6.6862 11.8197 7.93193 11.3987 9.21362 11.3956H15.2715V15.634L21.7308 9.17479L15.2715 2.71552V6.98303H6.81952Z" fill="#4D4D4D"/>
                            </svg>
                            </Box>
                        </Box> */}
                        
                        <Box className="solutionBanner light-gray" sx={{mt:3, mb:0, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box className="contentBox">
                                <Typography>
                                    Exercise
                                </Typography>
                                <Typography variant='h3' sx={{margin:0}} >
                                    NCERT SOLUTIONS
                                </Typography>
                                <Box className="cardList">
                                    <ul>
                                        <li>NCERT Solutions </li>
                                        <li>10 Questions</li>
                                    </ul>
                                </Box>
                            </Box>
                            <Box className="imageBox">
                                <img src={cardIcon}></img>
                            </Box>
                        </Box>
                        <Box className="TabsBox">
                            <Box className="tabsBoxTop">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="all (10)" {...a11yProps(0)} />
                                <Tab label="medium (7)" {...a11yProps(1)} />
                                <Tab label="hard (3)" {...a11yProps(2)} />
                            </Tabs>
                            </Box>
                            <Box>
                            <TabPanel value={value} index={0}>
                                    <Box className="mainHeading">
                                        <Typography variant='h3' sx={{mb:1}}>
                                            questions
                                        </Typography>
                                    </Box>
                                    <Box className="questionType">
                                        <Box className="cardMain sky" sx={{mb:2, border:0, borderRadius:0}}>
                                            <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'}}}>
                                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'}}}>
                                                    <Box className="cardIcon">
                                                        <Typography variant='div'>
                                                            1
                                                        </Typography>
                                                    </Box>
                                                    <Box className="cardContent">
                                                        <Typography variant='div' className='hardBox lightPink'>
                                                        Hard
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="cardLeft">
                                                    <Typography variant='div' sx={{ mr:1}}>report</Typography>
                                                    <Typography variant='div' >
                                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.5 2.25C0.5 1.284 1.284 0.5 2.25 0.5H19.75C20.716 0.5 21.5 1.284 21.5 2.25V14.75C21.5 15.2141 21.3156 15.6592 20.9874 15.9874C20.6592 16.3156 20.2141 16.5 19.75 16.5H10.164C10.1311 16.4999 10.0986 16.5064 10.0682 16.5189C10.0379 16.5314 10.0103 16.5498 9.987 16.573L6.487 20.073C6.28305 20.2762 6.02352 20.4144 5.74112 20.4703C5.45872 20.5262 5.16609 20.4973 4.90011 20.3871C4.63414 20.277 4.40673 20.0905 4.24655 19.8513C4.08636 19.6121 4.00058 19.3309 4 19.043V18.25C4 17.2835 3.2165 16.5 2.25 16.5C1.78587 16.5 1.34075 16.3156 1.01256 15.9874C0.684375 15.6592 0.5 15.2141 0.5 14.75V2.25ZM2.25 2C2.1837 2 2.12011 2.02634 2.07322 2.07322C2.02634 2.12011 2 2.1837 2 2.25V14.75C2 14.888 2.112 15 2.25 15H4.75C4.94891 15 5.13968 15.079 5.28033 15.2197C5.42098 15.3603 5.5 15.5511 5.5 15.75C5.5 16.9272 6.92327 17.5167 7.75567 16.6843L8.927 15.513C9.25501 15.1848 9.69996 15.0002 10.164 15H19.75C19.8163 15 19.8799 14.9737 19.9268 14.9268C19.9737 14.8799 20 14.8163 20 14.75V2.25C20 2.1837 19.9737 2.12011 19.9268 2.07322C19.8799 2.02634 19.8163 2 19.75 2H2.25ZM11 4C11.1989 4 11.3897 4.07902 11.5303 4.21967C11.671 4.36032 11.75 4.55109 11.75 4.75V8.75C11.75 8.94891 11.671 9.13968 11.5303 9.28033C11.3897 9.42098 11.1989 9.5 11 9.5C10.8011 9.5 10.6103 9.42098 10.4697 9.28033C10.329 9.13968 10.25 8.94891 10.25 8.75V4.75C10.25 4.55109 10.329 4.36032 10.4697 4.21967C10.6103 4.07902 10.8011 4 11 4ZM11 13C10.7348 13 10.4804 12.8946 10.2929 12.7071C10.1054 12.5196 10 12.2652 10 12C10 11.7348 10.1054 11.4804 10.2929 11.2929C10.4804 11.1054 10.7348 11 11 11C11.2652 11 11.5196 11.1054 11.7071 11.2929C11.8946 11.4804 12 11.7348 12 12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13Z" fill="#727272"/>
                                                    </svg>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className="contentBox">
                                                <Typography variant='h3' sx={{mb:2}}>
                                                obtain the formula for the electric field due to a long thin wire of uniform linear density without using gauss’s law
                                                </Typography>
                                            </Box>

                                            <Box className="rightSolution orgbdr radius8">
                                            <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                    <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                    </svg>
                                                </Typography>
                                                <Typography  sx={{margin:0}}>
                                                    solution
                                                </Typography>
                                            </Box>
                                            <Box className="SolutionSummary">
                                                <Typography variant='h3' sx={{mb:1}}>
                                                The correct option is D
                                                </Typography>
                                                <Typography>
                                                Biological process of producing young ones
                                                </Typography>
                                            </Box>
                                        </Box>
                                        </Box>

                                        <Box className="cardMain" sx={{mb:2}}>
                                            <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'}}}>
                                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'}}}>
                                                    <Box className="cardIcon">
                                                        <Typography variant='div'>
                                                        2
                                                        </Typography>
                                                    </Box>
                                                    <Box className="cardContent">
                                                        <Typography variant='div' className='medium'>
                                                        Medium
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="contentBox">
                                                <Typography variant='h3' sx={{mb:2}}>
                                                obtain the formula for the electric field due to a long thin wire of uniform linear density without using gauss’s law
                                                </Typography>
                                            </Box>

                                        
                                        </Box>
                                    </Box>
                                
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                            <h3>Hello yasa 2</h3>
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <h3>Hello yasa 3</h3>
                            </TabPanel>
                            </Box>
                        </Box>                    
                    </Box>
                </Box>
            </Box>
        </Box>
        <FooterSection/>
    </div>
  )
}

export default PreviousSolution