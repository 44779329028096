import * as actionTypes from "../actions/actionTypes";

const initialState = [];
const getStudyMaterialDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDY_MATERIAL_DOCUMENT:
      return {
        studyMaterialDocument: action.payload.studyMaterialDocument,
      };

    default:
      return state;
  }
};
export default getStudyMaterialDocumentReducer;
