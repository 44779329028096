import TimeAgo from 'javascript-time-ago';
import en from "javascript-time-ago/locale/en.json";
import ar from "javascript-time-ago/locale/ar.json";


const timeAgoDate = (date) => {
  const d = new Date(date);

  // Load the English / Arabic locale
  TimeAgo.addLocale(localStorage.getItem("I18N_LANGUAGE") === "en" ? en : ar);

  // Initialize TimeAgo with English / Arabic locale
  const timeAgo = new TimeAgo(localStorage.getItem("I18N_LANGUAGE"));

  return date && timeAgo.format(d);
};

export default timeAgoDate;