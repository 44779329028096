import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Header from "../header/Header";
import SubTopHead from "../sub-top-head/SubTopHead";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import ShareWithFriend from '../common-components/ShareWithFriend';


import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/education.png';
import iconsBox1 from '../../assets/images/bookIcon.png';
import iconsBox2 from '../../assets/images/starIcon.png';
import iconsBox3 from '../../assets/images/bookIconAr.png';
import iconsBox4 from '../../assets/images/starIconAr.png';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useTranslations from '../../custom-hooks/useTranslations';
import useMockTest from '../../custom-hooks/useMockTest';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import { getMockTestQuestions } from '../../actions/getMockTestQuestions';

const MockTest = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subjectId } = useParams();
    const translations = useTranslations();
    const { mockTestList } = useMockTest(atob(subjectId))
    const { getSubject } = useStudyMaterial()
    const subject = getSubject(atob(subjectId));

    const translation = {
        ...translations[36]?.PracticeTest
    }

    const onMockTestClick = (test) => {
        dispatch(getMockTestQuestions({ MockTestID: test.ID }))
        navigate(`/mock-test-detail/${subjectId}/${btoa(test?.ID)}`)
    }

  return (
    <div>
    <Header/>
    <HeroSection/>

        <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <SubTopHead subject={subject} />
                {/* <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                            <Button className='backBtn' variant="text" sx={{mb:1, paddingLeft:0}}  startIcon={<ArrowBackIcon />}> Back </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardContent">
                                    <Typography variant='h4'>biology</Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>20 Chapters</li>
                                            <li>20 Topics</li>
                                            <li>820 Questions</li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box> */}

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />

                        <Box className="solutionBanner lightYellow" sx={{mt:3, mb:0, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box className="contentBox">
                                <Typography variant='h3' sx={{margin:0}} >
                                    {translation.MockTestForSubject?.replace('{XXX}', subject?.SubjectName)}
                                </Typography>
                            </Box>
                            <Box className="imageBox">
                                <img src={cardIcon}></img>
                            </Box>
                        </Box>
                        <Box className="attemptedHeading" sx={{background:'#FFEBCE', padding:1}}>
                            <Box sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                <Typography variant='div'>
                                    <img src={shareImg}></img>
                                </Typography>
                                <Typography>
                                    <Typography variant='div'>{subject?.AttemptedStudent} {translation?.Aspirants} {translation?.Have}</Typography> 
                                        {translation?.AttemptedSoFar}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="mockUpTest">
                             <Box className="mainHeading">
                                <Typography variant='h3' sx={{mb:1,mt:3}}>
                                    {translation?.AllTests} ({mockTestList?.length})
                                </Typography>
                            </Box>
                            {mockTestList?.length > 0 && 
                                mockTestList.map((test) => {
                                    return <Box className="attemptedCard" sx={{mb:2}} key={test.ID}>
                                        <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center'}}}>
                                            <Box className="cardIcon">
                                                <Typography variant='div'>
                                                <svg width="16" height="16" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_4431_11605)">
                                                <path d="M23.8872 29.3995V26.6439C23.8872 25.1823 23.3066 23.7805 22.2731 22.747C21.2395 21.7134 19.8377 21.1328 18.3761 21.1328H7.35388C5.89225 21.1328 4.49047 21.7134 3.45694 22.747C2.42341 23.7805 1.84277 25.1823 1.84277 26.6439V29.3995" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.8656 15.6238C15.9093 15.6238 18.3767 13.1564 18.3767 10.1127C18.3767 7.06897 15.9093 4.60156 12.8656 4.60156C9.8219 4.60156 7.35449 7.06897 7.35449 10.1127C7.35449 13.1564 9.8219 15.6238 12.8656 15.6238Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M32.1538 29.4001V26.6445C32.1529 25.4234 31.7465 24.2372 30.9984 23.2721C30.2503 22.3071 29.2028 21.6178 28.0205 21.3125" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M22.5107 4.77344C23.6962 5.07696 24.7469 5.7664 25.4973 6.73306C26.2476 7.69973 26.6549 8.88862 26.6549 10.1123C26.6549 11.336 26.2476 12.5249 25.4973 13.4916C24.7469 14.4582 23.6962 15.1477 22.5107 15.4512" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_4431_11605">
                                                <rect width="33.0667" height="33.0667" fill="white" transform="translate(0.46582 0.464844)"/>
                                                </clipPath>
                                                </defs>
                                                </svg>

                                                </Typography>
                                            </Box>
                                            <Box className="cardContent">
                                                <Typography>{test?.AttemptedUser} {translation?.PracticeAttempted}</Typography>
                                            </Box> 
                                        </Box>
                                        <Box className="contentBox" sx={{mb:1}}>
                                            <Typography variant='h3'>
                                                {test?.TestName}
                                            </Typography>
                                            <Box className="cardList">
                                                <ul>
                                                    <li>{test?.NumberOfQuestion} {translation?.PracticeQuestion}  </li>
                                                    <li>{test?.NumberOfMinute} {translation?.PracticsMins}</li>
                                                    {test?.StatusID === 1 && <li>{test?.NumberOfMark} {translation?.PracticeMarks}</li> }
                                                </ul>
                                            </Box>
                                        </Box>
                                        { test?.AttemptedCount === 0 ? <Box className="scoreBar midYellow" sx={{cursor: 'pointer'}} onClick={() => onMockTestClick(test)}>
                                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                                <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                                    <Box className="cardIcon">
                                                        <Typography variant='div'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.6667 7.33203H3.33333C2.59695 7.33203 2 7.92898 2 8.66536V13.332C2 14.0684 2.59695 14.6654 3.33333 14.6654H12.6667C13.403 14.6654 14 14.0684 14 13.332V8.66536C14 7.92898 13.403 7.33203 12.6667 7.33203Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M4.66602 7.33203V4.66536C4.66602 3.78131 5.01721 2.93346 5.64233 2.30834C6.26745 1.68322 7.11529 1.33203 7.99935 1.33203C8.8834 1.33203 9.73125 1.68322 10.3564 2.30834C10.9815 2.93346 11.3327 3.78131 11.3327 4.66536V7.33203" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="cardContent attemptStatus">
                                                        <Typography>{translation?.PracticeAttempt} </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="cardLeft" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                                    <Typography>{translation?.Syllabus} </Typography>
                                                    <Typography variant='div'>
                                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </Typography>
                                                </Box>
                                                
                                            </Box>
                                        </Box> :
                                        <Box className="scoreBar green" sx={{cursor: 'pointer'}} onClick={() => onMockTestClick(test)}>
                                            <Box className="cardTop" sx={{display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                                <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                                    <Box className="cardIcon">
                                                        <Typography variant='div'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_4431_11616)">
                                                        <path d="M14.6673 7.38527V7.99861C14.6665 9.43622 14.201 10.8351 13.3402 11.9865C12.4794 13.1379 11.2695 13.9803 9.89089 14.3879C8.51227 14.7955 7.03882 14.7465 5.6903 14.2483C4.34177 13.7501 3.19042 12.8293 2.40796 11.6233C1.6255 10.4173 1.25385 8.99065 1.34844 7.55615C1.44303 6.12165 1.99879 4.75616 2.93284 3.66332C3.86689 2.57049 5.12917 1.80886 6.53144 1.49204C7.93371 1.17521 9.40083 1.32017 10.714 1.90527" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.6667 2.66797L8 9.3413L6 7.3413" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_4431_11616">
                                                        <rect width="16" height="16"/>
                                                        </clipPath>
                                                        </defs>
                                                        </svg>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="cardContent attemptStatus">
                                                        <Typography>{translation?.MarkScored} <Typography variant='div'>{test?.Scored}/{test?.NumberOfMark}</Typography> 
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="cardLeft">
                                                    <Typography variant='div'><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    </Typography>
                                                </Box>
                                                
                                            </Box>
                                        </Box> }
                                    </Box>
                                })
                            }


                            {/* <Box className="attemptedCard">
                                <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center'}}}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4431_11605)">
                                        <path d="M23.8872 29.3995V26.6439C23.8872 25.1823 23.3066 23.7805 22.2731 22.747C21.2395 21.7134 19.8377 21.1328 18.3761 21.1328H7.35388C5.89225 21.1328 4.49047 21.7134 3.45694 22.747C2.42341 23.7805 1.84277 25.1823 1.84277 26.6439V29.3995" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.8656 15.6238C15.9093 15.6238 18.3767 13.1564 18.3767 10.1127C18.3767 7.06897 15.9093 4.60156 12.8656 4.60156C9.8219 4.60156 7.35449 7.06897 7.35449 10.1127C7.35449 13.1564 9.8219 15.6238 12.8656 15.6238Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M32.1538 29.4001V26.6445C32.1529 25.4234 31.7465 24.2372 30.9984 23.2721C30.2503 22.3071 29.2028 21.6178 28.0205 21.3125" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.5107 4.77344C23.6962 5.07696 24.7469 5.7664 25.4973 6.73306C26.2476 7.69973 26.6549 8.88862 26.6549 10.1123C26.6549 11.336 26.2476 12.5249 25.4973 13.4916C24.7469 14.4582 23.6962 15.1477 22.5107 15.4512" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_4431_11605">
                                        <rect width="33.0667" height="33.0667" fill="white" transform="translate(0.46582 0.464844)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography>25 k + Attempted</Typography>
                                    </Box> 
                                </Box>
                                <Box className="contentBox" sx={{mb:1}}>
                                    <Typography variant='h3'>
                                        Biology test 1
                                    </Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>30 Questions  </li>
                                            <li>90 Mins</li>
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="scoreBar green">
                                <Box className="cardTop" sx={{display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                    <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_4431_11616)">
                                            <path d="M14.6673 7.38527V7.99861C14.6665 9.43622 14.201 10.8351 13.3402 11.9865C12.4794 13.1379 11.2695 13.9803 9.89089 14.3879C8.51227 14.7955 7.03882 14.7465 5.6903 14.2483C4.34177 13.7501 3.19042 12.8293 2.40796 11.6233C1.6255 10.4173 1.25385 8.99065 1.34844 7.55615C1.44303 6.12165 1.99879 4.75616 2.93284 3.66332C3.86689 2.57049 5.12917 1.80886 6.53144 1.49204C7.93371 1.17521 9.40083 1.32017 10.714 1.90527" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.6667 2.66797L8 9.3413L6 7.3413" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4431_11616">
                                            <rect width="16" height="16"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography>Marks Scored <Typography variant='div'>13/56</Typography> </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="cardLeft">
                                        <Typography variant='div'><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</Typography>
                                    </Box>
                                     
                                </Box>
                                </Box>
                            </Box>

                            <Box className="attemptedCard" sx={{mt:2}}>
                                <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center'}}}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4431_11605)">
                                        <path d="M23.8872 29.3995V26.6439C23.8872 25.1823 23.3066 23.7805 22.2731 22.747C21.2395 21.7134 19.8377 21.1328 18.3761 21.1328H7.35388C5.89225 21.1328 4.49047 21.7134 3.45694 22.747C2.42341 23.7805 1.84277 25.1823 1.84277 26.6439V29.3995" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.8656 15.6238C15.9093 15.6238 18.3767 13.1564 18.3767 10.1127C18.3767 7.06897 15.9093 4.60156 12.8656 4.60156C9.8219 4.60156 7.35449 7.06897 7.35449 10.1127C7.35449 13.1564 9.8219 15.6238 12.8656 15.6238Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M32.1538 29.4001V26.6445C32.1529 25.4234 31.7465 24.2372 30.9984 23.2721C30.2503 22.3071 29.2028 21.6178 28.0205 21.3125" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.5107 4.77344C23.6962 5.07696 24.7469 5.7664 25.4973 6.73306C26.2476 7.69973 26.6549 8.88862 26.6549 10.1123C26.6549 11.336 26.2476 12.5249 25.4973 13.4916C24.7469 14.4582 23.6962 15.1477 22.5107 15.4512" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_4431_11605">
                                        <rect width="33.0667" height="33.0667" fill="white" transform="translate(0.46582 0.464844)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography>25 k + Attempted</Typography>
                                    </Box> 
                                </Box>
                                <Box className="contentBox" sx={{mb:1}}>
                                    <Typography variant='h3'>
                                        Biology test 1
                                    </Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>30 Questions  </li>
                                            <li>90 Mins</li>
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="scoreBar green">
                                <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                    <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_4431_11616)">
                                            <path d="M14.6673 7.38527V7.99861C14.6665 9.43622 14.201 10.8351 13.3402 11.9865C12.4794 13.1379 11.2695 13.9803 9.89089 14.3879C8.51227 14.7955 7.03882 14.7465 5.6903 14.2483C4.34177 13.7501 3.19042 12.8293 2.40796 11.6233C1.6255 10.4173 1.25385 8.99065 1.34844 7.55615C1.44303 6.12165 1.99879 4.75616 2.93284 3.66332C3.86689 2.57049 5.12917 1.80886 6.53144 1.49204C7.93371 1.17521 9.40083 1.32017 10.714 1.90527" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.6667 2.66797L8 9.3413L6 7.3413" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4431_11616">
                                            <rect width="16" height="16"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography>Marks Scored <Typography variant='div'>13/56</Typography> </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="cardLeft">
                                        <Typography variant='div'><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</Typography>
                                    </Box>
                                     
                                </Box>
                                </Box>
                            </Box>

                            <Box className="attemptedCard" sx={{mt:2}}>
                                <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center'}}}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4431_11605)">
                                        <path d="M23.8872 29.3995V26.6439C23.8872 25.1823 23.3066 23.7805 22.2731 22.747C21.2395 21.7134 19.8377 21.1328 18.3761 21.1328H7.35388C5.89225 21.1328 4.49047 21.7134 3.45694 22.747C2.42341 23.7805 1.84277 25.1823 1.84277 26.6439V29.3995" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.8656 15.6238C15.9093 15.6238 18.3767 13.1564 18.3767 10.1127C18.3767 7.06897 15.9093 4.60156 12.8656 4.60156C9.8219 4.60156 7.35449 7.06897 7.35449 10.1127C7.35449 13.1564 9.8219 15.6238 12.8656 15.6238Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M32.1538 29.4001V26.6445C32.1529 25.4234 31.7465 24.2372 30.9984 23.2721C30.2503 22.3071 29.2028 21.6178 28.0205 21.3125" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.5107 4.77344C23.6962 5.07696 24.7469 5.7664 25.4973 6.73306C26.2476 7.69973 26.6549 8.88862 26.6549 10.1123C26.6549 11.336 26.2476 12.5249 25.4973 13.4916C24.7469 14.4582 23.6962 15.1477 22.5107 15.4512" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_4431_11605">
                                        <rect width="33.0667" height="33.0667" fill="white" transform="translate(0.46582 0.464844)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography>25 k + Attempted</Typography>
                                    </Box> 
                                </Box>
                                <Box className="contentBox" sx={{mb:1}}>
                                    <Typography variant='h3'>
                                        Biology test 1
                                    </Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>30 Questions  </li>
                                            <li>90 Mins</li>
                                            <li>65 marks</li>
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="scoreBar midYellow">
                                <Box className="cardTop" sx={{display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                    <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.6667 7.33203H3.33333C2.59695 7.33203 2 7.92898 2 8.66536V13.332C2 14.0684 2.59695 14.6654 3.33333 14.6654H12.6667C13.403 14.6654 14 14.0684 14 13.332V8.66536C14 7.92898 13.403 7.33203 12.6667 7.33203Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4.66602 7.33203V4.66536C4.66602 3.78131 5.01721 2.93346 5.64233 2.30834C6.26745 1.68322 7.11529 1.33203 7.99935 1.33203C8.8834 1.33203 9.73125 1.68322 10.3564 2.30834C10.9815 2.93346 11.3327 3.78131 11.3327 4.66536V7.33203" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                        <Typography>Attempt </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="cardLeft" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                        <Typography>Syllabus</Typography>
                                        <Typography variant='div'>
                                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Typography>
                                    </Box>
                                     
                                </Box>
                                </Box>
                            </Box> */}

                            {/* <Box className="attemptedCard" sx={{mt:2}}>
                                <Box className="cardTop" sx={{mb:2, display: {xs: 'flex', alignItems: 'center'}}}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4431_11605)">
                                        <path d="M23.8872 29.3995V26.6439C23.8872 25.1823 23.3066 23.7805 22.2731 22.747C21.2395 21.7134 19.8377 21.1328 18.3761 21.1328H7.35388C5.89225 21.1328 4.49047 21.7134 3.45694 22.747C2.42341 23.7805 1.84277 25.1823 1.84277 26.6439V29.3995" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.8656 15.6238C15.9093 15.6238 18.3767 13.1564 18.3767 10.1127C18.3767 7.06897 15.9093 4.60156 12.8656 4.60156C9.8219 4.60156 7.35449 7.06897 7.35449 10.1127C7.35449 13.1564 9.8219 15.6238 12.8656 15.6238Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M32.1538 29.4001V26.6445C32.1529 25.4234 31.7465 24.2372 30.9984 23.2721C30.2503 22.3071 29.2028 21.6178 28.0205 21.3125" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.5107 4.77344C23.6962 5.07696 24.7469 5.7664 25.4973 6.73306C26.2476 7.69973 26.6549 8.88862 26.6549 10.1123C26.6549 11.336 26.2476 12.5249 25.4973 13.4916C24.7469 14.4582 23.6962 15.1477 22.5107 15.4512" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_4431_11605">
                                        <rect width="33.0667" height="33.0667" fill="white" transform="translate(0.46582 0.464844)"/>
                                        </clipPath>
                                        </defs>
                                        </svg>

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography>25 k + Attempted</Typography>
                                    </Box> 
                                </Box>
                                <Box className="contentBox" sx={{mb:1}}>
                                    <Typography variant='h3'>
                                        Biology test 1
                                    </Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>30 Questions  </li>
                                            <li>90 Mins</li>
                                            <li>65 marks</li>
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="scoreBar midYellow">
                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                        <Box className="cardRight" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                            <Box className="cardIcon">
                                                <Typography variant='div'>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.6667 7.33203H3.33333C2.59695 7.33203 2 7.92898 2 8.66536V13.332C2 14.0684 2.59695 14.6654 3.33333 14.6654H12.6667C13.403 14.6654 14 14.0684 14 13.332V8.66536C14 7.92898 13.403 7.33203 12.6667 7.33203Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M4.66602 7.33203V4.66536C4.66602 3.78131 5.01721 2.93346 5.64233 2.30834C6.26745 1.68322 7.11529 1.33203 7.99935 1.33203C8.8834 1.33203 9.73125 1.68322 10.3564 2.30834C10.9815 2.93346 11.3327 3.78131 11.3327 4.66536V7.33203" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                </Typography>
                                            </Box>
                                            <Box className="cardContent">
                                                <Typography>Attempt </Typography>
                                            </Box>
                                        </Box>

                                        <Box className="cardLeft" sx={{display: {xs: 'flex', alignItems: 'center'}}}>
                                            <Typography>Syllabus</Typography>
                                            <Typography variant='div'>
                                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 13L7 7L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </Typography>
                                        </Box>
                                        
                                    </Box>
                                </Box>
                            </Box> */}

                            <Box className="createTeachers">
                            <Box className="chaptersBox" sx={{mt:2, display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Box className="chapterBoxIn lightYellow" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                   
                                    <Typography  variant='h3'>
                                    created by expert teachers <Typography variant='div'> with 15+years of experience</Typography>
                                    </Typography>
                                    <Box className="iconsBox">
                                        <img className='engIcon' src={iconsBox1}></img>
                                    </Box>
                                    <Box className="iconsBox">
                                        <img className='ArIcon' src={iconsBox3}></img>
                                    </Box>
                                </Box>
                                <Box className="chapterBoxIn lightYellow" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                   
                                    <Typography  variant='h3'>
                                    created by expert teachers <Typography variant='div'> with 15+years of experience</Typography>
                                    </Typography>
                                    <Box className="iconsBox">
                                    <img className='engIcon'  src={iconsBox2}></img>
                                    </Box>
                                    <Box className="iconsBox">
                                    <img className='ArIcon' src={iconsBox4}></img>
                                    </Box>
                                </Box>
                                </Box>
                            </Box>

                        </Box>
                       
                    </Box>
                </Box>
            </Box>
        </Box>

    <FooterSection/>
    </div>
  )
}

export default MockTest