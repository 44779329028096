import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

export const getTranslationsSuccess = (data) => {
  return {
    type: actionTypes.GET_TRANSLATIONS,
    payload: {
      translations: data.Data,
    },
  };
};

export const getTranslations = () => async (dispatch) => {
    const data = { deviceType: 1, };
    return await apiCall
      .axiosPost(`${API_BASE_URL}/GetLocalizationsString`, data)
      .then((response) => {
        if (response) {
          dispatch(getTranslationsSuccess(response.data));
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
};