import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";


const submitFeedBackSuccess = (data) => {
  return {
    type: actionTypes.SUBMIT_FEEDBACK,
    payload: {
      feedback: data.Data,
    },
  };
};

export const submitFeedBack = (feedBack) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/SaveSessionRating`, feedBack)
    .then((response) => {
      if (response) {
        dispatch(submitFeedBackSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};