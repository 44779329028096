import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../actions/modalAction";


const ErrorModal = () => {
	const dispatch = useDispatch();
	const errorMessage = useSelector(({ modalReducer }) => modalReducer.errorMessage);

	return (
		<div id="myModal" className="error-modal">
			<div className="error-modal-content">
				<span className="error-modal-close" onClick={() => dispatch(closeModal())}>
					&times;
				</span>
				<div className="cis-modal-wrap">
					<ErrorOutlineIcon color="error" />
					{ errorMessage?.includes('https://onelink.to/39wfhz') ? 
						<div>
						<img className="d-block"  src="../../images/onelink.svg" alt="Not Found" style={{margin: '10px auto'}} />
						<a href="https://onelink.to/39wfhz" target="_blank">{errorMessage?.replace('https://onelink.to/39wfhz', '')}</a> 
						</div> : <p>{errorMessage}</p>
					}
				</div>
			</div>
		</div>
	);
};

export default ErrorModal;