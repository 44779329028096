import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getClassesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLASSES_LIST:
      return {
        classes: action.payload.classes,
      };

    default:
      return state;
  }
};
export default getClassesListReducer;
