import React, { useEffect } from "react";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";
import Cookies from 'js-cookie';


const LaunchHireClassRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const sessionId = sessionStorage.getItem('SessionID');
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `privateroom${sessionId}`;
  const roomUuid = `privateroomuuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const sessionType = 2;
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const pathName = sessionStorage.getItem("pathName"); 

  useEffect(() => {
    localStorage.setItem("SessionType", "PT");
    localStorage.setItem("CallType", 2);
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, sessionType, pathName, agoraCallDuration, agoraSessionEnd };
    test(params);

  }, []);
  return;
};
export default LaunchHireClassRoom;
