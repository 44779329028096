import { API_BASE_URL } from "../../config/apiConfig";
import { axiosPost } from "../../services/api";

function convertCallDurationToSeconds(callDuration) {
  // Split the time string into hours, minutes, and seconds
  let parts = callDuration.split(' : ');

  // Convert each part to an integer
  let hours = parseInt(parts[0], 10);
  let minutes = parseInt(parts[1], 10);
  let seconds = parseInt(parts[2], 10);
  
  // Calculate the total number of seconds
  let totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
  
  return totalSeconds;
}

export const agoraSessionEnd = async (sessionType, sessionId, pathName) => {
  const callDuration = localStorage.getItem('CallDuration');
  const callDurationSeconds = convertCallDurationToSeconds(callDuration);
  const url = `${API_BASE_URL}/AgoraSessionEnd`;
  const data = {
    SessionType: sessionType === 1 ? "QC" : sessionType === 2 ? "PT" : "PC",
    CallDuration: callDurationSeconds.toString(),
    SessionID: sessionId,  
  }
  sessionStorage.setItem("StudentSessionEnd", true);
  try {
    const res = await axiosPost(url, data);
    setTimeout(() => {
      window.location.pathname = pathName;
    }, 100)
  } catch (e) {
    setTimeout(() => {
      window.location.pathname = pathName;
    }, 100)
  }
}