
import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getClasses } from "../actions/classesAction";


const useClasses = () => {
  const dispatch = useDispatch();
  const classes  = useSelector(({ classReducer }) => classReducer.classes);

  const getClass = useCallback(
    (sessionId) => {
      return classes?.find((cls) => cls.ID.toString() === sessionId.toString()) || {};
    },
    [classes]
  );

  useEffect(() => {
    dispatch(getClasses());
  }, []);

  return { classes, getClass };
};

export default useClasses;
