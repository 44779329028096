import { createContext, useContext, useEffect, useState } from "react";
import {
	signInWithPopup,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	GoogleAuthProvider,
	OAuthProvider,
	signOut,
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
	const [user, setUser] = useState({});

	function logIn(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}

	function logOut() {
		return signOut(auth);
	}
	function googleSignIn() {
		const googleAuthProvider = new GoogleAuthProvider();
		googleAuthProvider.setCustomParameters({
			prompt: 'select_account',  // Force the account selection prompt
		});
		return signInWithPopup(auth, googleAuthProvider);
	}

    function appleSignIn() {
		// const provider = new OAuthProvider('apple.com');
		// return signInWithPopup(auth, provider);

		const provider = new OAuthProvider('apple.com');
		provider.addScope('email');
		provider.addScope('name');
		return signInWithPopup(auth, provider);

	}

	async function microsoftSignIn () {
		const provider = new OAuthProvider('microsoft.com').setCustomParameters({
			tenant: '43322af0-1be6-4d2c-a239-70ec97bffcee',  // Put Tenant Id from Azure registered app,
			prompt: 'consent' // Get Consent from user to access their basic info (optional - Reommended only during SignUp)
		});
		return signInWithPopup(auth, provider);
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
			setUser(currentuser);
		});

		return () => {
			unsubscribe();
		};
	}, []);

	return (
		<userAuthContext.Provider
			value={{
				user,
				logIn,
				logOut,
                appleSignIn,
				googleSignIn,
				microsoftSignIn,
			}}
		>
			{children}
		</userAuthContext.Provider>
	);
}

export function useUserAuth() {
	return useContext(userAuthContext);
}
