import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const getStudentDashBoardSuccess = (data) => {
  const noDashboard = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_STUDENT_DASHBOARD,
    payload: {
      dashboard: noDashboard ? {} : data.Data,
    },
  };
};

export const getStudentDashBoard = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetStudentDashboard`,)
    .then((response) => {
      if (response) {
        dispatch(getStudentDashBoardSuccess(response?.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};