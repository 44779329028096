import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import studyBanner from "../../assets/images/study-material-img.png";
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import "./Test.css";
import "../responsive/Responsive.css";
import "../subject/Subject.css";
import notebook from "../../assets/images/practiceNotebook.svg";
import yellowNotebook from "../../assets/images/yellowNoteNook.svg";
import penShortNote from "../../assets/images/penshortNote.svg";
import formulaSheet from "../../assets/images/formulaSheetpen.svg";
import biology from "../../assets/images/biology.svg";
import chemistry from "../../assets/images/chemistry.svg";
import physics from "../../assets/images/physics.svg";
import mathematics from "../../assets/images/mathematics.svg";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { useDispatch } from "react-redux";
import useTranslations from "../../custom-hooks/useTranslations";
import useStudentDashboard from "../../custom-hooks/useStudentDashboard";
import useStudyMaterial from "../../custom-hooks/useStudyMaterial";
import { getQuestionbyChapter } from "../../actions/getQuestionByChapter";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 16,
    backgroundColor: theme.palette.mode === "light" ? "#005DB8" : "#308fe8",
  },
}));

const Test = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translations = useTranslations();
  const { studentDashboard } = useStudentDashboard();
  const { studyMaterial, getAttemptedChapters } = useStudyMaterial();
  const attemptedChapters = getAttemptedChapters();

  const translation = {
    ...translations[26]?.QuickCall,
    ...translations[36]?.PracticeTest,
  };

  const handlePracticeTest = () => {
    navigate('/practice-test')
  }

  const handleMockTest = () => {
    navigate('/mock-test')
  }

  const handleNotes = (note) => {
    navigate(`/notes/${btoa(note?.ID)}`)
  }

  const handleSubjects = (subject) => {
    navigate(`/chapter/${btoa(subject?.ID)}`)
  }

  const handleAttemptedChapters = (chapter) => {
    dispatch(getQuestionbyChapter({ TopicID: chapter.SubjectID, ChapterID: chapter?.ID }))
    navigate(`/practice-test/${btoa(chapter.SubjectID)}/${btoa(chapter.ID)}`)
  }

  return (
    <>
      <Header />
      <HeroSection />
      <Box className="OuterContainer practiceTestContainer">
        <Box className="studyContainer" sx={{ background: "#F9FCFF" }}>
          <Box
            className="studyBanner"
            sx={{
              display: {
                xs: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              },
            }}
          >
            <Box className="contentBox">
              <Typography variant="h3" sx={{ mb: 1 }}>
                {translation?.StudyMaterial}
              </Typography>
              <Typography>
                {translation?.PracticeMaterialUnderstanding}
              </Typography>
            </Box>
            <Box className="imageBox">
              <img src={studyBanner}></img>
            </Box>
          </Box>
          <Box className="practiceBox">
            <Box className="mptFlex" sx={{ marginBottom: "24px" }}>
              <Box className="mptBox" onClick={handlePracticeTest}>
                <Box className="mptBoxWrap" sx={{ cursor: 'pointer'}}>
                  <img src={notebook} className="notebook" />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#000000D9",
                      fontWeight: "600",
                      lineHeight: "1.2",
                      textTransform: "capitalize",
                    }}
                  >
                    {translation?.PracticeWithQuestionBank}
                  </Typography>
                </Box>
              </Box>
              <Box className="mptBox" onClick={handleMockTest}>
                <Box className="mptBoxWrap" sx={{ cursor: 'pointer'}}>
                  <img src={yellowNotebook} alt="" className="notebook" />
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000000D9",
                        fontWeight: "600",
                        lineHeight: "1.2",
                        textTransform: "capitalize",
                      }}
                    >
                      {translation?.MockTest}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#555555",
                        textTransform: "uppercase",
                      }}
                    >
                      {translation?.BestWayToJudge}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="snFlex">
              {studentDashboard?.Documents?.map((item) => {
                return (
                  <Box className="snfBox" sx={{cursor: 'pointer'}} onClick={() => handleNotes(item)} key={item?.ID}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: "#000000",
                      }}
                    >
                      {item?.Value}
                    </Typography>
                    <img
                      src={item?.IconURL}
                      alt="notes"
                      style={{ width: "25px" }}
                    />
                  </Box>
                );
              })}

              {/* <Box className="snfBox">
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', textTransform: 'capitalize', color: '#000000' }}>short notes</Typography>
                                <img src={penShortNote} alt='' />
                            </Box>
                            <Box className="snfBox">
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', textTransform: 'capitalize', color: '#000000' }}>formula sheets</Typography>
                                <img src={formulaSheet} alt='' />
                            </Box>
                            <Box className="snfBox">
                                <Typography sx={{ fontSize: '12px', fontWeight: '500', textTransform: 'capitalize', color: '#000000' }}>formula sheets</Typography>
                                <img src={formulaSheet} alt='' />
                            </Box> */}
            </Box>
            <Box className="subjectFlex">
              {studyMaterial?.map((subject) => {
                return (
                  <Box className="subjectflBox" sx={{ cursor: 'pointer' }} key={subject.ID} onClick={() => handleSubjects(subject)}>
                    <Box className="cirBox">
                      <img src={subject?.SubjectImageURL} alt="" />
                    </Box>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000000",
                        textTransform: "capitalize",
                      }}
                    >
                      {subject?.SubjectName}
                    </Typography>
                  </Box>
                );
              })}

              {/* <Box className="subjectflBox">
                                <Box className="cirBox">
                                <img src={chemistry} alt='' />
                                </Box>
                                <Typography variant="p" sx={{ fontSize: "18px", fontWeight: "600", color: "#000000", textTransform: 'capitalize' }}>chemistry</Typography>
                            </Box>
                            <Box className="subjectflBox">
                                <Box className="cirBox">
                                <img src={physics} alt='' />
                                </Box>
                                <Typography variant="p" sx={{ fontSize: "18px", fontWeight: "600", color: "#000000", textTransform: 'capitalize' }}>physics</Typography>
                            </Box>
                            <Box className="subjectflBox">
                                <Box className="cirBox">
                                <img src={mathematics} alt='' />
                                </Box>
                                <Typography variant="p" sx={{ fontSize: "18px", fontWeight: "600", color: "#000000", textTransform: 'capitalize' }}>mathematics</Typography>
                            </Box> */}
            </Box>
            <Box className="bigSubjectCard">
                { attemptedChapters?.map((chapter, index) => {
                    return <Box className="bigSubjecBox active" sx={{ cursor: 'pointer' }} key={chapter.ID} onClick={() => handleAttemptedChapters(chapter)}>
                        <Box className="dotSubjectBox"></Box>
                        <Box className="bigSubjectBoxHead">
                        <Box className="bigCircular">
                            {/* <img src={physics} alt="" /> */}
                            <Typography  variant="h6">{index + 1}</Typography>
                        </Box>
                        <Typography
                            variant="h4"
                            sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#000000",
                            textTransform: "capitalize",
                            }}
                        >
                            {chapter.ChapterName}
                        </Typography>
                        </Box>
                        <Box className="chapProgresWrap">
                        <Box className="chapProgress">
                            <Typography
                            variant="h4"
                            sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: "#000000",
                                textTransform: "capitalize",
                                marginBottom: "12px",
                            }}
                            >
                            {translation?.ChapterProgress}
                            </Typography>
                        </Box>
                        <BorderLinearProgress variant="determinate" value={(chapter?.QuestionAttempted/chapter?.NumberOfQuestion) * 100} />
                        <Box className="chapProStart">
                            <Box className="chapDot"></Box>
                            <Typography
                            variant="h4"
                            sx={{
                                fontSize: "18px",
                                fontWeight: "400",
                                color: "#000000",
                                textTransform: "capitalize",
                            }}
                            >
                            {chapter?.NumberOfQuestion - chapter?.QuestionAttempted}/{chapter?.NumberOfQuestion} {translation?.PracticeNotStarted}
                            </Typography>
                        </Box>
                        </Box>
                    </Box>
                }) }

              {/* <Box className="bigSubjecBox">
                <Box className="dotSubjectBox active"></Box>
                <Box className="dotSubjectBox"></Box>
                <Box className="bigSubjectBoxHead">
                  <Box className="bigCircular">
                    <img src={biology} alt="" />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    biology
                  </Typography>
                </Box>
                <Box className="chapProgresWrap">
                  <Box className="chapProgress">
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000000",
                        textTransform: "capitalize",
                        marginBottom: "12px",
                      }}
                    >
                      {translation?.ChapterProgress}
                    </Typography>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={50} />
                  <Box class="chapProStart">
                    <Box className="chapDot"></Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "#000000",
                        textTransform: "capitalize",
                      }}
                    >
                      10/20 {translation?.PracticeNotStarted}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="bigSubjecBox active">
                <Box className="dotSubjectBox"></Box>
                <Box className="bigSubjectBoxHead">
                  <Box className="bigCircular">
                    <img src={physics} alt="" />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    physics
                  </Typography>
                </Box>
                <Box className="chapProgresWrap">
                  <Box className="chapProgress">
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000000",
                        textTransform: "capitalize",
                        marginBottom: "12px",
                      }}
                    >
                      chapter Progress
                    </Typography>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={50} />
                  <Box class="chapProStart">
                    <Box className="chapDot"></Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "#000000",
                        textTransform: "capitalize",
                      }}
                    >
                      10/20 not started
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <FooterSection />
    </>
  );
};

export default Test;
