import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/education.png';
import ShareWithFriend from '../common-components/ShareWithFriend';

const Solution = () => {
  return (
    <div>
     <Header/>
     <HeroSection/> 
        <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                        <Button className='backBtn' variant="text" sx={{mb:1, paddingLeft:0}}  startIcon={<ArrowBackIcon />}> Back </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardContent">
                                    <Typography variant='h4'>solutions</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />
                        {/* <Box className="shareBox" sx={{mt:3, mb:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                            <Typography variant='div'>
                                <img src={shareImg}></img>
                            </Typography>
                            <Typography>
                                share with your friends
                            </Typography>
                            </Box>
                            <Box>
                            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.6148 25.0346H2.06429e-05V11.9639C-0.00237698 11.071 0.204115 10.19 0.602995 9.39111C1.14456 8.19601 2.01876 7.18211 3.12113 6.47054C4.22351 5.75897 5.50744 5.37984 6.81952 5.37844H13.667V0.96582H15.7911L24 9.17479L15.7619 17.4129H13.667V13.0002H9.21362C8.2821 13.0025 7.37673 13.3084 6.63479 13.8717C5.89284 14.4349 5.35479 15.2247 5.10221 16.1214L2.6148 25.0346ZM6.81952 6.98303C5.8138 6.9841 4.82976 7.27537 3.98547 7.82187C3.14119 8.36836 2.47254 9.14688 2.05976 10.064L2.04512 10.0948C1.75362 10.6746 1.60271 11.3149 1.60461 11.9639V22.6851L3.55669 15.6901C3.90418 14.4564 4.64449 13.3697 5.66535 12.5947C6.6862 11.8197 7.93193 11.3987 9.21362 11.3956H15.2715V15.634L21.7308 9.17479L15.2715 2.71552V6.98303H6.81952Z" fill="#4D4D4D"/>
                            </svg>
                            </Box>
                        </Box> */}
                        
                        <Box className="solutionBanner light-gray" sx={{mt:3, mb:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box className="contentBox">
                                <Typography variant='h3' sx={{margin:0}} >
                                NCERT SOLUTIONS
                                </Typography>
                            </Box>
                            <Box className="imageBox">
                                <img src={cardIcon}></img>
                            </Box>
                        </Box>

                        <Box className="SolutionBox" sx={{ mt:3, padding:'0px 0px'}}>
                        <Box className="cardMain"sx={{ mt:1}}>
                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        1
                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography variant='h4'>exercise</Typography>
                                    </Box>
                                </Box>
                                <Box className="cardLeft">
                                    <Button variant="contained" className='lightGreen'>start practice</Button>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="cardMain"sx={{ mt:2}}>
                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        2

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography variant='h4'>additional Exercise</Typography>
                                    </Box>
                                    
                                </Box>
                                <Box className="cardLeft">
                                    <Button variant="contained" className='lightGreen'>start practice</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="cardMain"sx={{ mt:2}}>
                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                    <Box className="cardIcon">
                                        <Typography variant='div'>
                                        3

                                        </Typography>
                                    </Box>
                                    <Box className="cardContent">
                                        <Typography variant='h4'>Example</Typography>
                                    </Box>
                                    
                                </Box>
                                <Box className="cardLeft">
                                    <Button variant="contained" className='lightGreen'>start practice</Button>
                                </Box>
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
     <FooterSection/>
    </div>
  )
}

export default Solution
