import * as actionTypes from "../actions/actionTypes";

const initialState = {feedback: []};

const feedBackOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FEED_BACK_OPTION:
      return {
        ...state,
        feedback: [action.payload.feedback],
      };

    default:
      return state;
  }
};
export default feedBackOptionReducer;
