import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTutorDurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_DURATION:
      return {
        tutor: action.payload.tutor,
      };

    default:
      return state;
  }
};
export default getTutorDurationReducer;
