import moment from 'moment';
import 'moment/locale/ar'; // Import Arabic locale

const momentDate = (date) => {
  // Convert dateString to a moment object
  const d = moment(date);

  // Change locale to English / Arabic
  d.locale(localStorage.getItem("I18N_LANGUAGE"));

  // Format the date
  const formattedDate = d.format('LL');

  return date && formattedDate;
};

export default momentDate;
