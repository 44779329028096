import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';


const ProtectedRoute = ({ children }) => {
  const loginToken = Cookies.get("loginToken");
  const isActive = JSON.parse(sessionStorage.getItem('IsActive'));
  let location = useLocation();

  if (!loginToken || isActive === false) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
