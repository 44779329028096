import * as actionTypes from "../actions/actionTypes";

const initialState = { 
  isOpenModal: false, errorMessage: "", 
  isMessage: false, message: "",
  isConfirm: false, confirmMessage: ""
 };

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        openModal: action.payload.openModal,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        openModal: action.payload.openModal,
      };
    case actionTypes.OPEN_MESSAGE_MODAL:
      return {
        isMessage: action.payload.isMessage,
        message: action.payload.message,
      };
    case actionTypes.CLOSE_MESSAGE_MODAL:
      return {
        isMessage: action.payload.isMessage,
      };
    case actionTypes.CONFIRM_MESSAGE_MODAL:
      return {
        isConfirm: action.payload.isConfirm,
        confirmMessage: action.payload.confirmMessage,
      };
    case actionTypes.CLOSE_CONFIRM_MESSAGE_MODAL:
      return {
        isConfirm: action.payload.isConfirm,
      };

    default:
      return state;
  }
};
export default modalReducer;
