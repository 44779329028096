import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getMockTestQuestionsSuccess } from "../actions/getMockTestQuestions";

const useMockTestQuestions = (mockTestId) => {
  const dispatch = useDispatch();
  
  const mockTestQuestions = useSelector(({ getMockTestQuestionsReducer }) => getMockTestQuestionsReducer.mockTestQuestions) || {};
  const isMockTestStart = JSON.parse(sessionStorage.getItem('MockTestStart'));

  const getMockTestQuestions = useCallback(() => {
    if (Object.keys(mockTestQuestions).length === 0 && mockTestId) { 
      const data = { MockTestID: mockTestId };
      axiosPost(`${API_BASE_URL}/GetMockTestQuestions`, data)
        .then((res) => {
          dispatch(getMockTestQuestionsSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mockTestQuestions, mockTestId]);

  const getQuestionsByChapter = useCallback(
    (chapterId) => {
      return mockTestQuestions?.Questions?.filter((question) => question.ChapterID.toString() === chapterId.toString())
    },
    [mockTestQuestions]
  );

  const groupQuestionsByChapter = useCallback(
    () => {
      const chapterObj = {};
      mockTestQuestions?.Questions?.forEach(question => {
        const chapterId = question.ChapterID;
        if (!chapterObj[chapterId]) {
          chapterObj[chapterId] = [];
        }
        chapterObj[chapterId].push(question);
      });
      return Object.keys(chapterObj).map(chapterId => {

        const chapterResult = chapterObj[chapterId]?.reduce((acc, current) => {
          if (current.AnswerID === current.LastSelectedAnswerID) {
            acc.Correct = acc.Correct + 1;
            acc.Scored = acc.Scored + current.Mark;
          } else {
            acc.Incorrect = acc.Incorrect + 1;
          }

          if (current.AttemptedStatus) {
            acc.AttemptedQuestion = acc.AttemptedQuestion + 1;
          } else {
            acc.NotAttemptedQuestion = acc.NotAttemptedQuestion + 1;
          }

          return acc;
        }, { Scored: 0, Correct: 0, Incorrect: 0, AttemptedQuestion: 0,  NotAttemptedQuestion: 0, })

        return {
          ChapterID: chapterId,
          ChapterName: chapterObj[chapterId][0]?.ChapterName,
          Questions: chapterObj[chapterId],
          NoOfQuestions: chapterObj[chapterId]?.length,
          Scored: chapterResult.Scored, 
          Correct: chapterResult.Correct, 
          Incorrect: chapterResult.Incorrect, 
          AttemptedQuestion: chapterResult.AttemptedQuestion, 
          AttemptedStatus: chapterResult.AttemptedQuestion > 0,
          NotAttemptedQuestion: chapterResult.NotAttemptedQuestion, 
        }
      })
    },
    [mockTestQuestions]
  );

  const getQuestionsByMark = useCallback(
    (mark) => {
      return mockTestQuestions?.Questions?.filter((question) => question.Mark.toString() === mark.toString())
    },
    [mockTestQuestions]
  );

  // const groupQuestionsByMark = useCallback(
  //   () => {
  //     const markObj = {};
  //     mockTestQuestions?.Questions?.forEach(question => {
  //       const mark = question.Mark;
  //       if (!markObj[mark]) {
  //         markObj[mark] = [];
  //       }
  //       markObj[mark].push(question);
  //     });
  //     return Object.keys(markObj).map(mark => {

  //       const markResult = markObj[mark]?.reduce((acc, current) => {
  //         if (current.AttemptedStatus) {
  //           if (current.AnswerID === current.LastSelectedAnswerID) {
  //             acc.Correct = acc.Correct + 1;
  //             acc.Scored = acc.Scored + current.Mark;
  //           } else {
  //             acc.Incorrect = acc.Incorrect + 1;
  //           }
  //         }

  //         if (current.AttemptedStatus) {
  //           acc.AttemptedQuestion = acc.AttemptedQuestion + 1;
  //         } else {
  //           acc.NotAttemptedQuestion = acc.NotAttemptedQuestion + 1;
  //         }

  //         return acc;
  //       }, { Scored: 0, Correct: 0, Incorrect: 0, AttemptedQuestion: 0,  NotAttemptedQuestion: 0, })


  //       return {
  //         Mark: mark,
  //         Questions: markObj[mark],
  //         NoOfQuestions: markObj[mark]?.length,
  //         TotalMark: mark * markObj[mark]?.length,
  //         Scored: markResult.Scored, 
  //         Correct: markResult.Correct, 
  //         Incorrect: markResult.Incorrect, 
  //         NotAttemptedQuestion: markResult.NotAttemptedQuestion, 
  //         AttemptedQuestion: markResult.AttemptedQuestion, 
  //         AttemptedStatus: markResult.AttemptedQuestion > 0,
  //       }
  //     });
  //     },
  //   [mockTestQuestions]
  // );

  const groupQuestionsByMark = useCallback(() => {
    const markObj = {};
  
    mockTestQuestions?.Questions?.forEach(question => {
      const mark = question.Mark;
      if (!markObj[mark]) {
        markObj[mark] = { Questions: [], Scored: 0, Correct: 0, Incorrect: 0, AttemptedQuestion: 0, NotAttemptedQuestion: 0 };
      }
  
      markObj[mark].Questions.push(question);
  
      if (question.AttemptedStatus) {
        markObj[mark].AttemptedQuestion++;
        if (question.AnswerID === question.LastSelectedAnswerID) {
          markObj[mark].Correct++;
          markObj[mark].Scored += question.Mark;
        } else {
          markObj[mark].Incorrect++;
        }
      } else {
        markObj[mark].NotAttemptedQuestion++;
      }
    });
  
    return Object.keys(markObj).map(mark => {
      const { Questions, Scored, Correct, Incorrect, AttemptedQuestion, NotAttemptedQuestion } = markObj[mark];
      const totalQuestions = Questions.length;
  
      return {
        Mark: mark,
        Questions,
        NoOfQuestions: totalQuestions,
        TotalMark: mark * totalQuestions,
        Scored,
        Correct,
        Incorrect,
        NotAttemptedQuestion,
        AttemptedQuestion,
        AttemptedStatus: AttemptedQuestion > 0,
      };
    });
  }, [mockTestQuestions]);
  

  const updateQuestionStatus = useCallback(
    (questionId, answerId) => {
      const updatedAttemptedQuestion = JSON.parse(JSON.stringify(mockTestQuestions?.Questions));
      const prevAnswerIndex = updatedAttemptedQuestion.findIndex((question) => question.ID === questionId);
    
      if (prevAnswerIndex !== -1) {
        updatedAttemptedQuestion[prevAnswerIndex] = { 
          ...updatedAttemptedQuestion[prevAnswerIndex], 
          LastSelectedAnswerID: answerId,
          AttemptedStatus: true,
          IsAttempted: true
        };
      } 
  
      sessionStorage.setItem('MockTestQuestions', JSON.stringify({ 
        Instructions: mockTestQuestions?.Instructions, 
        Questions: updatedAttemptedQuestion
      }));
      dispatch(
        getMockTestQuestionsSuccess({
          Data: {
            Instructions: mockTestQuestions?.Instructions,
            Questions: updatedAttemptedQuestion,
          },
        })
      );
    },
    [mockTestQuestions]
  );

  const updateAttemptedQuestions = useCallback(
    (selectQuestions) => {
      const selectQuestionsMap = new Map(selectQuestions?.map(sq => [sq.QuestionID?.toString(), sq.AnswerID]));
  
      const updatedQuestions = mockTestQuestions?.Questions?.map(question => {
        const questionID = question.ID?.toString();
        const answerID = questionID ? selectQuestionsMap.get(questionID) : null;
        if (answerID) {
          if (question.AnswerID == answerID) {
            return { ...question, IsAnswerChecked: true, IsCorrect: true };
          } else {
            return { ...question,  IsAnswerChecked: true, IsCorrect: false };
          }
        }
        return question;
      });
  
      sessionStorage.setItem('MockTestQuestions', JSON.stringify({ 
        Instructions: mockTestQuestions?.Instructions, 
        Questions: updatedQuestions
      }));
      dispatch(
        getMockTestQuestionsSuccess({
          Data: {
            Instructions: mockTestQuestions?.Instructions,
            Questions: updatedQuestions,
          },
        })
      );
    },
    [mockTestQuestions]
  );

  const updateQuestionsAttemptedStatus = useCallback(
    () => {
      const updatedQuestions = mockTestQuestions?.Questions?.map(question => {
        question.AttemptedStatus = false;
        return question;
      });
      
      sessionStorage.setItem('MockTestQuestions', JSON.stringify({ 
        Instructions: mockTestQuestions?.Instructions, 
        Questions: updatedQuestions
      }));
      dispatch(
        getMockTestQuestionsSuccess({
          Data: {
            Instructions: mockTestQuestions?.Instructions,
            Questions: updatedQuestions,
          },
        })
      );
    },
    [mockTestQuestions]
  );

  useEffect(() => {
    if (isMockTestStart) {
      dispatch(getMockTestQuestionsSuccess({
        Data: JSON.parse(sessionStorage.getItem('MockTestQuestions'))
      }));
    } else {
      getMockTestQuestions();
    }
  }, [isMockTestStart]);

  return { mockTestQuestions: mockTestQuestions?.Questions, Instructions: mockTestQuestions?.Instructions, 
    getQuestionsByChapter, groupQuestionsByChapter, getQuestionsByMark, groupQuestionsByMark, updateQuestionStatus, updateAttemptedQuestions, updateQuestionsAttemptedStatus };
};

export default useMockTestQuestions;
