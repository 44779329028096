module.exports = {
  SET_INITIAL_STATE: "SET_INITIAL_STATE",
  GET_CLASSES: "GET_CLASSES",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  USER_PHONE_NUMBER: "USER_PHONE_NUMBER",
  GET_PAYMENTS: "GET_PAYMENTS",
  POST_QUICK_CALL: "POST_QUICK_CALL",
  SUBMIT_FEEDBACK:"SUBMIT_FEEDBACK",
  GET_STUDENT_PROFILE: "GET_STUDENT_PROFILE",
  GET_STUDENT_DASHBOARD: "GET_STUDENT_DASHBOARD",
  GET_QUICK_CALLS_RECENT_SESSIONS: "GET_QUICK_CALLS_RECENT_SESSIONS",
  GET_TUTOR_INFORMATION: "GET_TUTOR_INFORMATION",
  GET_TOPICS: "GET_TOPICS",
  GET_TUTOR_DURATION: "GET_TUTOR_DURATION",
  GET_EXAM_BOARD: "GET_EXAM_BOARD",
  GET_CLASSES_LIST: "GET_CLASSES_LIST",
  FEED_BACK_OPTION: "FEED_BACK_OPTION",
  ACTIVE_TUTOR_LIST: "ACTIVE_TUTOR_LIST",
  GET_TARGET_EXAM_BOARD: "GET_TARGET_EXAM_BOARD",
  GET_TOPICS_LIST: "GET_TOPICS_LIST",
  POST_STUDENT_REGISTRATION: "POST_STUDENT_REGISTRATION",
  GET_APP_LANGUAGE: "GET_APP_LANGUAGE",
  GET_TEACHING_LANGUAGE: "GET_TEACHING_LANGUAGE",
  UPDATE_STUDENT_PROFILE: "UPDATE_STUDENT_PROFILE",
  ADD_TO_FAVT_TUTOR: "ADD_TO_FAVT_TUTOR",
  GET_FAVT_TUTOR_STATUS: "GET_FAVT_TUTOR_STATUS",
  GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
  TOGGLE_TUTOR_ONLINE_STATUS: "TOGGLE_TUTOR_ONLINE_STATUS",
  GET_TRANSLATIONS: "GET_TRANSLATIONS",
  GET_GENDER: "GET_GENDER",
  GET_HELPER_DATA: "GET_HELPER_DATA",
  GET_EXAMINATION_BOARDS: "GET_EXAMINATION_BOARDS",
  STUDENTS_SESSION_DETAILS: "STUDENTS_SESSION_DETAILS",
  PRIVATE_TUTOR_INFORMATION: "PRIVATE_TUTOR_INFORMATION",
  GET_STUDY_MATERIAL: "GET_STUDY_MATERIAL",
  GET_QUESTION_BY_CHAPTER: "GET_QUESTION_BY_CHAPTER",
  GET_MOCK_TEST_LIST: "GET_MOCK_TEST_LIST",
  GET_MOCK_TEST_QUESTIONS: "GET_MOCK_TEST_QUESTIONS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_REFERRAL_RECORD: "GET_REFERRAL_RECORD",
  CHECK_ANSWER: "CHECK_ANSWER",
  GET_STUDY_MATERIAL_DOCUMENT: "GET_STUDY_MATERIAL_DOCUMENT",
  PRIVATE_CLASSES_ASSIGNMENT: "PRIVATE_CLASSES_ASSIGNMENT",
  GET_PARTICIPANETS_LIST: "GET_PARTICIPANETS_LIST",
  GET_CHAT_BACKUP: "GET_CHAT_BACKUP",
  OPEN_MESSAGE_MODAL: "OPEN_MESSAGE_MODAL",
  CLOSE_MESSAGE_MODAL: "CLOSE_MESSAGE_MODAL",
  CONFIRM_MESSAGE_MODAL: "CONFIRM_MESSAGE_MODAL",
  CLOSE_CONFIRM_MESSAGE_MODAL: "CLOSE_CONFIRM_MESSAGE_MODAL"
};