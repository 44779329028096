import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const studentDashboardReducer = (state = initialState, action) => {
  switch (action.type) {

      case actionTypes.GET_STUDENT_DASHBOARD:
      return {
        dashboard: action.payload.dashboard,
      };

    default:
      return state;
  }
};
export default studentDashboardReducer;