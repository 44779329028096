import * as actionTypes from "../actions/actionTypes";

const initialState = {teachinglanguage: []};

const getTeachingLanguageReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.GET_TEACHING_LANGUAGE:
      return {
        ...state,
        teachinglanguage: [action?.payload?.teachinglanguage],
      };

    default:
      return state;
  }
};
export default getTeachingLanguageReducer;
