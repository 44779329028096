import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_STATE:
      return {
        user: action.payload.user,
      };

    case actionTypes.USER_PHONE_NUMBER:
      return {
        phoneNumber: action.payload.phoneNumber,
      };

    default:
      return state;
  }
};
export default loginReducer;
