import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getMockTestListSuccess } from "../actions/getMockTestList";

const useMockTest = (subjectId) => {
  const dispatch = useDispatch();
  const mockTestList = useSelector(({ getMockTestListReducer }) => getMockTestListReducer.mockTestList || []);

  const getMockTestList = useCallback(() => {
    if (mockTestList?.length === 0 && subjectId) {
      const data = { TopicID: subjectId };
      axiosPost(`${API_BASE_URL}/GetMockTestList`, data)
        .then((res) => {
          dispatch(getMockTestListSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [subjectId]);

  const getMockTest = useCallback(
    (mockTestId) => {
      return mockTestList?.find(test => test.ID.toString() === mockTestId?.toString()) || {};
    },
    [mockTestList]
  );

  const updateAttemptedCount = useCallback(
    (mockTestId) => {
      const updatedMockTest = mockTestList?.map((test) => {
        if (test.ID.toString() === mockTestId.toString()) {
          test.AttemptedCount = test.AttemptedCount + 1;
        }
        return test;
      })
      dispatch(getMockTestListSuccess({ Data: updatedMockTest }));

    },
    [mockTestList]
  );

  const updateScore = useCallback(
    (mockTestId, score) => {
      const updatedMockTest = mockTestList?.map((test) => {
        if (test.ID.toString() === mockTestId.toString()) {
          test.Scored = score;
        }
        return test;
      })
      dispatch(getMockTestListSuccess({ Data: updatedMockTest }));

    },
    [mockTestList]
  );


  useEffect(() => {
    getMockTestList();
  }, [getMockTestList]);

  return { mockTestList, getMockTest, updateAttemptedCount, updateScore };
};

export default useMockTest;
