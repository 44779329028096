import React, { useEffect } from "react";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";
import Cookies from 'js-cookie';


const LaunchQuickCallRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const questionId =  sessionStorage.getItem('QuestionID');
  const sessionId = questionId;
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `room${questionId}`;
  const roomUuid = `roomuuid${questionId}`;
  const channel = `channel${questionId}`;
  const roomType = 0;
  const sessionType = 1;
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const pathName = sessionStorage.getItem("pathName");


  useEffect(() => {
    localStorage.setItem("SessionType", "S");
    localStorage.setItem("CallType", 1);
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, sessionType, pathName, agoraCallDuration, agoraSessionEnd };
    test(params);
  }, []);
  return;
};
export default LaunchQuickCallRoom;
