import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const getHelperDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HELPER_DATA:
      return {
        helperData: action.payload.helperData,
      };

    default:
      return state;
  }
};
export default getHelperDataReducer;
