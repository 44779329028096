import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const privateClassesAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRIVATE_CLASSES_ASSIGNMENT:
      return {
        ...state,
        assignments: action.payload.assignments,
      };

    default:
      return state;
  }
};
export default privateClassesAssignmentReducer;
