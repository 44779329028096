import * as actionTypes from "../actions/actionTypes";

const initialState = {tutor: []};

const addToFavtTutorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_FAVT_TUTOR:
      return {
        ...state,
        tutor: [action.payload.tutor],
      };

    default:
      return state;
  }
};
export default addToFavtTutorReducer;
