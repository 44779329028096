import * as actionTypes from "../actions/actionTypes";

const initialState = {privateTutorInfo: {}};

const privateTutorInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRIVATE_TUTOR_INFORMATION:
      return {
        ...state,
        privateTutorInfo: action.payload.privateTutorInfo,
      };

    default:
      return state;
  }
};
export default privateTutorInformationReducer;
