import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const topicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TOPICS:
      return {
        topics: action.payload.topics,
      };

    default:
      return state;
  }
};
export default topicsReducer;
