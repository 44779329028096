import React from "react";
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import "./Login.css";


import white_logo from '../../assets/images/logo.png';
import clouds from '../../assets/images/cloud.png';
import clouds2 from '../../assets/images/mobile_cloud.png';
import mainSlider01 from '../../assets/images/main_slider_01.png';
import mainSlider02 from '../../assets/images/main_slider_02.png';
import mainSlider03 from '../../assets/images/main_slider_03.png';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { useTranslation } from "react-i18next";
import useTranslations from "../../custom-hooks/useTranslations";

const LoginSlider = () => {
  const { t } = useTranslation();
  const translations = useTranslations();
  const translation = translations[0]?.Login;

  return (
    <Box className="left_section">
      <img src={white_logo} alt="logo" />
      <img className="cloud_abs" src={clouds} alt="cloud" />
      <img className="cloud_abs2" src={clouds2} alt="cloud" />
      <Box className="main_slider" dir="rtl">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          slidesPerGroup={1}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          rtl={true}
          loop={true}
          //   loopFillGroupWithBlank={true}
          pagination={{ clickable: true }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          //   dots={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
          className="mySwiper swiper_login"
        >
          <SwiperSlide>
            <img src={mainSlider01} />
            <Box className="sliderBox" sx={{ textAlign: "center" }}>
              <Typography
                className="sliderBox_head"
                variant="h4"
                sx={{
                  fonrSize: "24px",
                  color: "#fff",
                  fontWeight: "600",
                  marginBottom: "8px",
                  TextTransform: "capitalize",
                }}
              >
                {translation?.AskAnyQuestion || t('AskAnyQuestion')}
              </Typography>
              <Typography
                className="sliderBox_content"
                variant="p"
                sx={{ fontSize: "16px", color: "#fff" }}
              >
                {translation?.MultipleTutor || t('MultipleTutor')}
              </Typography>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <img src={mainSlider02} />
            <Box className="sliderBox" sx={{ textAlign: "center" }}>
              <Typography
                className="sliderBox_head"
                variant="h4"
                sx={{
                  fonrSize: "24px",
                  color: "#fff",
                  fontWeight: "600",
                  marginBottom: "8px",
                  TextTransform: "capitalize",
                }}
              >
                {translation?.SelectYourSubject || t('SelectYourSubject')}
              </Typography>
              <Typography
                className="sliderBox_content"
                variant="p"
                sx={{ fontSize: "16px", color: "#fff" }}
              >
                {translation?.MultipleSession || t('MultipleSession')}
              </Typography>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <img src={mainSlider03} />
            <Box className="sliderBox" sx={{ textAlign: "center" }}>
              <Typography
                className="sliderBox_head"
                variant="h4"
                sx={{
                  fonrSize: "24px",
                  color: "#fff",
                  fontWeight: "600",
                  marginBottom: "8px",
                  TextTransform: "capitalize",
                }}
              >
                {translation?.JoinLiveSession || t('JoinLiveSession')}
              </Typography>
              <Typography
                className="sliderBox_content"
                variant="p"
                sx={{ fontSize: "16px", color: "#fff" }}
              >
                {translation?.LiveSession || t('LiveSession')}
              </Typography>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  );
};

export default LoginSlider;
