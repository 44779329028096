import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const getTranslationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSLATIONS:
      return {
        translations: action.payload.translations,
      };

    default:
      return state;
  }
};
export default getTranslationsReducer;