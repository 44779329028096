import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

export const sessionRejoined = async (payload) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/SessionRejoined`, payload)
    .then((response) => {
      console.log('response: ', response);
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};