import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "./api";

const generateAgoraToken = async (userUid, role, channelName) => {

    const data = {
        uid: userUid,
        RoleID: role,
        channelName: channelName,
    };

    const response = await axiosPost(`${API_BASE_URL}/GenerateAgoraToken`, data);
    return response.data.Data;
};

export default generateAgoraToken;