import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, LinearProgress, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

import studyBanner from '../../assets/images/study-material-img.png';

import { useDispatch } from "react-redux";
import useTranslations from "../../custom-hooks/useTranslations";
import useStudyMaterial from "../../custom-hooks/useStudyMaterial";
import { getMockTestList } from "../../actions/getMockTestList";


import Header from "../header/Header"
import HeroSection from "../hero-section/HeroSection"
import InnerContainer from "../inner-container/InnerContainer"
import FooterSection from "../footer/FooterSection"

import "./Subject.css"
import "../responsive/Responsive.css"

const Subject = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const translations = useTranslations();
  const { studyMaterial } = useStudyMaterial();
  const subPathName = window.location.pathname.split('/')[1];

  const translation = {
    ...translations[26]?.QuickCall,
    ...translations[36]?.PracticeTest
  }

  const handleBack = () => {
    navigate(-1);
  }

  const handleSubjectBox = (subject) => {
    if (subPathName !== 'mock-test') return;
    dispatch(getMockTestList({ TopicID: subject?.ID }))
    navigate(`/${subPathName}/${btoa(subject?.ID)}`)
  }

  const handleSubject = (subject) => {
    if (subPathName === 'practice-test') {
      navigate(`/${subPathName}/${btoa(subject?.ID)}`)
    } else if (subPathName === 'mock-test') {
      dispatch(getMockTestList({ TopicID: subject?.ID }))
      navigate(`/${subPathName}/${btoa(subject?.ID)}`)
    } else {
      navigate(`/chapter/${btoa(subject?.ID)}`)
    }
  }


  return (
    <>
      <Header/>
      <HeroSection/>
      {/* <InnerContainer/> */}

      <Box className='OuterContainer'>
        <Box className='studyContainer'>
          <Box className="studyBanner">
            <Button className='backBtn' variant="text" sx={{mb:2}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack} > {translation?.BackBtn} </Button>
            <Box sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' }, mt: -4}}>
              <Box className='contentBox'>
                <Typography variant='h3' sx={{mb:1}}>
                  {translation?.StudyMaterial}
                </Typography> 
                <Typography>
                  {translation?.PracticeMaterialUnderstanding}
                </Typography>
              </Box>
              <Box className='imageBox'>
                <img src={studyBanner}></img>
              </Box>
            </Box>
          </Box>

          <Box className="practiceBox">
            <Typography variant='h3' align='center' sx={{mt:2, mb:1 }}>
              {translation?.SelectTopicPractice}
            </Typography>
            <Box className='practiceBoxIn'>
              { studyMaterial?.map((subject) => {
                  return (
                    <Box 
                      className="cardMain" 
                      sx={{ mb:2, cursor: subPathName !== 'mock-test' ? 'unset' : 'pointer'}} 
                      onClick={() => handleSubjectBox(subject)} 
                      key={subject?.ID}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                            <Box className="cardIcon">
                              <img src={subject?.SubjectImageURL} alt="subject logo"></img>
                            </Box>
                            <Box className="cardContent">
                              <Typography variant='h4'>{subject?.SubjectName}</Typography>
                              <Box className="cardList">
                                { subPathName === 'mock-test' ?
                                  <ul>
                                    <li>{subject?.MockTestCount} {translation?.MockTest}</li>
                                    <li>{subject?.MockTestQuestionCount} {translation?.PracticeQuestion}</li>
                                  </ul> : 
                                  <ul>
                                    <li>{subject?.NumberOfChapter} {translation?.PracticeChapter}</li>
                                    <li>{subject?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                                  </ul>
                                }
                              </Box>
                            </Box>
                              
                          </Box>
                          { subPathName !== 'mock-test' &&
                            <Box className="cardLeft">
                              <Button variant="contained" onClick={() => handleSubject(subject)}>{translation?.ViewChapter}</Button>
                            </Box>
                          }
                      </Box>
                      { subPathName !== 'mock-test' && subject?.SubjectStatus === "Attempted" && subPathName !== 'mock-test' && 
                        <Box className="cardBottom">
                          <Typography variant='h5'>
                            {translation?.ChapterProgress}
                          </Typography>
                          <Box className="progressBar">
                            <LinearProgress variant="determinate" value={(subject?.NumberOfCompletedChapter/subject?.NumberOfChapter) * 100} sx={{height:5}} />
                          </Box>
                            
                          <Box className="progressNumber">
                            <span className='practicing'>{subject?.ChapterPractising}/{subject?.NumberOfChapter} {translation?.PracticeStarted}</span>
                            <span className='notStarted'>{subject?.ChapterNotStarted}/{subject?.NumberOfChapter} {translation?.PracticeNotStarted}</span>
                            <span className='understood'>{subject?.NumberOfCompletedChapter}/{subject?.NumberOfChapter} {translation?.PracticeCompleted} </span>
                          </Box>
                        </Box> }
                      </Box>
                      )
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      <FooterSection/>
    </>
  )
}

export default Subject