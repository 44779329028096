import { parseISO, startOfToday, startOfYesterday, startOfWeek, startOfMonth, startOfYear, endOfDay, endOfMonth, endOfWeek, endOfYear, isWithinInterval, subWeeks, subMonths, subYears } from 'date-fns';

export const dateFilter = (data, range) => {
  const today = startOfToday();
  const yesterday = startOfYesterday();
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
  const startOfCurrentMonth = startOfMonth(today);
  const startOfCurrentYear = startOfYear(today);

  let startDate, endDate;

  switch (range) {
    case '1':
      startDate = today;
      endDate = endOfDay(today);
      break;
    case '8':
      startDate = yesterday;
      endDate = endOfDay(yesterday);
      break;
    case '2':
      startDate = startOfCurrentWeek;
      endDate = endOfDay(today);
      break;
    case '3':
      startDate = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
      endDate = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
      break;
    case '4':
      startDate = startOfCurrentMonth;
      endDate = endOfDay(today);
      break;
    case '5':
      startDate = startOfMonth(subMonths(today, 1));
      endDate = endOfMonth(subMonths(today, 1));
      break;
    case '6':
      startDate = startOfCurrentYear;
      endDate = endOfDay(today);
      break;
    case '7':
      startDate = startOfYear(subYears(today, 1));
      endDate = endOfYear(subYears(today, 1));
      break;
    default:
      return data;
  }

  return data?.filter(item => {
    const date = parseISO(item?.DateTime); // Adjust according to your data structure
    return isWithinInterval(date, { start: startDate, end: endDate });
  });
};
