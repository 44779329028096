import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

export const getTransactionsSuccess = (data) => {
  return {
    type: actionTypes.GET_TRANSACTIONS,
    payload: {
      transactions: data.Data,
    },
  };
};

export const getTransactions = () => async (dispatch) => {
    return await apiCall
      .axiosGet(`${API_BASE_URL}/AllTransactions`)
      .then((response) => {
        if (response) {
          dispatch(getTransactionsSuccess(response.data));
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
};