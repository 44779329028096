import * as actionTypes from "../actions/actionTypes";

const initialState = {quickCall: []};

const quickCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_QUICK_CALL:
      return {
        ...state,
        quickCall: [action.payload.quickCall],
      };

    default:
      return state;
  }
};
export default quickCallReducer; 
