import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Box, CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CropImg from '../../assets/images/cropIcon.svg';
import rotateLeft from '../../assets/images/rotateLeft.svg';


const ImageCropModal = ({ isCropImageModal, translation, imageToCrop, isApiCalling, setCroppedImage, cancelCropImage, onSubmitCroppedImage }) => {
  const [crop, setCrop] = useState({});
  const [image, setImage] = useState(null);
  const [isCrop, setIsCrop] = useState(false);
  const [isDisabaled, setIsDisabled] = useState(true);
  const [rotate, setRotate] = useState(0);

  const onCropHandler = () => {
    setIsCrop(true);
    setIsDisabled(false);
  };

  const cropImageDone = () => {
    setIsCrop(false);
    setIsDisabled(true);
    if (!crop.width || !crop.height || !image) {
      return;
    }

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    canvas.toBlob((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      setCroppedImage(objectUrl);
    }, 'image/jpeg');
    setCrop({});
  };


  const handleRotate = () => {
    let angle;
    setRotate((prev) => {
      angle = prev + 90;
      return parseInt((prev + 90) / 180);
    });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (angle % 180 === 0) {
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
    } else {
      canvas.width = image.naturalHeight;
      canvas.height = image.naturalWidth;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2);

    canvas.toBlob((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      setCroppedImage(objectUrl);
    }, 'image/jpeg');
  };

  const handleCancel = () => {
    setCrop({});
    setImage(null);
    setRotate(0);
    setIsCrop(false);
    setIsDisabled(true);
    cancelCropImage();
  };

  const handleSubmit = () => {
    onSubmitCroppedImage();
  };

  return (
    <Dialog
      open={isCropImageModal}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='rcDialog'
    >
      <DialogContent>
        {!isCrop && !isApiCalling && imageToCrop && <Box className="rotateCrop" sx={{ display: 'flex', justifyContent: 'center', gap: 2, pb: 3 }}>
          <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: 'none' }}
            size="small"
            className="cis-btn btn-gray m-0"
            onClick={onCropHandler}
          >
            <img src={CropImg} />
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: 'none' }}
            size="small"
            className="cis-btn btn-gray m-0"
            onClick={handleRotate}
          >
            <img src={rotateLeft} />
          </Button>
        </Box>}

        {imageToCrop && !isApiCalling &&
          <ReactCrop
            src={imageToCrop}
            onImageLoaded={(img) => setImage(img)}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            disabled={isDisabaled}
          />}

          { isApiCalling && 
            <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: '60px' }}>
              <CircularProgress />
            </Box> }
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2, pb: 3 }}>

        <Button
          variant="contained"
          sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
          size="small"
          className='cis-btn m-0'
          onClick={handleCancel}
        >
          {translation?.QuickCancel}
        </Button>

        {isCrop ?
          <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={cropImageDone}
          >
            {translation?.Done}
          </Button> :
          <Button
            variant="contained"
            disabled={isApiCalling}
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={handleSubmit}
          >
            {translation?.QuickSubmit}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ImageCropModal);
