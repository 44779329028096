import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTIONS:
      return {
        transactions: action.payload.transactions,
      };

    default:
      return state;
  }
};
export default getTransactionsReducer;
