import * as actionTypes from "../actions/actionTypes";

const initialState = {tutor: []};

const activeTutorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVE_TUTOR_LIST:
      return {
        ...state,
        tutor: [action.payload.tutor],
      };

    default:
      return state;
  }
};
export default activeTutorReducer;
