import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";


export const getStudyMaterialSuccess = (data) => {
  return {
    type: actionTypes.GET_STUDY_MATERIAL,
    payload: {
      studyMaterial: data.Data,
    },
  };
};

export const getStudyMaterial = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetStudyMaterial`)
    .then((response) => {
      if (response) {
        dispatch(getStudyMaterialSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};
