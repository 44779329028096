import * as actionTypes from "../actions/actionTypes";

const initialState = {sessionDetails: []};

const studentSessionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STUDENTS_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: action.payload.sessionDetails,
      };

    default:
      return state;
  }
};
export default studentSessionDetailsReducer;
