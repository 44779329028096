import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getFavtTutorStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FAVT_TUTOR_STATUS:
      return {
        tutor: action.payload.tutor,
      };

    default:
      return state;
  }
};
export default getFavtTutorStatusReducer;

