import React, { useState, useMemo } from "react";
import { Box, Typography, Button, Snackbar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import useTranslations from "../../custom-hooks/useTranslations";
import shareImg from '../../assets/images/shareImg.png';

const ShareWithFriend = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const translations = useTranslations();

  const translation = useMemo(() => ({
    ...translations[26]?.QuickCall,
    ...translations[36]?.PracticeTest
  }), [translations]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: 'Check out this link!',
          url: window.location.origin,
        });
      } else {
        setIsOpen(true);
      }
    } catch (error) {
      console.error('Error sharing:', error.message);
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(window.location.origin + window.location.pathname);
    setSnackbarOpen(true); // Show snackbar on successful copy
  };

  const handleClosed = () => {
    setIsOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the snackbar
  };

  return (
    <>
      <Box className="shareBox" sx={{ mt: 3, mb: 3, display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' } }} onClick={handleShare}>
        <Box sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
          <Typography variant='div'>
            <img src={shareImg}></img>
          </Typography>
          <Typography>
            {translation?.ShareWithYourFriend}
          </Typography>
        </Box>
        <Box>
          <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.6148 25.0346H2.06429e-05V11.9639C-0.00237698 11.071 0.204115 10.19 0.602995 9.39111C1.14456 8.19601 2.01876 7.18211 3.12113 6.47054C4.22351 5.75897 5.50744 5.37984 6.81952 5.37844H13.667V0.96582H15.7911L24 9.17479L15.7619 17.4129H13.667V13.0002H9.21362C8.2821 13.0025 7.37673 13.3084 6.63479 13.8717C5.89284 14.4349 5.35479 15.2247 5.10221 16.1214L2.6148 25.0346ZM6.81952 6.98303C5.8138 6.9841 4.82976 7.27537 3.98547 7.82187C3.14119 8.36836 2.47254 9.14688 2.05976 10.064L2.04512 10.0948C1.75362 10.6746 1.60271 11.3149 1.60461 11.9639V22.6851L3.55669 15.6901C3.90418 14.4564 4.64449 13.3697 5.66535 12.5947C6.6862 11.8197 7.93193 11.3987 9.21362 11.3956H15.2715V15.634L21.7308 9.17479L15.2715 2.71552V6.98303H6.81952Z" fill="#4D4D4D" />
          </svg>
        </Box>
      </Box>

      <Dialog
        open={isOpen}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{translation?.ShareWithYourFriend}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', background: '#F9FCFF', padding: '10px 20px', gap: 4 }}>
            <Box sx={{ fontSize: '16px', fontWeight: '600' }}>
              {window.location.origin + window.location.pathname}
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={handleCopy}>
              <ContentCopyOutlinedIcon />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="large"
            className='cis-btn m-0'
            onClick={handleClosed}
          >
            {translation?.QuickCancel}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Link copied to clipboard!"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
});

export default React.memo(ShareWithFriend);