import React from "react"
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useDispatch, useSelector } from "react-redux";
import { closeMessageModal } from "../../actions/modalAction";

const MessageModal = ({isOpen, title, btnTxt, handleClose}) => {
    const dispatch = useDispatch();

    const message = useSelector(({ modalReducer }) => modalReducer.message);

    const closedHandler = () => {
        dispatch(closeMessageModal())
        if (typeof handleClose === 'function') {
            handleClose();
        }
    }

    return <Dialog
        open={isOpen}
        keepMounted
        onClose={closedHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
            <h2 className='title-1 text-center mb-0'>{message}</h2>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
            <Button
                variant="contained"
                sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
                size="large"
                className='cis-btn m-0'
                onClick={closedHandler}
            >
                {btnTxt}
            </Button>
        </DialogActions>
    </Dialog>
}

export default MessageModal;