import React from "react"
import { Button, Stack, Chip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import useHelperData from "../../custom-hooks/useHelperData";

const FeedbackModal = ({ isOpen, feedback, translation, onChangeFeedback, onSubmitFeedback }) => {
    const { helperData, getFeedBackOption } = useHelperData();
    const feedbackOption = getFeedBackOption();

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='feedback_dialog'
        >
        <DialogContent>
            <h2 className='title-1 text-center mb-0'> {translation?.SessionEndTeacher}</h2>
            <div className='mb-3 ratinbox'>
                <p className='title-info'>{translation?.HowTutor}</p>
                <Stack direction="row" spacing={1} className='rating-menus'>
                    {helperData?.RatingMsg?.map((item) => (
                        <Chip
                            key={item.Value}
                            label={item.Value}
                            onClick={() => onChangeFeedback("TutorRating", item.ID)}
                            variant="outlined"
                            className={feedback?.TutorRating === item.ID ? "cis-selected" : ""}
                        />
                    ))}
                </Stack>
            </div>
            <div className='lt_bg_wrap'>
                <div className='mb-3 ratinbox'>
                    <div fullwidth className='mb-20'>
                        <p className='title-info'>{translation?.DidYouLike}</p>
                        <Stack direction="row" spacing={1} className='rating-menus'>
                            {feedbackOption?.map((item) => (
                                <Chip
                                    key={item.ID}
                                    label={item.Value}
                                    onClick={() => onChangeFeedback("FeedbackMessage", item.ID)}
                                    variant="outlined"
                                    className={feedback?.FeedbackMessage?.includes(item.ID) ? "cis-selected" : ""}
                                />
                            ))}
                        </Stack>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                variant="contained"
                sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
                size="large"
                className='cis-btn m-0'
                onClick={onSubmitFeedback}
                disabled={!feedback?.TutorRating || !feedback?.FeedbackMessage[0]}
            >
                {translation?.QuickSubmit}
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default FeedbackModal;