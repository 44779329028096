import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getHelperData } from "../actions/getHelperData";

const useHelperData = () => {
  const dispatch = useDispatch();
  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData) || {};

  const getFeedBackOption = useCallback(
    () => helperData?.FeedbackMessage?.filter(item => item.RoleID === 3),
    [helperData]
  );

  const selectedCountry = useCallback(
    () => {
      const selectedCountry = helperData?.Country?.find((countryItem) => countryItem?.ID.toString() === localStorage.getItem('CountryID'))
      if (selectedCountry !== undefined) {
        return selectedCountry
      } else {
        return helperData?.Country?.find((countryItem) => countryItem?.ID.toString() === '61') || {}
      }
    },
    [helperData]
  );

  useEffect(() => {
    if (Object.keys(helperData).length === 0) {
      dispatch(getHelperData());
    }
  }, [])

  return { helperData, getFeedBackOption, selectedCountry };
};

export default useHelperData;
