import * as actionTypes from "../actions/actionTypes";

const initialState = [];
const getMockTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MOCK_TEST_LIST:
      return {
        mockTestList: action.payload.mockTestList,
      };

    default:
      return state;
  }
};
export default getMockTestReducer;
