import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, LinearProgress, Typography, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";
import VideoThumbnail from 'react-video-thumbnail';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import clock from '../../assets/images/clock.svg';
import calendar from '../../assets/images/calendar.svg';
import doubleCheck from '../../assets/images/double_check.svg';
import teachinfopic from '../../assets/images/techPic1.png';
import msg from '../../assets/images/msg.svg'


import "../../App.css";
import "../subject/Subject.css";
import "./PrivateClass.css";
import "../responsive/Responsive.css";

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '../../custom-hooks/useTranslations';
import { getClasses, getClassesSuccess } from '../../actions/classesAction';
import { addToFavtTutor } from '../../actions/addToFavtTutorAction';

const PrivateClass = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translations = useTranslations();
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [alignment, setAlignment] = useState('Active');
    const [isApiCalling, setIsApiCalling] = useState(true);

    const translation = {
      ...translations[0]?.Login,
      ...translations[8]?.PrivateClassDashboard,
      ...translations[12]?.ClassDetail,
      ...translations[12]?.Payment,
      ...translations[18]?.YourCourses,
      ...translations[21]?.CommonFrontend,
      ...translations[26]?.QuickCall,
    }

    const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
    const classes = useSelector(({ classReducer }) => classReducer.classes);

    const handleChange = (event, newAlignment) => {
        if (newAlignment) {
            const filterClasses = classes?.filter(({ ClassStatus }) => ClassStatus === newAlignment);
            setAlignment(newAlignment);
            setFilteredClasses(filterClasses);
        }
    };

    const handleFavouriteTutor = async (cls) => {
        const updatedClasses = classes?.map((item) => {
            if (item.TutorID === cls.TutorID) {
                return { ...item, isTutorFavourite: !item.isTutorFavourite };
            }
            return item;
        });
        dispatch(getClassesSuccess({ Data: updatedClasses }))
        dispatch(addToFavtTutor({ TutorID: cls?.TutorID }));
    };

    const handleSession = (session) => {
        navigate(`/overview/${btoa(session.ID)}`)
    }

    const handleMessage = (session) => {
        navigate(`/overview/${btoa(session.ID)}/${btoa(3)}`)
    }

    useEffect(() => {
        if (Array.isArray(classes)) {
            setIsApiCalling(false);
            const filterClasses = classes?.filter(({ ClassStatus }) => ClassStatus === alignment);
            setFilteredClasses(filterClasses);
        }
	}, [classes]);

    useEffect(() => {
        dispatch(getClasses())
    }, [])

    return (
        <div>
            <Header />
            <Box className="quikCallBanner">
                <Box className='OuterContainer' sx={{ border: 'none', borderRadius: '0px' }}>
                    <Typography variant='h1'>
                        {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className='OuterContainer containerShape' sx={{ background: '#EAF4FF', padding: '30px' }}>
                <Box className='SectionIn'>
                    <Box className="headbox">
                        <Typography variant='h4'>
                            {translation?.PrivateClass} 
                        </Typography>
                        <Box className="customToggle">
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="Complete">{translation?.CompleteStatus}</ToggleButton>
                                <ToggleButton value="Active">{translation?.ActiveBtn}</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    {/* { sessions?.length === 0 ? 
                        <Box className="outerCard oCenter">
                            <Typography variant='h3' sx={{ color: '#005DB8', fontSize: '24px', textTransform: 'capitalize', fontFamily: '"Rajdhani", sans-serif;' }}>
                            {translation?.coming}
                            <Typography variant='span' sx={{ color: '#EA4435' }}> {translation?.soon}</Typography>
                            </Typography>
                            <Typography variant='p' sx={{ fontSize: '18px', color: '#727272', textTransform: 'uppercase', mt: '10px', fontFamily: '"Rajdhani", sans-serif;' }}>{translation?.NoClassesFound}</Typography>
                        </Box> : */}

                    { isApiCalling && 
                        <Box className="outerCard oCenter"  sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    }

                    { isApiCalling === false && filteredClasses?.length === 0 &&
                        <Box className="outerCard oCenter">
                            <Typography variant='h3' sx={{ color: '#005DB8', fontSize: '24px', textTransform: 'capitalize', fontFamily: '"Rajdhani", sans-serif;' }}>
                            {translation?.Comming}
                            <Typography variant='span' sx={{ color: '#EA4435' }}> {translation?.Soon}</Typography>
                            </Typography>
                            <Typography variant='p' sx={{ fontSize: '18px', color: '#727272', textTransform: 'uppercase', mt: '10px', fontFamily: '"Rajdhani", sans-serif;' }}>{translation?.NoClassesFound}</Typography>
                        </Box>
                    }

                    { filteredClasses?.length > 0 && filteredClasses?.map((cls) => {
                        return <Box className="outerCard" sx={{ padding: '16px', marginTop: '10px' }} key={cls?.ID}>
                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px' }}>{cls?.PrivateClassName}</Typography>
                            <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px' }}>
                                <Box className="oc_headleft">
                                    <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                        <ListItem><img src={clock}></img>{cls?.ClassesTimmings}</ListItem>
                                        <ListItem><img src={calendar}></img>{new Date(cls?.ClassesDate).toLocaleDateString('en-US')}</ListItem>
                                    </List>
                                </Box>
                                <Box className="headright">
                                    <Typography variant='p'>{translation[cls?.ClassStatus]} <Typography variant='span' className={`${cls?.ClassStatus === 'Active' ? 'active-dot' : ''}`}></Typography></Typography>
                                </Box>
                            </Box>
                            <Box className="token_sessions">
                                <Box className="session_span">
                                    {cls?.ClassSessions?.reduce((acc, current) => current.SessionStatusID === 3 ? acc = acc + 1 : acc, 0)}/{cls?.ClassSessions?.length} {translation?.SessionTaken}
                                </Box>
                                <Grid className='session_grid_wrap' container spacing={2}>

                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    slidesPerGroup={3}
                                    pagination={{clickable: false}}
                                    navigation={true}
                                    modules={[ Navigation]}
                                    autoplay={{
                                    delay: 2000,
                                    pauseOnMouseEnter: true,
                                    disableOnInteraction: false
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView:1,
                                            spaceBetween:10,
                                        },
                                        480: {
                                        slidesPerView:2,
                                        spaceBetween:10,
                                        },
                                        768: {
                                            slidesPerView:3,
                                            spaceBetween:10,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    { cls?.ClassSessions?.map((session) => {
                                        return (
                                            <SwiperSlide key={session?.ID}>
                                                <Grid item>
                                                    <Box sx={{ cursor: 'pointer', border: '1px solid #f5f5f5', padding: '8px', borderRadius: '8px' }} onClick={() => handleSession(cls)}>
                                                        <Box className="item-head">
                                                            <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                                {session?.SessionName}
                                                            </Typography>
                                                            { session?.SessionStatus === 'Complete' && 
                                                                <img src={doubleCheck} />
                                                            }
                                                        </Box>
                                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                            {translation?.SessionStatus} {session?.SessionStatus}
                                                        </Typography>
                                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                            {new Date(session?.ClassesDate).toLocaleDateString('en-US')} | {session?.ClassesTimmings}
                                                        </Typography>

                                                        { session?.NumberOfProblem > 0 &&
                                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: session?.SessionStatusID === 3 ? '#BEEFB3' : '#EAF4FF', color: '#000', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px', maxWidth: '154px' }}>
                                                                {translation?.ProblemSolved} {session?.NumberOfSolvedProblem}/{session?.NumberOfProblem}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Grid>
                                            </SwiperSlide>
                                        )  
                                    }) }
                                    
                                </Swiper>


                                {/* { cls?.ClassSessions?.map((session) => {
                                        return   <Grid item md={4} key={session?.ID}>
                                            <Box sx={{ cursor: 'pointer' }} onClick={() => handleSession(cls)}>
                                                <Box className="item-head">
                                                    <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                        {session?.SessionName}
                                                    </Typography>
                                                    { session?.SessionStatus === 'Complete' && 
                                                        <img src={doubleCheck} />
                                                    }
                                                </Box>
                                                <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                    {translation?.SessionStatus} {session?.SessionStatus}
                                                </Typography>
                                                <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                    {new Date(session?.ClassesDate).toLocaleDateString('en-US')} | {session?.ClassesTimmings}
                                                </Typography>

                                                { session?.NumberOfProblem > 0 &&
                                                    <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: session?.SessionStatusID === 3 ? '#BEEFB3' : '#EAF4FF', color: '#000', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px' }}>
                                                        {translation?.ProblemSolved} {session?.NumberOfSolvedProblem}/{session?.NumberOfProblem}
                                                    </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                    }) }
                                     */}



                                    {/* <Grid item md={4}>
                                        <Box>
                                            <Box className="item-head">
                                                <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                    Session one
                                                </Typography>
                                                <img src={doubleCheck} />
                                            </Box>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                session completed
                                            </Typography>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                17 Oct 2023 | 06:00 pm
                                            </Typography>

                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: '#BEEFB3', color: '#000', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px' }}>
                                                problem solved 6/6
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Box>
                                            <Box className="item-head">
                                                <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                    Session two
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#727272' }}>
                                                session completed
                                            </Typography>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                17 Oct 2023 | 06:00 pm
                                            </Typography>

                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: '#EAF4FF', color: '#005DB8', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px' }}>
                                                problem solved 0/6
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Box>
                                            <Box className="item-head">
                                                <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                    parents meet
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#727272' }}>
                                                session completed
                                            </Typography>
                                            <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                                17 Oct 2023 | 06:00 pm
                                            </Typography>

                                        </Box>
                                    </Grid> */}
                                </Grid>
                                <Box className="sessionBy">
                                    <Box className="sessionBox sb-short">
                                        <Typography variant='h5' sx={{ marginBottom: '8px' }}>{translation?.SessionBy}</Typography>
                                        <Box className="RankInfo" sx={{ gap: 1.5, display: { xs: 'flex', alignItems: 'top' } }}>
                                            <Box 
                                                className={`ProfilePic ${cls?.OnlineStatusID === '1' ? 'add-online' : cls?.OnlineStatusID === '2' ? 'add-busy' : 'add-offline' }`} 
                                                onClick={() => navigate(`/tutor-profile/${btoa(cls?.TutorID)}`)}
                                            >
                                                <img src={cls?.ImageURL}></img>
                                            </Box>
                                            <Box className="TeachInfo">
                                                <Typography variant='h5'>{cls?.TutorName}</Typography>
                                                <Box className="RatingBox">
                                                    <Stack spacing={2}>
                                                        <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(cls?.TutorRating)} precision={0.5} readOnly />
                                                    </Stack>
                                                </Box>
                                            </Box>
                                            <Button className={`favrouiteBtn ${cls?.isTutorFavourite == true ? 'active' : ''}`} onClick={() => handleFavouriteTutor(cls)} sx={{padding: '0', minWidth: '36px', maxWidth: '36px', background: 'none', marginLeft:'auto'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <circle cx="12" cy="12" r="12" fill="#D8D8D8" />
                                                    <path d="M12 4L13.7961 9.52786H19.6085L14.9062 12.9443L16.7023 18.4721L12 15.0557L7.29772 18.4721L9.09383 12.9443L4.39155 9.52786H10.2039L12 4Z" fill="white" />
                                                </svg>
                                            </Button>
                                        </Box>
                                        <Box className="sessionBtn">
                                            {/* <button className='blueBtn radius4'><svg width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_4574_2478)">
                                                    <path d="M11.5 6.03896V6.49896C11.4994 7.57717 11.1503 8.62629 10.5047 9.48987C9.85908 10.3534 8.95164 10.9852 7.91768 11.2909C6.88372 11.5966 5.77863 11.5599 4.76724 11.1862C3.75584 10.8126 2.89233 10.122 2.30548 9.21749C1.71863 8.31297 1.4399 7.24299 1.51084 6.16711C1.58178 5.09124 1.9986 4.06712 2.69914 3.24749C3.39968 2.42787 4.34639 1.85665 5.39809 1.61903C6.4498 1.38141 7.55013 1.49012 8.535 1.92896" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.5 2.5L6.5 7.505L5 6.005" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4574_2478">
                                                        <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                                {translation?.MarkAsAFavourite}</button> */}
                                            <button className='bdrbtn' onClick={() => handleMessage(cls)}><img src={msg} />{translation?.Message}</button>
                                        </Box>
                                    </Box>
                                    <Box className="sessionBox sb-max">
                                        <Typography variant='h5' sx={{ marginBottom: '8px' }}>{translation?.Counselor}</Typography>
                                        <Box className="RankInfo" sx={{ gap: 1.5, display: { xs: 'flex', alignItems: 'top' } }}>
                                            <Box className="ProfilePic">
                                                <img src={cls?.MentorInfo?.ProfilePic}></img>
                                            </Box>
                                            <Box className="TeachInfo">
                                                <Typography variant='h5'>{cls?.MentorInfo?.Name}</Typography>
                                                <Box className="email-mob">
                                                    <Typography variant='span'>{cls?.MentorInfo?.Email}</Typography>
                                                    <Typography variant='span'>{cls?.MentorInfo?.MobileNo}</Typography>

                                                </Box>
                                            </Box>

                                        </Box>
                                        <Box className="sessionBtn">
                                            <button className='bdrbtn' onClick={() => handleMessage(cls)}><img src={msg} />{translation?.Message}</button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    })}

                    {/* <Box className="outerCard" sx={{ padding: '16px' }}>
                        <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '600', color: '#000', fontFamily: '"Rajdhani", sans-serif;', marginBottom: '8px' }}>Obtain the greatest number of your digits</Typography>
                        <Box className="oc_headwrap" sx={{ padding: '0', display: 'flex', gap: '10px' }}>
                            <Box className="oc_headleft">
                                <List sx={{ padding: '0', display: 'flex', gap: '15px', width: '100%' }}>
                                    <ListItem><img src={clock}></img> 06:00 pm to 07:00 pm</ListItem>
                                    <ListItem><img src={calendar}></img> 17 Oct 2023</ListItem>
                                </List>
                            </Box>
                            <Box className="headright">
                                <Typography variant='p'>Active <Typography variant='span' className='active-dot'></Typography></Typography>
                            </Box>
                        </Box>
                        <Box className="token_sessions">
                            <Box className="session_span">
                                0/4 session taken
                            </Box>
                            <Grid className='session_grid_wrap' container spacing={2}>
                                <Grid item md={4}>
                                    <Box>
                                        <Box className="item-head">
                                            <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                Session one
                                            </Typography>
                                            <img src={doubleCheck} />
                                        </Box>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                            session completed
                                        </Typography>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                            17 Oct 2023 | 06:00 pm
                                        </Typography>

                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: '#BEEFB3', color: '#000', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px' }}>
                                            problem solved 6/6
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Box>
                                        <Box className="item-head">
                                            <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                Session two
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#727272' }}>
                                            session completed
                                        </Typography>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                            17 Oct 2023 | 06:00 pm
                                        </Typography>

                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555', textTransform: 'uppercase', borderRadius: '8px', backgroundColor: '#EAF4FF', color: '#005DB8', marginBottom: 0, padding: '4px 8px', textAlign: 'center', marginTop: '4px' }}>
                                            problem solved 0/6
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Box>
                                        <Box className="item-head">
                                            <Typography variant='h5' sx={{ fontSize: '20px', color: '#000', fontWeight: '500', textTransform: 'capitalize' }}>
                                                parents meet
                                            </Typography>
                                        </Box>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#727272' }}>
                                            session completed
                                        </Typography>
                                        <Typography variant='h6' sx={{ fontSize: '14px', marginBottom: '8px', color: '#555555' }}>
                                            17 Oct 2023 | 06:00 pm
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="sessionBy">
                                <Box className="sessionBox sb-short">
                                    <Typography variant='h5' sx={{ marginBottom: '8px' }}>session by</Typography>
                                    <Box className="RankInfo" sx={{ gap: 1.5, display: { xs: 'flex', alignItems: 'top' } }}>
                                        <Box className="ProfilePic">
                                            <img src={teachinfopic}></img>
                                        </Box>
                                        <Box className="TeachInfo">
                                            <Typography variant='h5'>Ronak meher</Typography>
                                            <Box className="RatingBox">
                                                <Stack spacing={2}>
                                                    <Rating name="half-rating-read" defaultValue={3} precision={0.5} readOnly />
                                                </Stack>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box className="sessionBtn">
                                        <button className='blueBtn radius4'><svg width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_4574_2478)">
                                                <path d="M11.5 6.03896V6.49896C11.4994 7.57717 11.1503 8.62629 10.5047 9.48987C9.85908 10.3534 8.95164 10.9852 7.91768 11.2909C6.88372 11.5966 5.77863 11.5599 4.76724 11.1862C3.75584 10.8126 2.89233 10.122 2.30548 9.21749C1.71863 8.31297 1.4399 7.24299 1.51084 6.16711C1.58178 5.09124 1.9986 4.06712 2.69914 3.24749C3.39968 2.42787 4.34639 1.85665 5.39809 1.61903C6.4498 1.38141 7.55013 1.49012 8.535 1.92896" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.5 2.5L6.5 7.505L5 6.005" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4574_2478">
                                                    <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                            mark as a favourite</button>
                                        <button className='bdrbtn'><img src={msg} />message</button>
                                    </Box>
                                </Box>
                                <Box className="sessionBox sb-max">
                                    <Typography variant='h5' sx={{ marginBottom: '8px' }}>consular</Typography>
                                    <Box className="RankInfo" sx={{ gap: 1.5, display: { xs: 'flex', alignItems: 'top' } }}>
                                        <Box className="ProfilePic">
                                            <img src={teachinfopic}></img>
                                        </Box>
                                        <Box className="TeachInfo">
                                            <Typography variant='h5'>jhon robert</Typography>
                                            <Box className="email-mob">
                                                <Typography variant='span'>jhonrober@gmail.com</Typography>
                                                <Typography variant='span'>0123456789</Typography>

                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box className="sessionBtn">
                                        <button className='bdrbtn'><img src={msg} />message</button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box> */}

                        
                    {/* } */}
                </Box>
            </Box>
            <FooterSection />
        </div>
    )
}

export default PrivateClass
