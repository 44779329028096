import * as actionTypes from "../actions/actionTypes";

const initialState = {
  tutor: {},
};

const getTutorInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_INFORMATION:
      return {
        ...state,
        tutor: action.payload.tutor,
      };

    default:
      return state;
  }
};

export default getTutorInformationReducer;
