import React from "react"
import { Button, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import useHelperData from "../../custom-hooks/useHelperData";

const SessionReportModal = ({ isReport, reportId, translation, onChangeReport, closeReportModal, onSubmitReport }) => {
    const { helperData } = useHelperData();

    return <Dialog
        open={isReport}
        onClose={closeReportModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>{translation?.DidYouFind}</DialogTitle>
        <DialogContent>

            { helperData?.ReportMessages?.map((reportMessage) => (
                <FormGroup key={reportMessage?.ID} onChange={onChangeReport}>
                    <FormControlLabel 
                    control={
                        <Checkbox
                            checked={reportId == reportMessage.ID}
                            onChange={onChangeReport}
                            value={reportMessage.ID}
                        />
                    }
                label={reportMessage?.Value} />
                </FormGroup>
            ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center',  gap: 2, pb: 3 }}>
            <Button
                variant="contained"
                sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
                size="small"
                className='cis-btn m-0'
                onClick={closeReportModal}
            >
                {translation?.QuickCancel}
            </Button>
            <Button
                variant="contained"
                sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
                size="small"
                className='cis-btn m-0'
                disabled={!reportId}
                onClick={onSubmitReport}
            >
                {translation?.QuickSubmit}
            </Button>
        </DialogActions>
    </Dialog>
}

export default SessionReportModal;