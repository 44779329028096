import * as actionTypes from "../actions/actionTypes";

const initialState = [];
const getStudyMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDY_MATERIAL:
      return {
        studyMaterial: action.payload.studyMaterial,
      };

    default:
      return state;
  }
};
export default getStudyMaterialReducer;
