import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import './HeroSection.css'
import heroIn from '../../assets/images/banner-img.png';
import useTranslations from '../../custom-hooks/useTranslations';

function HeroSection() {

  return (
    <Box className='heroSection' sx={{ display: {xs: 'flex'} }}>
        <Box className="heroIn" sx={{ display: {xs:'flex', justifyContent: 'space-between', alignItems: 'center'} }}>
            <Box className='banner-content'>
                <Typography variant='h2'>
                this is the practice test for <br/>
                    {/* <Typography variant='span'> student toevaluate<br/>  knowledge</Typography>  */}
                </Typography>
                {/* <Typography>
                Lorem ipsum dolor sit amet consectetur. Ac felis bibendum in blandit ut risus est. Diam neque non id tellus leo. Vitae tristique congue sed morbi. Vel amet sit nibh erat facilisis nec.
                </Typography> */}
            </Box>
            {/* <Box className='banner-image' sx={{ display: {xs:'flex', justifyContent: 'end', alignItems: 'center'} }}>
            <img src={heroIn}></img>
            </Box> */}
        </Box>
    </Box>
  )
}

export default HeroSection
