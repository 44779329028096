import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, LinearProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";

import "../../App.css";
import "./Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";

import { useDispatch } from "react-redux";
import useTranslations from "../../custom-hooks/useTranslations";
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import { getMockTestList } from "../../actions/getMockTestList";
import { getQuestionbyChapter } from "../../actions/getQuestionByChapter";


const Chapter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translations = useTranslations();
  const { subjectId } = useParams();
  const { getSubject } = useStudyMaterial()
  const subject = getSubject(atob(subjectId));
  const subPathName = window.location.pathname.split('/')[1];

  const translation = {
    ...translations[36]?.PracticeTest
  }

  const handleChapterBox = (chapter) => {
    if (subPathName !== 'chapter') return;
    navigate(`/test-detail/${subjectId}/${btoa(chapter?.ID)}`)
  }

  const handleChapter = (chapter) => {
    dispatch(getQuestionbyChapter({ TopicID: atob(subjectId), ChapterID: chapter?.ID }))
    if (subPathName === 'practice-test') {
      navigate(`/practice-test/${subjectId}/${btoa(chapter?.ID)}`)
    } else {
      navigate(`/test-detail/${subjectId}/${btoa(chapter?.ID)}`)
    }
  }

  return (
    <>
      <Header />
      <HeroSection />
      <Box className="OuterContainer">
        <Box className="studyContainer">
          <SubTopHead subject={subject} />
          <Box className="practiceBox subjectPg">
            <Box className="practiceBoxIn">
              <Box
                className="chaptersBox"
                sx={{
                  mt: 2,
                  mb: 2,
                  display: {
                    xs: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                }}
              >
                <Box className="chapters lightGreen">
                  <Typography variant="h4">{subject?.NumberOfCompletedChapter}/{subject?.NumberOfChapter}</Typography>
                  <Typography>{translation?.PracticeChapter} {translation?.PracticeCompleted}</Typography>
                </Box>
                <Box className="chapters sky">
                  <Typography variant="h4">{subject?.ChapterPractising}/{subject?.NumberOfChapter}</Typography>
                  <Typography>{translation?.PracticeChapter} {translation?.PracticeStarted}</Typography>
                </Box>
                <Box className="chapters pink">
                  <Typography variant="h4">{subject?.ChapterNotStarted}/{subject?.NumberOfChapter}</Typography>
                  <Typography>{translation?.PracticeChapter} {translation?.PracticeNotStarted}</Typography>
                </Box>
              </Box>

              <Typography className="cenerHead" variant="div">
                <h3>{translation?.SelectChapterPractice}</h3>
              </Typography>

              <Box
                className="alertBox"
                sx={{
                  mt: 2,
                  mb: 2,
                  display: { xs: "flex", alignItems: "center" },
                }}
              >
                <Typography variant="div">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0007 2.83268C24.8247 2.83268 31.1673 9.17531 31.1673 16.9993C31.1673 24.8234 24.8247 31.166 17.0007 31.166C9.17662 31.166 2.83398 24.8234 2.83398 16.9993C2.83398 9.17531 9.17662 2.83268 17.0007 2.83268Z"
                      stroke="#555555"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 22.667V17.0003"
                      stroke="#555555"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 11.333H17.0142"
                      stroke="#555555"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Typography>
                <Typography>
                  {translation?.AttemptQuestionEachChapter}
                </Typography>
              </Box>

              { subject?.Chapters?.length > 0 && subject?.Chapters?.map((chapter, index) => {
                return (
                  <Box className="cardMain" 
                    sx={{ mb: 2, cursor: subPathName !== 'chapter' ? 'unset' : 'pointer' }} 
                    onClick={() => handleChapterBox(chapter)} 
                    key={chapter?.ID}>
                    <Box
                      className="cardTop"
                      sx={{
                        display: {
                          xs: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Box
                        className="cardRight"
                        sx={{ display: { xs: "flex", alignItems: "center" } }}
                      >
                        <Box className="cardIcon">
                          <Typography variant="div">{index + 1}</Typography>
                        </Box>
                        <Box className="cardContent">
                          <Typography variant="h4">
                            {chapter?.ChapterName}
                          </Typography>
                          { subPathName !== 'chapter' && 
                            <Box className="cardList">
                              <ul>
                                <li>{chapter?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                              </ul>
                            </Box>
                          }
                        </Box>
                      </Box>
                      { subPathName !== 'chapter' &&
                        <Box className="cardLeft">
                          <Button variant="contained" className={`${chapter?.ChapterCompleted === true ? 'lightGreen' : chapter?.ChapterCompleted === false && chapter?.ChapterStatus === 'Attempted' ? 'darkYellow' : ''}`} 
                            onClick={() => handleChapter(chapter)}>
                            {chapter?.ChapterCompleted === true ? translation?.PracticeCompleted : chapter?.ChapterCompleted === false && chapter?.ChapterStatus === 'Attempted' ? translation?.PracticeContinue : translation?.StartPractice}
                          </Button>
                        </Box>
                      }
                    </Box>

                    { subPathName !== 'chapter' && chapter?.ChapterCompleted === false && chapter?.ChapterStatus === 'Attempted' &&
                      <Box className="cardBottom">
                        <Typography variant="h5">{translation?.QuestionProgress}</Typography>
                        <Box className="progressBar">
                          <LinearProgress
                            variant="determinate"
                            value={(chapter?.QuestionAttempted/chapter?.NumberOfQuestion) * 100}
                            sx={{ height: 5 }}
                          />
                        </Box>

                        <Box className="progressNumber">
                          <span className="understood">{chapter?.QuestionUnderstood}/{chapter?.NumberOfQuestion} {translation?.PracticeUnderstood} </span>
                          <span className="practicing">{chapter?.QuestionAttempted}/{chapter?.NumberOfQuestion} {translation?.PracticeStarted}</span>
                          <span className="notStarted">{chapter?.NumberOfQuestion - chapter?.QuestionAttempted}/{chapter?.NumberOfQuestion} {translation?.PracticeNotStarted}</span>
                        </Box>
                      </Box>
                    }

                  </Box>
                )
              })}

              {/* <Box className="cardMain" sx={{ mt: 4 }}>
                <Box
                  className="cardTop"
                  sx={{
                    display: {
                      xs: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Box
                    className="cardRight"
                    sx={{ display: { xs: "flex", alignItems: "center" } }}
                  >
                    <Box className="cardIcon">
                      <Typography variant="div">1</Typography>
                    </Box>
                    <Box className="cardContent">
                      <Typography variant="h4">
                        Characteristics and Classification of Living Organisms
                      </Typography>
                      <Box className="cardList">
                        <ul>
                          <li> 1 topics</li>
                          <li>50 questions</li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="cardLeft">
                    <Button variant="contained" className="lightGreen">
                      completed
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box className="cardMain" sx={{ mt: 4 }}>
                <Box
                  className="cardTop"
                  sx={{
                    display: {
                      xs: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Box
                    className="cardRight"
                    sx={{ display: { xs: "flex", alignItems: "center" } }}
                  >
                    <Box className="cardIcon">
                      <Typography variant="div">2</Typography>
                    </Box>
                    <Box className="cardContent">
                      <Typography variant="h4">
                        Characteristics and Classification of Living Organisms
                      </Typography>
                      <Box className="cardList">
                        <ul>
                          <li> 1 topics</li>
                          <li>50 questions</li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="cardLeft">
                    <Button variant="contained" className="blueBtn">
                      start practice
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box className="cardMain" sx={{ mt: 4 }}>
                <Box
                  className="cardTop"
                  sx={{
                    display: {
                      xs: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Box
                    className="cardRight"
                    sx={{ display: { xs: "flex", alignItems: "center" } }}
                  >
                    <Box className="cardIcon">3</Box>
                    <Box className="cardContent">
                      <Typography variant="h4">
                        Characteristics and Classification of Living Organisms
                      </Typography>
                      <Box className="cardList">
                        <ul>
                          <li> 1 topics</li>
                          <li>50 questions</li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="cardLeft">
                    <Button variant="contained" className="darkYellow">
                      continue
                    </Button>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Typography variant="h5">chapter Progress</Typography>
                  <Box className="progressBar">
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ height: 5 }}
                    />
                  </Box>

                  <Box className="progressNumber">
                    <span className="understood">5/20 understood </span>
                    <span className="started">10/20 started</span>
                    <span className="notStarted">10/20 not started</span>
                  </Box>
                </Box>
              </Box> *}

              <Box className="cardMain" sx={{ mt: 4 }}>
                <Box
                  className="cardTop"
                  sx={{
                    display: {
                      xs: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Box
                    className="cardRight"
                    sx={{ display: { xs: "flex", alignItems: "center" } }}
                  >
                    <Box className="cardIcon">
                      <Typography variant="div">1</Typography>
                    </Box>
                    <Box className="cardContent">
                      <Typography variant="h4">
                        Characteristics and Classification of Living Organisms
                      </Typography>
                      <Box className="cardList">
                        <ul>
                          <li> 1 topics</li>
                          <li>50 questions</li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="cardLeft">
                    <Button variant="contained">start practice</Button>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <FooterSection />
    </>
  );
};

export default Chapter;
