import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { WebSocketURL } from '../config/apiConfig';
import { getHelperData } from '../actions/getHelperData';
import { getExamBoards } from '../actions/getExamBoardsAction';

let socket; // Declare the socket variable outside the hook

const initializeSocket = (socketUrl) => {
  if (!socket) {
    console.log("Initializing socket...");
    socket = io(socketUrl, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 3, // Number of attempts
      reconnectionDelay: 1000, // Delay between attempts in milliseconds
    });

    socket.on('connect', () => {
      console.log('Socket Connected');
      localStorage.setItem('WebSocketURL', socketUrl);
      if (Cookies.get("loginToken")) {
        const isActive = sessionStorage.getItem('IsActive');
        if (isActive != 'false') {
          const mySocketData = {
            AuthToken: localStorage.getItem('customToken'),
            authorization: JSON.parse(Cookies.get("loginToken"))?.idToken,
            DeviceID: sessionStorage.getItem('deviceId'),
            IsActive: "true"
          }
          socket.emit("LoginSession", JSON.stringify(mySocketData));
        }
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket Disconnected');
    });

    socket.on('connect_error', (error) => {
      console.error('Socket Connection Error:', error);
    });
  }
  return socket;
};

const useSocket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [isConnected, setIsConnected] = useState(socket?.connected || false);
  const [isEnvironmentSet, setIsEnvironmentSet] = useState(false);

  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
  const boards = useSelector(({ examBoardsReducer }) => examBoardsReducer?.boards);
  const translations = useSelector(({ getTranslationsReducer }) => getTranslationsReducer.translations || []);

  useEffect(() => {
    // Initialize socket
    let currentSocket;
    if (translations?.length > 0) {
      const translation = {
        ...translations[33]?.ApplicationConfiguration
      }
      // const socketUrl = translation?.SocketProductionUrlForTutorWeb; 
      const socketUrl = translation?.SocketDevelopmentUrlForTutorWeb; 

      currentSocket = initializeSocket(socketUrl);

      // Set up event listeners
      const setupListeners = () => {
        currentSocket.on('CountryEvent', (data) => {
          const response = JSON.parse(data);
          const ID = response?.Data[0]?.ID;
          const payload = {
            RoleID: '3',
            Platform: 'Web',
            AppVersion: '1.0',
          };
          localStorage.setItem('CountryID', ID);
          currentSocket.emit('AppConfiguration', JSON.stringify(payload));
        });

        currentSocket.on('AppConfiguration', (appConfigData) => {
          const appConfigResponse = JSON.parse(appConfigData);
          if (appConfigResponse?.Data?.Environment) {
            localStorage.setItem('Environment', appConfigResponse?.Data?.Environment);
            setIsEnvironmentSet(true);
          }
        });

        currentSocket.on('ClientActiveStatus', (response) => {
          const profile = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")) : null;
          if (profile) {
            const payload = { UserID: profile?.ID?.toString() };
            currentSocket.emit('ClientResponse', JSON.stringify(payload));
          }
        });

        currentSocket.on('LoginSessionResponseEvent', (data) => {
          const loginSessionResponse = JSON.parse(data);
      
          const deviceId = sessionStorage.getItem("deviceId");
      
          if (loginSessionResponse?.Data?.DeviceID !== deviceId) {
            sessionStorage.setItem('IsActive', 'false');
            localStorage.setItem('customToken', loginSessionResponse?.Data?.AuthToken);
            navigate('/multi-device');

            // const callRequestType = sessionStorage.getItem("CallRequestType")
            // if (callRequestType) {
            //     const deviceId = loginSessionResponse.Data.DeviceID;
            //     const authorization = loginSessionResponse.Data.authorization;


            //     if (callRequestType === 'QuickCall') {
            //       const userState = JSON.parse(localStorage.getItem("userState"))

            //     } else if (callRequestType === 'HireTutor') {
            //       const payload = JSON.parse(sessionStorage.getItem('CallRequest'));
            //       payload['EndPoint'] = '/TutorRequest';
            //       payload['DeviceID'] = deviceId;
            //       payload['authorization'] = authorization
            //       payload['langid'] = localStorage.getItem('langId');
            //       payload['SessionID'] = Number(callRequest?.SessionID) || 0 // default send 0 // after payment send session id
            //       payload['IsAccept'] = false;
            //       payload['IsDecline'] = false;
            //       payload['requestType'] = 2; // student = 1 tutor= 2
      
            //       socket.emit('PTModule', JSON.stringify(payload));
                  
            //     } else if (callRequestType === 'PrivateTraining') {
            //       const payload = JSON.parse(sessionStorage.getItem('CallRequest'));
            //       payload['DeviceID'] = deviceId
            //       payload['authorization'] = authorization;
            //       payload['RoleID'] = 2;
            //       payload['isAccept'] = false;
            //       payload['isDecline'] = true;
          
            //       socket.emit('TrainingModule', JSON.stringify(payload));
            //     }

            //   localStorage.removeItem("QCImage");
            //   localStorage.removeItem("userState");
            //   sessionStorage.removeItem("TutorResponse");
            //   sessionStorage.removeItem("CallRequest");
            //   sessionStorage.removeItem("CallRequestType");
            // }

          }
        });
      };

      // Attach listeners if not already set up
      if (!currentSocket.hasListeners('CountryEvent')) {
        setupListeners();
      }
    }


    // Track connection state
    // currentSocket.on('connect', () => setIsConnected(true));
    // currentSocket.on('disconnect', () => setIsConnected(false));

    // Clean up listeners on component unmount
    return () => {
      if (currentSocket) {
        currentSocket.off('CountryEvent');
        currentSocket.off('AppConfiguration');
        currentSocket.off('ClientActiveStatus');
        currentSocket.off('LoginSessionResponseEvent');
      }
    };
  }, [translations]);

  useEffect(() => {
    // Fetch data when the environment is set
    if (isEnvironmentSet && !helperData) {
      dispatch(getHelperData());
    }

    if (isEnvironmentSet && !boards) {
      dispatch(getExamBoards());
    }
  }, [isEnvironmentSet, helperData, boards]);

  return { socket, isConnected: socket?.connected || isConnected };
};


export default useSocket;
