import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useDispatch, useSelector } from "react-redux";
import { closeConfirmMessageModal } from "../../actions/modalAction";


const ConfirmationModal = ({ isOpen, title, doneBtnText, cancelBtnText, handleClose, handleSubmit}) => {
    const dispatch = useDispatch();

    const message = useSelector(({ modalReducer }) => modalReducer.confirmMessage);

    const closedHandler = () => {
        dispatch(closeConfirmMessageModal())
        if (typeof handleClose === 'function') {
            handleClose();
        }
    }

    const submitHandler = () => {
        dispatch(closeConfirmMessageModal())
        handleSubmit();
    }

  return (
    <Dialog
      open={isOpen}
    //   keepMounted
    //   onClose={closedHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <h2 className='title-1 text-center mb-0'>{message}</h2>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2, pb: 3 }}>
        <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={submitHandler}>
            {doneBtnText}
        </Button>
        <Button
            variant="contained"
            sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
            size="small"
            className='cis-btn m-0'
            onClick={closedHandler}>
            {cancelBtnText}
        </Button> 
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
