import React, { useEffect, useMemo } from 'react'
import { Box, Card, LinearProgress, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import "../sub-top-head/SubTopHead.css"

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslations from '../../custom-hooks/useTranslations';

import useMockTest from '../../custom-hooks/useMockTest';
import useMockTestQuestions from '../../custom-hooks/useMockTestQuestions';
import { checkAnswer } from '../../actions/checkAnswerAction';
import { confirmMessageModal } from '../../actions/modalAction';

import MockTestTimer from '../mock-test-timer/MockTestTimer';
import ConfirmationModal from '../common-components/ConfirmationModal';

const SubTopHead = React.memo(({ subject }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translations = useTranslations();
  const { subjectId, mockTestId } = useParams();
  const testId = mockTestId ? atob(mockTestId) : sessionStorage.getItem('MockTestID');
  const { getMockTest, updateScore } = useMockTest(atob(subjectId));
  const mockTest = getMockTest(testId);
  const { mockTestQuestions, updateAttemptedQuestions } = useMockTestQuestions(testId);

  const time = sessionStorage.getItem('MockTestTimer') || 0;
  const isMockTestStart = JSON.parse(sessionStorage.getItem('MockTestStart'));

  const subPathName = window.location.pathname.split('/')[1];
  const pathArr = ['mock-test', 'mock-test-detail', 'mock-test-questions-detail', 'mock-test-by-mark', 'mock-test-by-chapter', 'mock-test-result']

  const isConfirm = useSelector(({ modalReducer }) => modalReducer.isConfirm);

  const translation = useMemo(() => ({
    ...translations[26]?.QuickCall,
    ...translations[36]?.PracticeTest
  }), [translations]);

  const handleBack = () => {
    if (subPathName === "mock-test-result") {
      navigate('/test');
    } else if (subPathName === "mock-test-detail" && isMockTestStart) {
      dispatch(confirmMessageModal(translation?.MockTestSubmit))
    } else {
      navigate(-1);
    }
  }

  const handleTestCompleted = () => {
    const updateAnswer = JSON.parse(sessionStorage.getItem('AnsweredQuestion')) || [];
    updateAttemptedQuestions(updateAnswer );
    const score = mockTestQuestions?.reduce((acc, current) => {
      if (current.AnswerID === current.LastSelectedAnswerID) {
        acc = acc + current.Mark;
      }
      return acc;
    }, 0);
    updateScore(testId, score);
    const obj = {
      MockTestID: testId,
      Scored: score, 
      IsCompleted: true, 
      CompleteTime: (mockTest?.NumberOfMinute * 60) - time,
      DataList: updateAnswer,
      IsMockTest: true
    }
    dispatch(checkAnswer(obj));
    sessionStorage.removeItem('MockTestStart')
    sessionStorage.removeItem('MockTestTimer')
    sessionStorage.removeItem('AnsweredQuestion')
    sessionStorage.removeItem('MockTestQuestions')
    // navigate(`/mock-test-result/${subjectId}/${mockTestId}`);
    navigate(-1);
  }

  return (
    <div className='subHeading'>
      <Box className="cardMain lightGray">
        <Box className="BackBtn">
          <Button className='backBtn' variant="text" sx={{mb:2}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
        </Box>
        {isMockTestStart && <MockTestTimer /> }

        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                <Box className="cardIcon">
                    <img src={subject?.SubjectImageURL} alt=""></img>
                </Box>

                <Box className="cardContent">
                  <Typography variant='h4'>{subject?.SubjectName}</Typography>
                  <Box className="cardList">
                    { pathArr.includes(subPathName) ?
                      <ul>
                        <li>{subject?.MockTestCount} {translation?.MockTest}</li>
                        <li>{subject?.MockTestQuestionCount} {translation?.PracticeQuestion}</li>
                      </ul> :
                      <ul>
                        <li>{subject?.NumberOfChapter} {translation?.PracticeChapter}</li>
                        <li>{subject?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                      </ul>
                    }
                  </Box>
                </Box> 
            </Box>
        </Box>
      </Box>
      <ConfirmationModal isOpen={isConfirm} cancelBtnText={translation?.QuickCancel} doneBtnText={translation?.QuickSubmit} handleSubmit={handleTestCompleted} />
    </div>
  )
})

export default SubTopHead;
