import React, { useState, useEffect } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import shareImg from '../../assets/images/shareImg.png';


import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import ShareWithFriend from '../common-components/ShareWithFriend';

const Notes = () => {
    const navigate = useNavigate();
    const { noteId } = useParams();
    const translations = useTranslations();
    const { getNotes } = useStudentDashboard();
    const notes = getNotes(atob(noteId));

    const translation = {
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleDownload = (note) => {
        window.open(note.DocumentURL, '_blank')
    }

  return (
    <div>
       <Header/>
       <HeroSection/> 
       <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                            <Button className='backBtn' variant="text" sx={{mb:1, paddingLeft:0}}  startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardContent">
                                    <Typography variant='h4'>{notes?.Value}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />
                        
                        <Box className="SolutionBox" sx={{ mt:3}}>
                            <Box className="cardSimple" sx={{ mt:3, mb:3}}>
                                <Typography variant='div'>
                                    <h3>{translation?.StudyMaterial}</h3>
                                </Typography>
                                <Box className="cardMain" sx={{ mt:1}}>
                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon">
                                            <Typography variant='div'>
                                            <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5708 10.6196H22.4279M13.5708 16.5244H22.4279M13.5708 22.4291H19.4755M7.66602 7.66722C7.66602 6.8842 7.97707 6.13325 8.53075 5.57958C9.08443 5.0259 9.83538 4.71484 10.6184 4.71484H25.3803C26.1633 4.71484 26.9143 5.0259 27.4679 5.57958C28.0216 6.13325 28.3327 6.8842 28.3327 7.66722V28.3339C28.3327 29.1169 28.0216 29.8679 27.4679 30.4215C26.9143 30.9752 26.1633 31.2863 25.3803 31.2863H10.6184C9.83538 31.2863 9.08443 30.9752 8.53075 30.4215C7.97707 29.8679 7.66602 29.1169 7.66602 28.3339V7.66722Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </Typography>
                                            </Box>
                                            <Box className="cardContent">
                                                <Typography variant='h4'>{notes?.Value}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="samplePdf">
                                { notes?.Notes?.map((note) => {
                                    return <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }} key={note.ID}>
                                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                <Box className="cardIcon">
                                                    <Typography variant='div'>
                                                    <svg width="28" height="28" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="1" width="31" height="33" rx="7.5" stroke="#EB0029"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M25.5063 11.715L20.1762 6.3525L19.6075 6.125H7.0625L6.25 6.9375V15.875H7.875V7.75H17.625V13.4375L18.4375 14.25H24.125V15.875H25.75V12.3L25.5063 11.715ZM19.25 12.625V7.75L24.125 12.625H19.25ZM7.0625 17.5L6.25 18.3125V28.0625L7.0625 28.875H24.9375L25.75 28.0625V18.3125L24.9375 17.5H7.0625ZM24.125 25.625V27.25H7.875V19.125H24.125V25.625ZM11.125 24H10.605V25.625H9.5V20.75H11.2225C12.4412 20.75 13.0588 21.335 13.0588 22.375C13.0619 22.596 13.017 22.8151 12.9273 23.0171C12.8375 23.2191 12.7049 23.3992 12.5387 23.545C12.136 23.8589 11.6352 24.0201 11.125 24ZM11.0275 21.6438H10.605V23.155H11.0275C11.6125 23.155 11.905 22.895 11.905 22.3913C11.905 21.8875 11.6125 21.6438 11.0275 21.6438ZM17.625 24.9425C17.865 24.7061 18.0526 24.422 18.1758 24.1084C18.299 23.7949 18.3549 23.459 18.34 23.1225C18.34 21.4975 17.4788 20.75 15.74 20.75H14.0175V25.625H15.74C16.0837 25.6416 16.4273 25.5899 16.7509 25.4727C17.0745 25.3556 17.3716 25.1753 17.625 24.9425ZM15.1062 24.7312V21.6438H15.6425C15.8498 21.6306 16.0575 21.6598 16.2531 21.7297C16.4487 21.7995 16.628 21.9085 16.78 22.05C16.9185 22.1941 17.026 22.365 17.0959 22.5523C17.1658 22.7396 17.1965 22.9391 17.1862 23.1388C17.2106 23.5671 17.0646 23.9877 16.78 24.3088C16.6312 24.4511 16.4552 24.5619 16.2625 24.6345C16.0698 24.7071 15.8645 24.74 15.6587 24.7312H15.1062ZM22.3375 23.74H20.7613V25.625H19.6562V20.75H22.4837V21.6438H20.7613V22.8463H22.3375V23.74Z" fill="#EB0029"/>
                                                    </svg>

                                                    </Typography>
                                                </Box>
                                                <Box className="cardContent">
                                                    <Typography variant='h4'>{note?.Value}</Typography>
                                                    <Box className="cardList">
                                                        <ul>
                                                            <li>{new Date(note?.DateTime).toLocaleDateString('en-US')}</li>
                                                        </ul>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="cardLeft">
                                            <Typography variant='div' className='download-icon' sx={{cursor: 'pointer'}} onClick={() => handleDownload(note)}>
                                            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9993 21.2687L11.2807 16.5513L12.2247 15.5913L15.3327 18.6993V7.16602H16.666V18.6993L19.7727 15.5927L20.718 16.5513L15.9993 21.2687ZM8.82068 25.8327C8.20646 25.8327 7.69402 25.6273 7.28335 25.2167C6.87268 24.806 6.6669 24.2931 6.66602 23.678V20.4473H7.99935V23.678C7.99935 23.8833 8.08468 24.0718 8.25535 24.2433C8.42602 24.4149 8.61402 24.5002 8.81935 24.4993H23.1793C23.3838 24.4993 23.5718 24.414 23.7433 24.2433C23.9149 24.0727 24.0002 23.8842 23.9993 23.678V20.4473H25.3327V23.678C25.3327 24.2922 25.1273 24.8047 24.7167 25.2153C24.306 25.626 23.7931 25.8318 23.178 25.8327H8.82068Z" fill="#727272"/>
                                                </svg>
                                            </Typography>
                                            </Box>
                                        </Box>
                                    
                                }) }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

       <FooterSection/> 
    </div>
  )
}

export default Notes;