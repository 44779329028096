import { API_BASE_URL } from "../config/apiConfig";
import { axiosPost } from "../services/api";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getTutorInformationSuccess } from "../actions/getTutorInformation";
import { dateFns } from "../services/dateFns";

const useTutorInformation = (tutorId) => {
  const dispatch = useDispatch();
  const tutorInformation = useSelector(({ getTutorInformationReducer }) => getTutorInformationReducer.tutor || {});

  const getTutorInformation = useCallback(() => {
    const data = { TutorID: tutorId, WithMe: true, Page: 1, PageSize: 10 };

    if (Object.keys(tutorInformation).length === 0) {
      axiosPost(`${API_BASE_URL}/PrivateTutorInformation`, data)
        .then((res) => {
          dispatch(getTutorInformationSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [tutorId, tutorInformation]);

  const getTutorSession = useCallback(
    (sessionId) => {
      return tutorInformation?.TutorCall?.paginatedData?.find(session => session.ID.toString() === sessionId.toString());
    },
    [tutorInformation]
  );

  const bookmarkTutorSession = useCallback(
    (sessionId) => {
      const updatedData = tutorInformation?.TutorCall?.paginatedData?.map((session) => {
        if (session.ID === sessionId) {
          session.isBookmarked = !session?.isBookmarked;
        }
        return session;
      });
      dispatch(getTutorInformationSuccess({ Data: { ...tutorInformation, TutorCall: {...tutorInformation.TutorCall, paginatedData: updatedData} } }));
    },
    [tutorInformation]
  );

  const addSessionRating = (sessionId, tutorRating) => {
    tutorInformation.TutorCall.paginatedData = tutorInformation?.TutorCall?.paginatedData?.map((session) => {
      if (session.ID === sessionId) {
        session.Ratings = tutorRating;
      }
      return session;
    })
  }

  const filterTutorSession = useCallback(
    (sessionType, sessionDay) => {
      if (sessionType !== '0' && sessionDay !== '0') {
        const sessions = tutorInformation?.TutorCall?.paginatedData?.filter(session => session?.Type === sessionType);
        return dateFns(sessions, sessionDay);
      } else if (sessionType !== '0') {
        return tutorInformation?.TutorCall?.paginatedData?.filter(session => session?.Type === sessionType);
      } else if (sessionDay !== '0') {
        return dateFns(tutorInformation?.TutorCall?.paginatedData, sessionDay);
      } else {
        return tutorInformation?.TutorCall?.paginatedData;
      }
    },
    [tutorInformation]
  );

  useEffect(() => {
    getTutorInformation();
  }, [getTutorInformation]);

  return { tutorInformation, getTutorSession, bookmarkTutorSession, addSessionRating, filterTutorSession };
};

export default useTutorInformation;