import React, { useEffect } from 'react'
import { Box, Card, LinearProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import SubTopHead from '../sub-top-head/SubTopHead';
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";


import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/reproduction.png';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ShareWithFriend from '../common-components/ShareWithFriend';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import useMockTestQuestions from '../../custom-hooks/useMockTestQuestions';

const MockTestQuestionsDetail = () => {
    const navigate = useNavigate();
    const { subjectId, mockTestId } = useParams();
    const translations = useTranslations();
    const { getSubject } = useStudyMaterial();
    const subject = getSubject(atob(subjectId));
    const { groupQuestionsByMark } = useMockTestQuestions(atob(mockTestId));
    const questions = groupQuestionsByMark();

    const translation = {
        ...translations[21]?.CommonFrontend,
        ...translations[36]?.PracticeTest
    }

    const isMockTestStart = JSON.parse(sessionStorage.getItem('MockTestStart'));

    const startMockTestByMark = (question) => {
        navigate(`/mock-test-by-mark/${subjectId}/${mockTestId}/${btoa(question?.Mark)}`)
    }

  return (
    <div>
          <Header/>
          <HeroSection/>
          <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <SubTopHead subject={subject} />
               
                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />

                        {/* <Box className="solutionBanner lightYellow" sx={{mt:3, mb:0, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box className="contentBox">
                                <Typography>
                                Biology test 1
                                </Typography>
                                <Typography variant='h3' sx={{margin:0}} >
                                reproduction in organisms
                                </Typography>
                                <Box className="cardList">
                                    <ul>
                                        <li>30 questions </li>
                                        <li>3 topics</li>
                                    </ul>
                                </Box>
                            </Box>
                            <Box className="imageBox">
                                <img src={cardIcon}></img>
                            </Box>
                        </Box> */}
                       
                        <Box className="reproductionBox">
                            {/* <Box className="continueBox" sx={{mt:5, mb:5}}>
                                <button className='continueBtn'>
                                    <Typography variant='div'>continue practice</Typography>
                                    <Typography variant='div'><svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 21L11 11L1 1" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    </Typography>
                                </button>
                            </Box> */}

                            <Box className="mainHeading">
                                <Typography variant='h3'>
                                    {translation?.SelectQuestions}
                                </Typography>
                            </Box>
                            { questions?.length > 0 && 
                              questions.map((question, index) => {
                                    return (
                                        <Box className="cardMain"sx={{ mt:2}} key={question?.Mark}>
                                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                    <Box className="cardIcon">
                                                        <Typography variant='div'>
                                                            {index + 1}
                                                        </Typography>
                                                    </Box>
                                                    <Box className="cardContent">
                                                        <Typography variant='h4'>{question?.Mark} {translation?.PracticeMarks} {translation?.PracticeQuestion}</Typography>
                                                        <Typography>
                                                        {question?.Questions?.length} {translation?.Questions}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                {   question.AttemptedQuestion === 0 ? 
                                                    <Box className="cardLeft" sx={{mt:2}}>       
                                                        <Button variant="contained" onClick={() => startMockTestByMark(question)}>{translation?.StartPractice}</Button>
                                                    </Box> : question.AttemptedQuestion === question?.NoOfQuestions ?
                                                    <Box className="cardLeft" sx={{mt:2}}>       
                                                        <Button variant="contained" className='lightGreen' onClick={() => startMockTestByMark(question)}>{translation?.PracticeCompleted}</Button>
                                                    </Box> :
                                                    <Box className="cardLeft" sx={{mt:2}}>       
                                                        <Button variant="contained" className='darkYellow' onClick={() => startMockTestByMark(question)}>{translation?.Continue}</Button>
                                                    </Box> 
                                                }
                                            </Box>

                                            {/* { question?.AttemptedStatus &&  */}
                                            { isMockTestStart && 
                                                <Box className="cardBottom">
                                                    <Typography variant='h5'>
                                                        {translation?.ChapterProgress}
                                                    </Typography>
                                                    <Box className="progressBar">
                                                        <LinearProgress variant="determinate" value={(question?.AttemptedQuestion / question?.NoOfQuestions) * 100} sx={{height:5}} />
                                                    </Box>
                                                    
                                                    <Box className="progressNumber">
                                                        {/* <span className='understood'>
                                                            {question?.Questions?.reduce((acc, current) => current?.Understood === true ? acc = acc + 1 : acc, 0)}/{question?.NoOfQuestions} {translation?.PracticeUnderstood} 
                                                        </span> */}
                                                        <span className={question?.AttemptedQuestion == question?.NoOfQuestions ? 'lightGreen' : 'understood'}>
                                                            {question?.AttemptedQuestion} / {question?.NoOfQuestions} {translation?.PracticeAttempted}
                                                        </span>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                    )
                                })
                            }

                            {/* <Box className="cardMain"sx={{ mt:2}}>
                                <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                    <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                                1
                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4'>overview of preproduction and life span</Typography>
                                            <Typography>
                                            10 questions
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="cardBottom">
                                    <Typography variant='h5'>
                                        chapter Progress
                                    </Typography>
                                    <Box className="progressBar">
                                        <LinearProgress variant="determinate" value={progress} sx={{height:5}} />
                                    </Box>
                                    
                                    <Box className="progressNumber">
                                        <span className='understood'>0/4 understood </span>
                                        <span className='practicing lightGreen'>04/10 aattempted</span>
                                    </Box>
                                </Box>

                                <Box className="cardLeft" sx={{mt:2}}>       
                                    <Button variant="contained" className='darkYellow radius8'>Continue</Button>
                                </Box>
                            </Box>
                            <Box className="cardMain"sx={{ mt:2}}>
                                <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                    <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                                2
                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4'>asexual reproduction</Typography>
                                            <Typography>
                                            10 questions
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="cardLeft" sx={{mt:2}}>       
                                    <Button variant="contained">start practice</Button>
                                </Box>
                            </Box>

                            <Box className="cardMain"sx={{ mt:2}}>
                                <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                    <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                        <Box className="cardIcon">
                                            <Typography variant='div'>
                                               3
                                            </Typography>
                                        </Box>
                                        <Box className="cardContent">
                                            <Typography variant='h4'>sexual reproduction</Typography>
                                            <Typography>
                                            10 questions
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="cardLeft" sx={{mt:2}}>       
                                    <Button variant="contained">start practice</Button>
                                </Box>
                            </Box> */}
                        </Box>
                       
                    </Box>
                </Box>
            </Box>
          </Box>
        <FooterSection/>
    </div>
  )
}

export default MockTestQuestionsDetail;