import React from 'react'
import { Box, Card, LinearProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Header from "../header/Header";
import SubTopHead from '../sub-top-head/SubTopHead';
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import shareImg from '../../assets/images/shareImg.png';
import cardIcon from '../../assets/images/reproduction.png';

import { useNavigate, useParams } from 'react-router-dom';

import ShareWithFriend from '../common-components/ShareWithFriend';
import useTranslations from '../../custom-hooks/useTranslations';
import useMockTest from '../../custom-hooks/useMockTest';
import useMockTestQuestions from '../../custom-hooks/useMockTestQuestions';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

const MockTestDetail = () => {
    const navigate = useNavigate();
    const { subjectId, mockTestId } = useParams();
    const translations = useTranslations();
    const { getSubject } = useStudyMaterial();
    const subject = getSubject(atob(subjectId));
    const { getMockTest, updateAttemptedCount } = useMockTest(atob(subjectId));
    const { Instructions, groupQuestionsByChapter, updateQuestionsAttemptedStatus } = useMockTestQuestions(atob(mockTestId));
    const questionsByChapter = groupQuestionsByChapter();
    const mockTest = getMockTest(atob(mockTestId));

    const translation = {
        ...translations[8]?.PrivateClassDashboard,
        ...translations[21]?.CommonFrontend,
        ...translations[36]?.PracticeTest
    }

    const isMockTestStart = JSON.parse(sessionStorage.getItem('MockTestStart'));

    const handleMockTest = async () => {
        try {
            navigate(`/mock-test-questions-detail/${subjectId}/${mockTestId}`)
            if (!isMockTestStart) {
                sessionStorage.setItem('MockTestID', atob(mockTestId));
                sessionStorage.setItem('MockTestStart', true);
                updateQuestionsAttemptedStatus();
                updateAttemptedCount(atob(mockTestId));
                await axiosPost(`${API_BASE_URL}/MockTestReAttempted`, { MockTestID: atob(mockTestId) });
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    const startMockTestByChapter = async (chapter) => {
        try {
            navigate(`/mock-test-by-chapter/${subjectId}/${mockTestId}/${btoa(chapter?.ChapterID)}`)
            if (!isMockTestStart) {
                sessionStorage.setItem('MockTestID', atob(mockTestId));
                sessionStorage.setItem('MockTestStart', true);
                updateQuestionsAttemptedStatus();
                updateAttemptedCount(atob(mockTestId));
                await axiosPost(`${API_BASE_URL}/MockTestReAttempted`, { MockTestID: atob(mockTestId) });
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    const viewResult = () => {
        navigate(`/mock-test-result/${subjectId}/${mockTestId}`)
    }

  return (
    <div>
        <Header/>
          <HeroSection/>
          <Box className='OuterContainer'>
            <Box className='studyContainer'>
                <SubTopHead subject={subject} />
                {/* <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                            <Button className='backBtn' variant="text" sx={{mb:1, paddingLeft:0}}  startIcon={<ArrowBackIcon />}> Back </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardContent">
                                    <Typography variant='h4'>biology</Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>20 Chapters</li>
                                            <li>20 Topics</li>
                                            <li>820 Questions</li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box> */}

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />
                        {/* <Box className="shareBox" sx={{mt:3, mb:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                            <Box sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                            <Typography variant='div'>
                                <img src={shareImg}></img>
                            </Typography>
                            <Typography>
                                share with your friends
                            </Typography>
                            </Box>
                            <Box>
                            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.6148 25.0346H2.06429e-05V11.9639C-0.00237698 11.071 0.204115 10.19 0.602995 9.39111C1.14456 8.19601 2.01876 7.18211 3.12113 6.47054C4.22351 5.75897 5.50744 5.37984 6.81952 5.37844H13.667V0.96582H15.7911L24 9.17479L15.7619 17.4129H13.667V13.0002H9.21362C8.2821 13.0025 7.37673 13.3084 6.63479 13.8717C5.89284 14.4349 5.35479 15.2247 5.10221 16.1214L2.6148 25.0346ZM6.81952 6.98303C5.8138 6.9841 4.82976 7.27537 3.98547 7.82187C3.14119 8.36836 2.47254 9.14688 2.05976 10.064L2.04512 10.0948C1.75362 10.6746 1.60271 11.3149 1.60461 11.9639V22.6851L3.55669 15.6901C3.90418 14.4564 4.64449 13.3697 5.66535 12.5947C6.6862 11.8197 7.93193 11.3987 9.21362 11.3956H15.2715V15.634L21.7308 9.17479L15.2715 2.71552V6.98303H6.81952Z" fill="#4D4D4D"/>
                            </svg>
                            </Box>
                        </Box> */}

                        <Box className="solutionBanner lightYellow detailFull" sx={{pb:2}}>
                           <Box className="contentUp" sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                                <Box className="contentBox">
                                    <Typography variant='h3' sx={{margin:0}} >
                                    {mockTest?.SubjectName}
                                    </Typography>
                                    <Box className="attemptedHeading" sx={{mt:1}}>
                                        <Box sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                            <Typography variant='div'>
                                                <img src={shareImg}></img>
                                            </Typography>
                                            <Typography>
                                            <Typography variant='div'>{mockTest?.AttemptedUser} {translation?.Aspirants} {translation?.Have}</Typography> {translation?.AttemptedSoFar}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="imageBox">
                                    <img src={cardIcon}></img>
                                </Box>
                            </Box>
                            <Box className="cardList" sx={{mt:1}}>
                                    <ul>
                                        <li> <strong>{mockTest?.NumberOfMinute}</strong> {translation?.PracticsMinutes} </li>
                                        <li> <strong>{mockTest?.NumberOfMark}</strong>  {translation?.TotalMarks}</li>
                                        <li> <strong>{mockTest?.NumberOfQuestion}</strong> {translation?.PracticeQuestion}</li>
                                    </ul>
                                </Box>
                        </Box>
                        
                       
                        <Box className="MockTestContent">
                            <Box className="continueBox" sx={{mt:5, mb:5, gap:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>

                                { mockTest?.AttemptedCount > 0 && !isMockTestStart ? <>
                                    <button className='continueBtn'>
                                        <Typography variant='div' onClick={handleMockTest}>
                                            {mockTest?.AttemptedCount === 1 ? `${mockTest?.AttemptedCount}st ` : 
                                            mockTest?.AttemptedCount === 2 ? `${mockTest?.AttemptedCount}nd ` :
                                            mockTest?.AttemptedCount === 3 ? `${mockTest?.AttemptedCount}rd ` : `${mockTest?.AttemptedCount}th `} 
                                            {translation?.PracticeAttempt}
                                        </Typography>
                                        <Typography variant='div'>
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5039 10.998H5.50391C4.39934 10.998 3.50391 11.8935 3.50391 12.998V19.998C3.50391 21.1026 4.39934 21.998 5.50391 21.998H19.5039C20.6085 21.998 21.5039 21.1026 21.5039 19.998V12.998C21.5039 11.8935 20.6085 10.998 19.5039 10.998Z" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.50391 10.998V6.99805C7.50391 5.67196 8.03069 4.40019 8.96837 3.46251C9.90605 2.52483 11.1778 1.99805 12.5039 1.99805C13.83 1.99805 15.1018 2.52483 16.0394 3.46251C16.9771 4.40019 17.5039 5.67196 17.5039 6.99805V10.998" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Typography>
                                    </button>

                                    <button className='continueBtn' onClick={viewResult}>
                                        <Typography variant='div'>{translation?.SeeResults}</Typography>
                                        <Typography variant='div'>
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.5039 10.998H5.50391C4.39934 10.998 3.50391 11.8935 3.50391 12.998V19.998C3.50391 21.1026 4.39934 21.998 5.50391 21.998H19.5039C20.6085 21.998 21.5039 21.1026 21.5039 19.998V12.998C21.5039 11.8935 20.6085 10.998 19.5039 10.998Z" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.50391 10.998V6.99805C7.50391 5.67196 8.03069 4.40019 8.96837 3.46251C9.90605 2.52483 11.1778 1.99805 12.5039 1.99805C13.83 1.99805 15.1018 2.52483 16.0394 3.46251C16.9771 4.40019 17.5039 5.67196 17.5039 6.99805V10.998" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Typography>
                                    </button>  
                                </> : <></> }

                                { mockTest.AttemptedCount === 0 && !isMockTestStart ?
                                    <button className='continueBtn' onClick={handleMockTest}>
                                        <Typography variant='div'>{translation?.StartPractice}</Typography>
                                    </button> : <></> 
                                }

                                { isMockTestStart ?
                                    <button className='continueBtn' onClick={handleMockTest}>
                                        <Typography variant='div'>{translation?.Continue}</Typography>
                                    </button> : <></> 
                                }


                            </Box>

                            <Box className="accordionMain">

                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    >
                                    <Typography>{translation?.Instructions}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className="contentBox">
                                            <ul>
                                                <li>{translation.TheQuestionPaperHave} {Instructions?.length} {translation?.SectionsAnd} {Instructions?.reduce((acc, section) => acc + section.NoofQuestion, 0)} {translation?.Questions}.</li>
                                                <li>{translation.QuestionsCompulsory}.</li>
                                                { Instructions?.map((item, index) => {
                                                    return <li key={index}>{item?.SectionName} {translation?.Has} {item?.NoofQuestion} {translation?.QuestionsOf} {item?.PerQuestionMarks} {translation?.MarkEach}.</li>
                                                }) }
                                                {/* <li>Section -a has 10 questions of 1 mark each. section- b has 9 question of 2 marks each. section-c has 8 questions of 3 marks each. section-d has 2 questions of 4 marks each. and section e has 1 question of 5 marks</li> */}
                                            </ul>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    >
                                    <Typography>{translation?.Syllabus}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className="contentBox">
                                        <Typography variant='h3'>{mockTest.SubjectName}</Typography>
                                            <Box className="InnerBorder">

                                                {questionsByChapter?.length > 0 && 
                                                    questionsByChapter.map((chapter) => {
                                                        return <Box className="cardMain"sx={{ mt:2}} key={chapter?.ChapterID}>
                                                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                                    <Box className="cardContent">
                                                                        <Typography variant='h4'>{chapter?.ChapterName}</Typography>
                                                                    </Box> 
                                                                </Box>
                                                                { chapter?.AttemptedStatus === false || !isMockTestStart ?
                                                                    <Box className="cardLeft" onClick={() => startMockTestByChapter(chapter)}>
                                                                        <Button variant="contained">{translation?.StartPractice}</Button>
                                                                    </Box> : chapter?.AttemptedQuestion === chapter?.NoOfQuestions ?
                                                                    <Box className="cardLeft" onClick={() => startMockTestByChapter(chapter)}>
                                                                        <Button variant="contained" className='lightGreen'>{translation?.PracticeCompleted}</Button>
                                                                    </Box> : chapter?.NotAttemptedQuestion > 0 ?
                                                                    <Box className="cardLeft" onClick={() => startMockTestByChapter(chapter)}>
                                                                        <Button variant="contained" className='darkYellow'>{translation?.Continue}</Button>
                                                                    </Box> : <></>
                                                                }
                                                            </Box>

                                                            {/* {   chapter?.AttemptedStatus === true && isMockTestStart ? */}
                                                            { isMockTestStart ?
                                                                <Box className="cardBottom">
                                                                    <Typography variant='h5'>
                                                                        {translation?.OnlyQuestionLeft?.replace('{XXX}', chapter?.NotAttemptedQuestion)}
                                                                    </Typography>
                                                                    <Box className="progressBar">
                                                                        <LinearProgress variant="determinate" value={((chapter?.Questions?.length - chapter?.NotAttemptedQuestion)/chapter?.Questions?.length * 100)} sx={{height:5}} />
                                                                    </Box>
                                                                </Box> : <></>
                                                            }
                                                        </Box>
                                                    })
                                                }
                                            

                                                {/* <Box className="cardMain"sx={{ mt:2}}>
                                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                            <Box className="cardContent">
                                                                <Typography variant='h4'>sexual preproduction in flowering plants</Typography>
                                                            </Box>
                                                            
                                                        </Box>
                                                        <Box className="cardLeft">
                                                            <Button variant="contained">start practice</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box className="cardMain"sx={{ mt:2}}>
                                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                            <Box className="cardContent">
                                                                <Typography variant='h4'>shuman reproduction</Typography>
                                                            </Box>
                                                            
                                                        </Box>
                                                        <Box className="cardLeft">
                                                            <Button variant="contained">start practice</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box className="cardMain"sx={{ mt:2}}>
                                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                            <Box className="cardContent">
                                                                <Typography variant='h4'>reproductive health</Typography>
                                                            </Box>
                                                            
                                                        </Box>
                                                        <Box className="cardLeft">
                                                            <Button variant="contained">start practice</Button>
                                                        </Box>
                                                    </Box>
                                                </Box> */}


                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                
                            </Box>
                            <Box className="QuestionSolution" sx={{mt:3}}>
                                <Box className="cardMain orgbdr lightYellow">
                                    <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon YellowCircle">
                                            <Typography variant='div'>
                                            <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                            <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                            </svg>
                                            </Typography>
                                            </Box>
                                            <Box className="cardContent">
                                                <Typography variant='h6'>{translation?.SolutionAvailableTest}.</Typography>
                                            </Box>
                                            
                                        </Box>
                                        {/* <Box className="cardLeft">
                                            <Button variant="contained" className='darkYellow'>check solution</Button>
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                       
                    </Box>
                </Box>
            </Box>
          </Box>
          <FooterSection/>
    </div>
  )
}

export default MockTestDetail