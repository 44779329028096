import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getCountryCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRY_CODE:
      return {
        country: action.payload.country,
      };

    default:
      return state;
  }
};
export default getCountryCodeReducer;

