import React, { useMemo } from 'react'
import Container from '@mui/system/Container';
import './FooterSection.css'
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import contactIconPh from '../../assets/images/phone.png';
import contactIconEm from '../../assets/images/email.png';

import social1 from '../../assets/images/fb.png';
import social2 from '../../assets/images/tw.png';
import social3 from '../../assets/images/inst.png';
import social4 from '../../assets/images/in.png';

import useTranslations from '../../custom-hooks/useTranslations';

const FooterSection = () => {
  const translations = useTranslations();
  const translation = useMemo(() => ({
    ...translations[21]?.CommonFrontend,
    ...translations[33]?.ApplicationConfiguration,
  }), [translations]);

  return (
    <div className='FooterMain'>
       {/* <Container maxWidth="md">
           <Box className="footerIn">
            <Typography variant='h6'>contact us</Typography>
            <Box className="contactUs" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                <a href='tel:0123456789'><span> <img src={contactIconPh}></img> </span> 0123456789</a>
                <a href='mailto:admin@gmail.com'><span> <img src={contactIconEm}></img> </span>admin@gmail.com</a>
            </Box>
            <Box className="socialBox" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                <a href=''><svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.75 11.5H10.25L11.25 7.5H7.75V5.5C7.75 4.47 7.75 3.5 9.75 3.5H11.25V0.14C10.924 0.0970001 9.693 0 8.393 0C5.678 0 3.75 1.657 3.75 4.7V7.5H0.75V11.5H3.75V20H7.75V11.5Z" fill="#727272"/>
                </svg>
                </a>

                <a href=''><svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.46 2.5C20.69 2.85 19.86 3.08 19 3.19C19.88 2.66 20.56 1.82 20.88 0.81C20.05 1.31 19.13 1.66 18.16 1.86C17.37 1 16.26 0.5 15 0.5C12.65 0.5 10.73 2.42 10.73 4.79C10.73 5.13 10.77 5.46 10.84 5.77C7.28004 5.59 4.11004 3.88 2.00004 1.29C1.63004 1.92 1.42004 2.66 1.42004 3.44C1.42004 4.93 2.17004 6.25 3.33004 7C2.62004 7 1.96004 6.8 1.38004 6.5V6.53C1.38004 8.61 2.86004 10.35 4.82004 10.74C4.19088 10.9129 3.53008 10.9369 2.89004 10.81C3.16165 11.6625 3.69358 12.4084 4.41106 12.9429C5.12854 13.4775 5.99549 13.7737 6.89004 13.79C5.37371 14.9905 3.49405 15.6394 1.56004 15.63C1.22004 15.63 0.880039 15.61 0.540039 15.57C2.44004 16.79 4.70004 17.5 7.12004 17.5C15 17.5 19.33 10.96 19.33 5.29C19.33 5.1 19.33 4.92 19.32 4.73C20.16 4.13 20.88 3.37 21.46 2.5Z" fill="#727272"/>
                </svg></a>

                <a href=''><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z" fill="#727272"/>
                </svg></a>
                
                <a href=''><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16ZM15.5 15.5V10.2C15.5 9.33539 15.1565 8.5062 14.5452 7.89483C13.9338 7.28346 13.1046 6.94 12.24 6.94C11.39 6.94 10.4 7.46 9.92 8.24V7.13H7.13V15.5H9.92V10.57C9.92 9.8 10.54 9.17 11.31 9.17C11.6813 9.17 12.0374 9.3175 12.2999 9.58005C12.5625 9.8426 12.71 10.1987 12.71 10.57V15.5H15.5ZM3.88 5.56C4.32556 5.56 4.75288 5.383 5.06794 5.06794C5.383 4.75288 5.56 4.32556 5.56 3.88C5.56 2.95 4.81 2.19 3.88 2.19C3.43178 2.19 3.00193 2.36805 2.68499 2.68499C2.36805 3.00193 2.19 3.43178 2.19 3.88C2.19 4.81 2.95 5.56 3.88 5.56ZM5.27 15.5V7.13H2.5V15.5H5.27Z" fill="#727272"/>
                </svg>
                </a>
            </Box>
           </Box> */}
           {/* <Divider sx={{mt:3, mb:3}} /> */}
           <Divider  sx={{mb:3}} />

           <Box className="copyRight">
            <Typography>
            {translation?.CopyRight} @ {new Date().getFullYear()} {translation?.RightReserved} 
            </Typography>
           </Box>
       {/* </Container> */}
    </div>
  )
}

export default  React.memo(FooterSection);
