import React, { useEffect } from "react";

const Timers = ({ seconds, setSeconds, minutes = 0, setMinutes }) => {
  useEffect(() => {
    if (minutes === 0 && seconds === 0) return;

    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prev => prev - 1);
      } else if (minutes > 0) {
        setMinutes(prev => prev - 1);
        setSeconds(59);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes, setSeconds, setMinutes]);

  return (
    <span>
      {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
    </span>
  );
};

export default Timers;