import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const getChatBackupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHAT_BACKUP:
      return {
        chat: action.payload.chat,
      };

    default:
      return state;
  }
};
export default getChatBackupReducer;
