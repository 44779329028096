import { parseISO, startOfToday, startOfYesterday, startOfWeek, startOfMonth, startOfYear, endOfDay, endOfMonth, endOfWeek, endOfYear, isWithinInterval, subWeeks, subMonths, subYears } from 'date-fns';

export const dateFns = (data, range) => {
  const today = startOfToday();
  const yesterday = startOfYesterday();
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
  const startOfCurrentMonth = startOfMonth(today);
  const startOfCurrentYear = startOfYear(today);

  let startDate, endDate;

  switch (range) {
    case 'today':
      startDate = today;
      endDate = endOfDay(today);
      break;
    case 'yesterday':
      startDate = yesterday;
      endDate = endOfDay(yesterday);
      break;
    case 'thisWeek':
      startDate = startOfCurrentWeek;
      endDate = endOfDay(today);
      break;
    case 'lastWeek':
      startDate = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
      endDate = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
      break;
    case 'thisMonth':
      startDate = startOfCurrentMonth;
      endDate = endOfDay(today);
      break;
    case 'lastMonth':
      startDate = startOfMonth(subMonths(today, 1));
      endDate = endOfMonth(subMonths(today, 1));
      break;
    case 'thisYear':
      startDate = startOfCurrentYear;
      endDate = endOfDay(today);
      break;
    case 'lastYear':
      startDate = startOfYear(subYears(today, 1));
      endDate = endOfYear(subYears(today, 1));
      break;
    default:
      return data;
  }

  return data?.filter(item => {
    const date = parseISO(item?.StartDateTime || item?.CreatedDate); // Adjust according to your data structure
    return isWithinInterval(date, { start: startDate, end: endDate });
  });
};
