import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import advertising1 from '../../assets/images/Addlogo.png';
import advertising2 from '../../assets/images/play-store.png';
import advertising3 from '../../assets/images/apple-store.png';
import advertising4 from '../../assets/images/artboard.png';

import useTranslations from '../../custom-hooks/useTranslations';

const Advertisement = () => {
  const translations = useTranslations();
  const translation = useMemo(() => ({
    ...translations[21]?.CommonFrontend,
    ...translations[33]?.ApplicationConfiguration,
  }), [translations]);

  const handleClick = () => {
    window.open(translation?.AppRedirectUrl, '_blank');
  };

  return (
    <Box
      className="AdverBanner"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
    >
      <Box className="contentBox" onClick={handleClick}>
        <Box className="ImgBox">
          <img src={advertising1} alt="Ad Logo" />
        </Box>
        <Typography variant='h2' sx={{ mt: 2, mb: 2 }}>
          {translation?.ControlLearning}
        </Typography>
        <Box className="AppStore" sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
          <Typography component="div">
            <img src={advertising2} alt="Google Play Store" />
          </Typography>
          <Typography component="div">
            <img src={advertising3} alt="Apple Store" />
          </Typography>
        </Box>
      </Box>
      <Box className="imageBox">
        <img src={advertising4} alt="Artboard" />
      </Box>
    </Box>
  );
};

export default React.memo(Advertisement);
