import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import useSocket from "../../custom-hooks/useSocket";

const QuickCallModal = ({ isOpen, isApiCalling, translation, handleClose , handleConnect ,questionID }) => {
  const { socket, isConnected } = useSocket();
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(60);
  const [onlineTutors, setOnlineTutors] = useState([]);
  const [showRetry, setShowRetry] = useState(false);


  const closed = () => {
    clearInterval(timerRef.current);
    setTimer(60);
    handleClose();
    setShowRetry(false);
  } 

  const cancelQCRequest = () => {
    const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
    if (!socket || !socket.connected) {
      // If socket is not initialized or not connected, do not emit the event
      console.error('Socket is not connected. Unable to cancel request.');
      return;
    }
    studentRequest.IsDecline = true;
    studentRequest.requestType = "1";
    socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');
        socket.off('GetOnlineTutorResponseEvent');
        sessionStorage.removeItem("TutorResponse")
        sessionStorage.removeItem("StudentRequest")
  };

  useEffect(() => {
    if (isOpen) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;

          if (newTimer <= 0) {
            clearInterval(timerRef.current);
            setTimer(0);
            cancelQCRequest();
            setShowRetry(true)
          }
          return newTimer;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isOpen]);

  useEffect(() => {
    if (socket) {
      socket?.on('GetOnlineTutorResponseEvent', (data) => {
        const response = JSON.parse(data);
        console.log("GetOnlineTutorResponseEvent",response.Data.length);        
        if (Array.isArray(response.Data)) {
          setOnlineTutors(response.Data);
        }
 			})
    }

  }, [socket])

  useEffect(() => {
    return () => {
      socket?.off('GetOnlineTutorResponseEvent');
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setOnlineTutors([]); // Reset state when modal is closed
    }
  }, [isOpen]);


  const onRetry = () => {
    clearInterval(timerRef.current);
    setTimer(60);
    setShowRetry(false);
    handleConnect(questionID || 0);
    
    // Start a new interval for the countdown
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;

        if (newTimer <= 0) {
          clearInterval(timerRef.current);
          setTimer(0);
          cancelQCRequest();
          setShowRetry(true);
        }
        return newTimer;
      });
    }, 1000);
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted
      // onClose={closed}
      aria-describedby="alert-dialog-slide-description"
      className="cis-dialog-1"
    >
      <DialogTitle>{translation?.ConnectWithFavTeacher}</DialogTitle>
      <DialogContent className="text-center">
        <div className="timing-box">
          <div className="timing-box_text-wrap">
            <span>
              {timer} <br /> {translation?.Sec}
            </span>
          </div>
          <div className="ripple_ timing-box-1"></div>
          <div className="ripple_ timing-box-2"></div>
          <div className="ripple_ timing-box-3"></div>
        </div>
      {showRetry ?
        <Typography variant="h5" sx={{color:'red'}}>
          {translation?.TutorNotAvailable}
        </Typography>
          :
        <Typography variant="h5">
          {translation?.RequestPushed} {onlineTutors.length}{" "}
          {translation?.Tutors}
        </Typography>
      }
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={closed}
          variant="contained"
          color="error"
          size="large"
          sx={{ px: 5 }}
          disabled={isApiCalling}
        >
          {isApiCalling ? translation?.Sending : translation?.QuickCancel}
        </Button>
        {showRetry && (
          <Button
            onClick={onRetry}
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 5 }}
            disabled={isApiCalling}
          >
            {translation?.Retry || "Retry"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QuickCallModal;