import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";


export const getMockTestQuestionsSuccess = (data) => {
  return {
    type: actionTypes.GET_MOCK_TEST_QUESTIONS,
    payload: {
      mockTestQuestions: data.Data,
    },
  };
};

export const getMockTestQuestions = (data) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/GetMockTestQuestions`, data)
    .then((response) => {
      if (response) {
        dispatch(getMockTestQuestionsSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};
