import React from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from '@mui/material'
import Header from "../header/Header";
import SubTopHead from "../sub-top-head/SubTopHead";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import ShareWithFriend from '../common-components/ShareWithFriend';

import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import shareImg from '../../assets/images/shareImg.png';

import { useDispatch } from 'react-redux';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import { getMockTestList } from '../../actions/getMockTestList';
import { getQuestionbyChapter } from "../../actions/getQuestionByChapter";


const TestDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subjectId, chapterId } = useParams();
  const { getSubject, getChapter } = useStudyMaterial()
  const subject = getSubject(atob(subjectId));
  const chapter = getChapter(atob(subjectId), atob(chapterId))

  const translations = useTranslations();
  const translation = {
    ...translations[36]?.PracticeTest
  }

  const handleQuestionBank = () => {
    dispatch(getQuestionbyChapter({ TopicID: atob(subjectId), ChapterID: atob(chapterId) }))
    navigate(`/practice-test/${subjectId}/${chapterId}`)
  }

  const handleMockTest = () => {
    dispatch(getMockTestList({ TopicID: atob(subjectId) }))
    navigate(`/mock-test/${subjectId}`)
  }

  const handleView = (material) => {
    navigate(`/study-material/${subjectId}/${chapterId}/${btoa(material.ID)}`)
  }
    
  return (
    <div>
    <Header/>
    <HeroSection/>
        <Box className='OuterContainer'>
            <Box className='studyContainer'>
            <SubTopHead subject={subject} />
            <Box className="practiceBox questionPg">
                <Box className='practiceBoxIn'>
                  <Box sx={{padding:'0px 16px'}}>
                    <ShareWithFriend />
                  </Box>
                  <Box className="Classification" sx={{padding:'0px 16px'}}>
                    <Box className="cardMain" sx={{ border:'none', padding:0}}>
                        <Box className="cardTop">
                            <Box className="cardRight">
                                <Box className="cardIcon" sx={{ mb:1}}>
                                  <Typography variant='div'>
                                    {chapter?.ChapterIndex}
                                  </Typography>
                                </Box>
                                <Box className="cardContent">
                                    <Typography variant='h4'>{chapter?.ChapterName}</Typography>
                                    <Box className="cardList">
                                        <ul>
                                            {/* <li>{chapter?.NumberOfTopic} topics</li> */}
                                            <li>{chapter?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                  </Box>
                  <Box className="beforeTest" sx={{ mt:3, padding:'0px 16px'}}>
                    <Typography variant='div'>
                      <h3>{translation?.Practice} <span>{translation?.BeforeTest}</span></h3>
                    </Typography>
                    { chapter?.Practice?.length > 0 && chapter.Practice.map((test) => {
                      return (
                        <Box className="cardMain"sx={{ mt:1}} key={test.ID}>
                          <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                              <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                  <Box className="cardIcon">
                                    <Typography variant='div'>
                                    <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5447 11.9342H24.2171M17.5447 18.0029H24.2171M17.5447 24.0715H24.2171M12.1921 12.8451C12.4336 12.8451 12.6653 12.7491 12.8361 12.5783C13.0069 12.4075 13.1029 12.1758 13.1029 11.9342C13.1029 11.6927 13.0069 11.461 12.8361 11.2902C12.6653 11.1194 12.4336 11.0234 12.1921 11.0234C11.9505 11.0234 11.7188 11.1194 11.548 11.2902C11.3772 11.461 11.2813 11.6927 11.2812 11.9342C11.2813 12.1758 11.3772 12.4075 11.548 12.5783C11.7188 12.7491 11.9505 12.8451 12.1921 12.8451ZM12.1921 18.9137C12.4336 18.9137 12.6653 18.8177 12.8361 18.6469C13.0069 18.4761 13.1029 18.2444 13.1029 18.0029C13.1029 17.7613 13.0069 17.5296 12.8361 17.3588C12.6653 17.188 12.4336 17.0921 12.1921 17.0921C11.9505 17.0921 11.7188 17.188 11.548 17.3588C11.3772 17.5296 11.2813 17.7613 11.2812 18.0029C11.2813 18.2444 11.3772 18.4761 11.548 18.6469C11.7188 18.8177 11.9505 18.9137 12.1921 18.9137ZM12.1921 24.9823C12.4336 24.9823 12.6653 24.8863 12.8361 24.7155C13.0069 24.5447 13.1029 24.313 13.1029 24.0715C13.1029 23.8299 13.0069 23.5983 12.8361 23.4274C12.6653 23.2566 12.4336 23.1607 12.1921 23.1607C11.9505 23.1607 11.7188 23.2566 11.548 23.4274C11.3772 23.5983 11.2813 23.8299 11.2812 24.0715C11.2813 24.313 11.3772 24.5447 11.548 24.7155C11.7188 24.8863 11.9505 24.9823 12.1921 24.9823Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22.7961 4.3457H13.2009C8.30923 4.3457 4.34375 8.31118 4.34375 13.2028V22.7981C4.34375 27.6897 8.30923 31.6552 13.2009 31.6552H22.7961C27.6878 31.6552 31.6533 27.6897 31.6533 22.7981V13.2028C31.6533 8.31118 27.6878 4.3457 22.7961 4.3457Z" stroke="#005DB8" strokeWidth="2"/>
                                    </svg>
                                    </Typography>
                                  </Box>
                                  <Box className="cardContent">
                                      <Typography variant='h4'>{test?.Value}</Typography>
                                  </Box>
                                  
                              </Box>
                              <Box className="cardLeft">
                                  <Button variant="contained" 
                                    // className='blueBtn' 
                                    className={chapter?.ChapterCompleted === true ? 'lightGreen' : chapter?.ChapterCompleted === false && chapter?.ChapterStatus === 'Attempted' ? 'darkYellow' : 'blueBtn'}
                                    onClick={handleQuestionBank}>
                                    {chapter?.ChapterCompleted === true ? translation?.PracticeCompleted : chapter?.ChapterCompleted === false && chapter?.ChapterStatus === 'Attempted' ? translation?.PracticeContinue : translation?.StartPractice}
                                  </Button>
                              </Box>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>

                <Box className="TestYourSelf" sx={{mt:3}}>
                    <Typography variant='div'>
                      <h3>{translation?.Test} <span>{translation?.YourSelf}</span></h3>
                    </Typography>
                    { chapter?.Mock?.length && chapter.Mock.map((test) => {
                      return (
                        <Box className="cardMain"sx={{ mt:1, background:"#fff"}} key={test.ID}>
                          <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                              <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                  <Box className="cardIcon">
                                    <Typography variant='div'>
                                    <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.23828 18.0002C3.23828 11.0414 3.23828 7.56204 5.39942 5.39942C7.56352 3.23828 11.0414 3.23828 18.0002 3.23828C24.9589 3.23828 28.4383 3.23828 30.5995 5.39942C32.7621 7.56352 32.7621 11.0414 32.7621 18.0002C32.7621 24.9589 32.7621 28.4383 30.5995 30.5995C28.4398 32.7621 24.9589 32.7621 18.0002 32.7621C11.0414 32.7621 7.56204 32.7621 5.39942 30.5995C3.23828 28.4398 3.23828 24.9589 3.23828 18.0002Z" stroke="#F4B856" strokeWidth="2"/>
                                      <path d="M9.14258 23.6096L10.8299 25.381L15.0473 20.9525M9.14258 13.2763L10.8299 15.0477L15.0473 10.6191" stroke="#F4B856" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M19.4766 13.5713H26.8575M19.4766 23.9046H26.8575" stroke="#F4B856" strokeWidth="2" strokeLinecap="round"/>
                                      </svg>

                                    </Typography>
                                  </Box>
                                  <Box className="cardContent">
                                    <Typography variant='h4'>{test?.Value}</Typography>
                                  </Box>
                                  
                              </Box>
                              <Box className="cardLeft">
                                  <Button variant="contained" 
                                  // className='darkYellow' 
                                  className="blueBtn"
                                  onClick={handleMockTest}>
                                    {translation?.StartPractice}
                                  </Button>
                              </Box>
                          </Box>
                        </Box>
                      )
                    })}
                </Box>

                {/* <Box className="SolutionBox" sx={{ mt:3, padding:'0px 16px'}}>
                  <Typography variant='div'>
                    <h3>{translation?.PracticeSolutions}</h3>
                  </Typography>

                  { chapter?.Solutions?.length && chapter.Solutions.map((test) => {
                    return (
                      <Box className="cardMain"sx={{ mt:1}} key={test.ID}>
                          <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                              <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                  <Box className="cardIcon">
                                    <Typography variant='div'>
                                    <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M28.0497 5.9043H7.38219C5.75157 5.9043 4.42969 7.22618 4.42969 8.8568V29.5243C4.42969 31.1549 5.75157 32.4768 7.38219 32.4768H28.0497C29.6803 32.4768 31.0022 31.1549 31.0022 29.5243V8.8568C31.0022 7.22618 29.6803 5.9043 28.0497 5.9043Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M23.6191 2.95312V8.85813" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M11.8105 2.95312V8.85813" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M4.42969 14.7627H31.0022" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    </Typography>
                                  </Box>
                                  <Box className="cardContent">
                                    <Typography variant='h4'>{test?.Value}</Typography>
                                  </Box>
                              </Box>
                              <Box className="cardLeft">
                                <Button variant="contained" className='lightGreen'>{translation?.StartPractice}</Button>
                              </Box>
                          </Box>
                      </Box>
                    )
                  }) }


                  <Box className="cardMain"sx={{ mt:2}}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                              <Box className="cardIcon">
                                <Typography variant='div'>
                                <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.18945 29.0716V6.92876C6.18945 5.94998 6.57827 5.0113 7.27037 4.3192C7.96247 3.6271 8.90115 3.23828 9.87993 3.23828H29.8085V32.7621H9.87993C8.90115 32.7621 7.96247 32.3733 7.27037 31.6812C6.57827 30.9891 6.18945 30.0504 6.18945 29.0716ZM6.18945 29.0716C6.18945 28.0928 6.57827 27.1542 7.27037 26.4621C7.96247 25.77 8.90115 25.3811 9.87993 25.3811H29.8085M12.0942 10.6192H20.9514M12.0942 16.524H23.9037" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>

                                </Typography>
                              </Box>
                              <Box className="cardContent">
                                  <Typography variant='h4'>text book solutions</Typography>
                              </Box>
                              
                          </Box>
                          <Box className="cardLeft">
                              <Button variant="contained" className='lightGreen'>start practice</Button>
                          </Box>
                      </Box>
                  </Box>
                </Box> */}
                
                <Box className="materialBox" sx={{mt:3}}>
                    <Typography variant='div'>
                      <h3>{translation?.PracticeMaterial}</h3>
                    </Typography>

                    { chapter?.Materials?.length && chapter.Materials.map((material) => {
                      return (
                        <Box className="cardMain"sx={{ mt:1}} key={material.ID}>
                          <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                              <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                  <Box className="cardIcon">
                                    <Typography variant='div'>
                                    <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5708 10.6196H22.4279M13.5708 16.5244H22.4279M13.5708 22.4291H19.4755M7.66602 7.66722C7.66602 6.8842 7.97707 6.13325 8.53075 5.57958C9.08443 5.0259 9.83538 4.71484 10.6184 4.71484H25.3803C26.1633 4.71484 26.9143 5.0259 27.4679 5.57958C28.0216 6.13325 28.3327 6.8842 28.3327 7.66722V28.3339C28.3327 29.1169 28.0216 29.8679 27.4679 30.4215C26.9143 30.9752 26.1633 31.2863 25.3803 31.2863H10.6184C9.83538 31.2863 9.08443 30.9752 8.53075 30.4215C7.97707 29.8679 7.66602 29.1169 7.66602 28.3339V7.66722Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>


                                    </Typography>
                                  </Box>
                                  <Box className="cardContent">
                                      <Typography variant='h4'>{material?.Value}</Typography>
                                  </Box>
                                  
                              </Box>
                              <Box className="cardLeft">
                                  <Button variant="contained" className='blueBtn' onClick={() => handleView(material)} >{translation?.View}</Button>
                              </Box>
                          </Box>
                        </Box>
                      )
                    })}

                    {/* <Box className="cardMain"sx={{ mt:1}}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                              <Box className="cardIcon">
                                <Typography variant='div'>
                                <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5708 10.6196H22.4279M13.5708 16.5244H22.4279M13.5708 22.4291H19.4755M7.66602 7.66722C7.66602 6.8842 7.97707 6.13325 8.53075 5.57958C9.08443 5.0259 9.83538 4.71484 10.6184 4.71484H25.3803C26.1633 4.71484 26.9143 5.0259 27.4679 5.57958C28.0216 6.13325 28.3327 6.8842 28.3327 7.66722V28.3339C28.3327 29.1169 28.0216 29.8679 27.4679 30.4215C26.9143 30.9752 26.1633 31.2863 25.3803 31.2863H10.6184C9.83538 31.2863 9.08443 30.9752 8.53075 30.4215C7.97707 29.8679 7.66602 29.1169 7.66602 28.3339V7.66722Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                </Typography>
                              </Box>
                              <Box className="cardContent">
                                  <Typography variant='h4'>class notes</Typography>
                              </Box>
                              
                          </Box>
                          <Box className="cardLeft">
                              <Button variant="contained" className='lightGreen'>start practice</Button>
                          </Box>
                      </Box>
                  </Box>
                  <Box className="cardMain"sx={{ mt:2}}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                              <Box className="cardIcon">
                                <Typography variant='div'>
                                <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.9986 28.3318C15.9789 27.1657 13.6879 26.5518 11.3557 26.5518C9.02362 26.5518 6.73257 27.1657 4.71289 28.3318V9.14128C6.73257 7.97521 9.02362 7.36133 11.3557 7.36133C13.6879 7.36133 15.9789 7.97521 17.9986 9.14128M17.9986 28.3318C20.0183 27.1657 22.3093 26.5518 24.6415 26.5518C26.9736 26.5518 29.2646 27.1657 31.2843 28.3318V9.14128C29.2646 7.97521 26.9736 7.36133 24.6415 7.36133C22.3093 7.36133 20.0183 7.97521 17.9986 9.14128M17.9986 28.3318V9.14128" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </Typography>
                              </Box>
                              <Box className="cardContent">
                                  <Typography variant='h4'>text book chapter</Typography>
                              </Box>
                              
                          </Box>
                          <Box className="cardLeft">
                              <Button variant="contained" className='lightGreen'>start practice</Button>
                          </Box>
                      </Box>
                  </Box>

                  <Box className="cardMain"sx={{ mt:2}}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                              <Box className="cardIcon">
                                <Typography variant='div'>
                                <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.3681 10.1282V19.4902C31.3683 19.8904 31.2894 20.2868 31.1358 20.6564C30.9823 21.026 30.7572 21.3616 30.4735 21.644L21.643 30.4745C21.3606 30.7585 21.0247 30.9838 20.6548 31.1373C20.2849 31.2909 19.8882 31.3696 19.4877 31.3691H10.1287C9.33162 31.3693 8.54232 31.2125 7.80588 30.9076C7.06943 30.6027 6.40025 30.1557 5.83657 29.5922C5.27289 29.0286 4.82575 28.3596 4.52068 27.6232C4.21561 26.8868 4.05859 26.0975 4.05859 25.3005V10.1267C4.05899 8.51747 4.69853 6.97427 5.83657 5.83651C6.97462 4.69874 8.51797 4.05957 10.1272 4.05957H25.301C26.9105 4.05957 28.454 4.69894 29.5921 5.83703C30.7302 6.97511 31.3681 8.51869 31.3681 10.1282Z" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M20.7477 31.1097L19.989 25.0425C19.9021 24.3543 19.9737 23.6553 20.1983 22.999C20.423 22.3427 20.7948 21.7465 21.2852 21.2559C21.7757 20.7653 22.3718 20.3933 23.028 20.1685C23.6843 19.9436 24.3833 19.8718 25.0715 19.9585L31.1386 20.7173" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </Typography>
                              </Box>
                              <Box className="cardContent">
                                  <Typography variant='h4'>short notes</Typography>
                              </Box>
                              
                          </Box>
                          <Box className="cardLeft">
                              <Button variant="contained" className='lightGreen'>start practice</Button>
                          </Box>
                      </Box>
                  </Box>

                  <Box className="cardMain"sx={{ mt:2}}>
                      <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                          <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                              <Box className="cardIcon">
                                <Typography variant='div'>
                                <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.23828 18.0002C3.23828 11.0414 3.23828 7.56204 5.39942 5.39942C7.56352 3.23828 11.0414 3.23828 18.0002 3.23828C24.9589 3.23828 28.4383 3.23828 30.5995 5.39942C32.7621 7.56352 32.7621 11.0414 32.7621 18.0002C32.7621 24.9589 32.7621 28.4383 30.5995 30.5995C28.4398 32.7621 24.9589 32.7621 18.0002 32.7621C11.0414 32.7621 7.56204 32.7621 5.39942 30.5995C3.23828 28.4398 3.23828 24.9589 3.23828 18.0002Z" stroke="#005DB8" strokeWidth="2"/>
                                <path d="M10.6191 20.9526L14.004 17.5677C14.2809 17.291 14.6563 17.1355 15.0477 17.1355C15.4391 17.1355 15.8146 17.291 16.0914 17.5677L18.4326 19.9089C18.7094 20.1857 19.0848 20.3412 19.4763 20.3412C19.8677 20.3412 20.2431 20.1857 20.5199 19.9089L25.381 15.0479M25.381 15.0479V18.7383M25.381 15.0479H21.6906" stroke="#005DB8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                </Typography>
                              </Box>
                              <Box className="cardContent">
                                  <Typography variant='h4'>formula sheet</Typography>
                              </Box>
                              
                          </Box>
                          <Box className="cardLeft">
                              <Button variant="contained" className='lightGreen'>start practice</Button>
                          </Box>
                      </Box>
                  </Box> */}
                </Box>
            </Box>
        </Box>
    </Box>
        </Box>
    <FooterSection/>
    </div>
  )
}

export default TestDetail