import CryptoJS from 'crypto-js';

const SECRET_KEY = "mYse&retkEy";

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return encrypted.replace(/\//g, "AGORA");
};

export const decryptData = (encrypt) => {
  const encrypted = encrypt.replace(/AGORA/g, "/");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
};
